import React from 'react';
import { Row, Col } from 'antd';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row className="dtx-l-login__row">
        <Col xxl={8} xl={10} lg={12} md={8} xs={24} className="dtx-c-login__img">
          <img alt="" src={'https://res.cloudinary.com/bytek/image/upload/v1638459980/extra/illustrazione-login.jpg'} className="login-img" />
        </Col>

        <Col xxl={16} xl={14} lg={12} md={16} xs={24} className="dtx-c-login__content">
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
