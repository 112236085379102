import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { getCustomerList, getCustomerList_new } from '../../../redux/customers/actionCreator';
import { getBrandList, getBrandList_new } from '../../../redux/brands/actionCreator';
import { postUpdateUser } from '../../../redux/users/actionCreator';

const { Option } = Select;

const ModifyClientUser = ({ visible, onClose, id, record, idCustomer }) => {
  // console.log(record);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [listCustomer, setListCustomer] = useState([]);
  const [listBrand, setListBrand] = useState([]);

  const [customerSelect, setCustomerSelect] = useState(null);

  const [customerActive, setCustomerActive] = useState(null);

  useEffect(() => {
    dispatch(
      getBrandList_new(values => {
        // console.log(values);

        if (values !== undefined && values.length > 0) {
          let newListBrand = [];

          form.setFieldsValue;

          values.map(brand => {
            newListBrand.push(<Option value={brand.id}>{brand.name}</Option>);

            /**
             * Patch for have the current
             */
            if (record.brand_ids.includes(brand.id)) {
              setCustomerActive(brand.customer.id);
            }
          });

          setListBrand(newListBrand);
        }
      }),
    );

    dispatch(
      getCustomerList_new(idCustomer, values => {
        let newListCustomer = [];

        // console.log(idCustomer, values);

        values.map(customer => {
          newListCustomer.push(<Option value={customer.id}>{customer.name}</Option>);
        });

        setListCustomer(newListCustomer);
      }),
    );
  }, []);

  const onFinish = values => {
    let newValues = {};

    newValues = {
      ...values,
      user_id: record.id,
      seomaster: 'False',
      seouser: 'False',
      clientuser: 'True',
    };

    dispatch(
      postUpdateUser(newValues, values => {
        onClose();
      }),
    );
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onUpdate = value => {
    const fields = form.getFieldsValue();

    let newForm = {};

    newForm = {
      ...fields,
      users: [],
    };

    form.setFieldsValue(newForm);
  };

  return (
    <Drawer
      destroyOnClose={true}
      title={'Edit Customer User'}
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
      preserve={false}
      className="dtx-c-drawer"
    >
      <div>
        {listCustomer.length > 0 && listBrand.length > 0 ? (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              first_name: record !== undefined && record.first_name,
              last_name: record !== undefined && record.last_name,
              email: record !== undefined && record.email,
              client_ids:
                record !== undefined && record.customer !== undefined && record.customer.length > 0
                  ? record.customer
                  : customerActive !== null && customerActive,
              brand_ids: record !== undefined && record.brand_ids,
            }}
            {...layout}
          >
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2 m-grey">Name:</p>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="e.g. Mario" className="dtx-c-field__input" />
                </Form.Item>
              </div>

              <div className="dtx-c-field">
                <p className="label2 m-grey">Surname:</p>
                <Form.Item
                  className="form-row__element"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="e.g. Rossi" className="dtx-c-field__input" />
                </Form.Item>
              </div>
            </div>
            <div className="dtx-c-field">
              <p className="label2 m-grey">Email:</p>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="e.g. mario.rossi@gmail.com" />
              </Form.Item>{' '}
            </div>
            <div className="dtx-c-field">
              <p className="label2 m-grey">Select the Customer to whom to associate the User:</p>
              {/* This is a Customer not Customer User */}
              <Form.Item
                name="client_ids"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Customer"
                  disabled={listCustomer.length > 0 ? false : true}
                  className="dtx-c-field__select"
                  onChange={value => {
                    let newForm = form.getFieldValue();

                    newForm['brand_ids'] = [];

                    form.setFieldsValue(newForm);

                    setListBrand([]);
                    onUpdate();
                    setCustomerSelect(value);
                    dispatch(
                      getBrandList(value, values => {
                        // console.log(values);
                        if (values !== undefined && values.length > 0) {
                          let newListBrand = [];

                          newListBrand.push(
                            values.map(customer => <Option value={customer.id}>{customer.name}</Option>),
                          );

                          setListBrand(newListBrand);
                        }
                      }),
                    );
                  }}
                >
                  {listCustomer}
                </Select>
              </Form.Item>
            </div>
            <div className="dtx-c-field">
              <p className="label2 m-grey">Select the Brands to associate the User with:</p>
              <Form.Item
                name="brand_ids"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Brands"
                  mode="multiple"
                  className="dtx-c-field__input"
                  disabled={listBrand.length > 0 && customerSelect !== null > 0 ? false : true}
                  loading={listBrand.length > 0 && customerSelect !== null > 0 ? false : true}
                >
                  {listBrand}
                </Select>
              </Form.Item>
            </div>
            <Form.Item>
              <Button className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        ) : (
          
            <Skeleton paragraph={{ rows: 12 }} />
          
        )}
      </div>
    </Drawer>
  );
};

export default ModifyClientUser;
