import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { getCustomerList } from '../../../redux/customers/actionCreator';
import { getBrandList } from '../../../redux/brands/actionCreator';
import { postCreateUser } from '../../../redux/users/actionCreator';

const { Option } = Select;

const NewClientUser = ({ visible, onClose, idCustomer }) => {
  const dispatch = useDispatch();

  const [listCustomer, setListCustomer] = useState([]);
  const [listBrand, setListBrand] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [customerSelect, setCustomerSelect] = useState(null);

  useEffect(() => {
    dispatch(
      getCustomerList(idCustomer, values => {
        // console.log(values);
        let newListCustomer = [];

        newListCustomer.push(values.map(customer => <Option value={customer.id}>{customer.name}</Option>));

        setListCustomer(newListCustomer);
      }),
    );

    dispatch(
      getBrandList(idCustomer, values => {
        // console.log(values);
        if (values !== undefined && values.length > 0) {
          let newListBrand = [];

          newListBrand.push(values.map(customer => <Option value={customer.id}>{customer.name}</Option>));

          setListBrand(newListBrand);
        }
      }),
    );
  }, [dispatch]);

  const onFinish = values => {
    let newValues = {};

    newValues = {
      ...values,
      user_groups: ['client'],
      username: values['email'],
    };

    dispatch(
      postCreateUser(newValues, status => {
        if (status === 200 || status === 204) {
          setLoading(false);
          onClose();
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onUpdate = value => {
    const fields = form.getFieldsValue();

    let newForm = {};

    newForm = {
      ...fields,
      brand_ids: [],
    };

    form.setFieldsValue(newForm);
  };

  return (
    <Drawer
      title={'New Customer User'}
      destroyOnClose={true}
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
      className="dtx-c-drawer"
    >
      <div>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            client_ids: idCustomer !== undefined ? parseInt(idCustomer) : undefined,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          {...layout}
        >
          <div className="dtx-c-field-row">
            <div className="dtx-c-field">
              <p className="label2 m-grey">Name:</p>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="e.g. Mario" className="dtx-c-field__input" />
              </Form.Item>
            </div>

            <div className="dtx-c-field">
              <p className="label2 m-grey">Surname:</p>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="e.g. Rossi" className="dtx-c-field__input" />
              </Form.Item>
            </div>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey">Email:</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="e.g. mario.rossi@gmail.com" className="dtx-c-field__input" />
            </Form.Item>{' '}
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey">Select the Customer to whom to associate the User:</p>

            <Form.Item
              name="client_ids"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Customer"
                className="dtx-c-field__select"
                disabled={listCustomer.length > 0 ? false : true}
                onChange={value => {
                  setListBrand([]);
                  onUpdate();
                  setCustomerSelect(value);
                  dispatch(
                    getBrandList(value, values => {
                      // console.log(values);
                      if (values !== undefined && values.length > 0) {
                        let newListBrand = [];

                        newListBrand.push(values.map(customer => <Option value={customer.id}>{customer.name}</Option>));

                        setListBrand(newListBrand);
                      }
                    }),
                  );
                }}
              >
                {listCustomer}
              </Select>
            </Form.Item>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey">Select the Brands to associate the User with:</p>
            <Form.Item
              name="brand_ids"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Brands"
                mode="multiple"
                className="dtx-c-field__select"
                disabled={listBrand.length > 0 && customerSelect !== null > 0 ? false : true}
              >
                {listBrand}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
              {loading ? <span className="Label">Loading...</span> : <span>Create User</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default NewClientUser;
