import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';

const AuthInfo = () => {
  const dispatch = useDispatch();
  
  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const userContent = (

    
    <UserDropDwon id="submenu">
      <div className="dtx-c-dropdown">
        <div className='dtx-c-dropdown-content'>
          <div className='dtx-c-dropdown-user'>
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            {
              level !== undefined && level !== null && <p className='label1'>{`${level.user.first_name} ${level.user.last_name}`}</p>
            }
            
          </div>
          <div className='dtx-c-dropdown-list'>
            <Link className="dtx-c-dropdown-item" to="/profile">
              <FeatherIcon icon="user" />
              <p className="span1">Profile</p>
            </Link>
          </div>
        </div>
        <div className='dtx-c-dropdown-footer'>
          <Link className="dtx-c-dropdown-item" onClick={SignOut} to="#">
              <FeatherIcon icon="log-out" />
              <p className="span1">Sign Out</p>
            </Link>
        </div>
      </div>
    </UserDropDwon>
  );

  // const onFlagChangeHandle = value => {
  //   setState({
  //     ...state,
  //     flag: value,
  //   });
  // };

  // const country = (
  //   <NavAuth>
  //     <Link onClick={() => onFlagChangeHandle('english')} to="#">
  //       <img src={require('../../../static/img/flag/english.png')} alt="" />
  //       <span>English</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('germany')} to="#">
  //       <img src={require('../../../static/img/flag/germany.png')} alt="" />
  //       <span>Germany</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('spain')} to="#">
  //       <img src={require('../../../static/img/flag/spain.png')} alt="" />
  //       <span>Spain</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('turky')} to="#">
  //       <img src={require('../../../static/img/flag/turky.png')} alt="" />
  //       <span>Turky</span>
  //     </Link>
  //   </NavAuth>
  // );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
