import axios from 'axios';
import { useState } from 'react';
import { DataService2 } from '../../config/dataService/dataService2';
import { setUpdating } from '../themeLayout/actionCreator';

const searchKewords = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/search_keywords/`, form);

    if (response.status === 200) {
      callback(response.data);
      dispatch(setUpdating(true));
    }
  };
};

const top10GrowingKeywords = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/top_10_growing_keywords/`, form);

    if (response.status == 200) {
      callback(response.data['Top 10 Growing Keywords']);
    }
  };
};

const searchVolumeKeywords = (form, callback) => {
  return async dispatch => {

    // console.log(form)

    const response = await DataService2.post(`/search_volume_by_cluster/`, form);

    if (response.status == 200) {
      callback(response.data);
    }
  };
};

const dynamicTrendByCluster = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/dynamic_trend_by_cluster/`, form);

    if (response.status == 200) {
      callback(response.data);
    }
  };
};

const getRSSgoogleFeed = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/get_keyword_news/`, form);

    if (response.status == 200) {
      callback(response.data);
    }
  };
};

const exportCSVKeyword = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/export_csv/`, form);

    if (response.status == 200) {
      callback(response.data);
    }
  };
};

const retrieveFilterValues = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/retrieve_filter_values/`, form);

    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const getUpdateDate = (form, callback) => {
  return async dispatch => {
    const response = await DataService2.post(`/get_update_date/`, form);

    if (response.status === 200) {
      callback(response.data);
    }
  };
};


export { searchKewords, top10GrowingKeywords, searchVolumeKeywords, dynamicTrendByCluster, getRSSgoogleFeed, exportCSVKeyword, retrieveFilterValues, getUpdateDate };
