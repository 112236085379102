import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import AllRequestsSolutionsTabs from '../../../components/tabs/solutions/AllRequestsSolutionsTabs.js';
import AllSolutionsTabs from '../../../components/tabs/solutions/AllSolutionsTabs.js';
import Drawers from '../../../components/drawer/index.js';
import SuccessModals from '../../../components/modals/SuccessModals.js';
import TrendSonarConfigurationEdit from '../../../components/modals/TrendSonarConfigurationEdit.js';

const SolutionsPage = () => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    // console.log('Close');
    setVisible(false);
  };

  const [successItem, setSuccessItem] = useState();
  const [idSuccess, setIdSuccess] = useState(null);

  // successModal variables
  const [successShow, setSuccessShow] = useState(false);

  const showSuccessModal = () => {
    setSuccessShow(true);
  };

  // configureModal variables
  const [configurationModal, setConfigurationModal] = useState(false);

  return (
    <div className="dtx-l-main">
      <div className="dtx-l-header">
        <h4>Solutions</h4>
        <Button
          className="dtx-c-btn btn__small dtx-c-btn__solid"
          onClick={() => {
            setVisible(true);
          }}
        >
          Add Solution
        </Button>
      </div>

      <Row className="mb-3-5">
        <AllRequestsSolutionsTabs />
      </Row>
      <Row className='dtx-c-table__composed'>
        <AllSolutionsTabs />
      </Row>

      <Drawers
        drawerName={'newSolutionSpecialist'}
        visible={visible}
        onClose={onClose}
        setSuccessShow={setSuccessShow}
        setIdSuccess={setIdSuccess}
        setSuccessItem={setSuccessItem}
      />

      <SuccessModals
        successShow={successShow}
        setSuccessShow={setSuccessShow}
        idSuccess={idSuccess}
        successItem={successItem}
        setConfigurationModal={setConfigurationModal}
      />

      <TrendSonarConfigurationEdit
        configurationEditModal={configurationModal}
        setConfigurationEditModal={setConfigurationModal}
        record={{ id: idSuccess }}
      />
    </div>
  );
};

export default SolutionsPage;
