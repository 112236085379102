import React from 'react';
import { HorizontalBar, Line } from 'react-chartjs-2';
import { chartLinearGradient } from '../utilities/utilities';

const TrendLine = ({ response, title, labels }) => {
  // console.log(response);

  let { datasets, options } = {
    options: {
      elements: {
        point: {
          radius: 0
        }
      },
      maintainAspectRatio: true,
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      labels: {
        display: false,
      },
      layout: {
        padding: 0,
      },
      title: {
        display: false,
        text: 'High Volume Constant Signal',
      },
      tooltips: {
        "enabled": true,
        displayColors: false,
        caretSize: 0,
        titleFontSize: 12,
        bodyFontSize: 12,
        bodySpacing: 0,
        titleSpacing: 0,
        xPadding: 2,
        yPadding: 2,
        cornerRadius: 2,
        titleMarginBottom: 2,
        callbacks: {
          title: function () { }
        }
      },
      scales: {
        yAxes: [
          {
            display: false,
            stacked: true,
            gridLines: {
              display: false,
              color: '#99CBF2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 1,
              display: false,
              stepSize: 1,
            },
          },
        ],
        xAxes: [
          {
            display: false,
            stacked: true,
            gridLines: {
              display: false,
              color: '#99CBF2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 1,
              display: false,
              color: '#99CBF2',
            },
          },
        ],
      },
    },

    datasets: [
      {
        data: response,
        borderColor: '#53a2fc',
        borderWidth: 2,
        fill: true,
        // () =>
        // chartLinearGradient(document.getElementById('kw-trend'), 300, {
        //     start: '#53a2fc30',
        //     end: '#ffffff10',
        // }),
        backgroundColor: '#ffffff',
        pointHoverBorderColor: 'transparent',
      },
    ],
  };
  const data = {
    datasets,
    labels,
  };

  return (
    <Line data={data} options={options} height={30} width={100} />
  );
};

export default TrendLine;
