import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';

export default function indexModalsMaster({ listShowModals, isModalVisibleSpecialist, setIsModalVisibleSpecialist, isModalVisible, setIsModalVisible, showModal, showDrawer }) {
  // console.log('Modal View: ', isModalVisibleSpecialist, 'List of Modals: ', listShowModals);

  let drawer = <div />;

  let showModals = [];

  const [drawerSelected, setDrawerSelected] = useState();

  listShowModals.map(isModal => {
    if (isModalVisibleSpecialist) {
      switch (isModal) {
        case 'newClientUser':
          showModals.push(
            <div
                className={drawerSelected === 'newClientUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
                onClick={() => {
                  setDrawerSelected('newClientUser');
                }}
              >
                <FeatherIcon icon={'briefcase'} className="m-secondary" />
                <p className="span3 m-grey">Customer User</p>
              </div>
          );
          break;
        case 'newSpecialistUser':
          showModals.push(
            <div
              className={drawerSelected === 'newSpecialistUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newSpecialistUser');
              }}
            >
              <FeatherIcon icon={'user'} className="m-secondary" />
              <p className="span3 m-grey">SEO User</p>
            </div>
          );
          break;
          case 'newMasterUser':
            showModals.push(
              <div
                key="newMasterUser"
                className={drawerSelected === 'newMasterUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
                onClick={() => {
                  setDrawerSelected('newMasterUser');
                }}
              >
                <FeatherIcon icon={'user-check'} className="m-secondary" />
                <p className="span3 m-grey">SEO Master</p>
              </div>,
            );
            break;
        default:
          drawer = <div></div>;
      }
    }
  });

  const handleOk = () => {
    showDrawer(drawerSelected);
    setIsModalVisibleSpecialist(false);
  };

  const handleCancel = e => {
    showModal();
    setIsModalVisibleSpecialist(false);
    setIsModalVisible(true)
  };

  return (
    <Modal
      title="Add New"
      visible={isModalVisibleSpecialist}
      okText="Add Element"
      cancelText="Go Back"
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      className='dtx-c-modal dtx-c-modal__add'
    >
      <div className="dtx-c-modal-content">
        <div className='dtx-c-modal-text'>
          <p className="span1">Select which element you want to add</p>
        </div>
        <div className="dtx-c-card-row">{showModals}</div>
      </div>
    </Modal>
  );
}
