import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, TimePicker, DatePicker, Form, message, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';

import moment from 'moment';
const format = 'HH:mm';

export default function UpdateFrequency(newConfig, form, setFrequencyComponents, update_frequency, disabledModal) {
  //console.log(disabledModal);

  const getDays = () => {
    let listDays = [];
    for (let i = 1; i <= 28; i++) {
      listDays.push(
        <Option key={i} value={i}>
          {i}
        </Option>,
      );
    }

    return listDays;
  };

  const getValueForm = (value, key) => {
    let newForms = {};
    newForms = {
      ...myFormValues,
      [key]: value,
    };
    setMyFormValues(newForms);
  };

  switch (update_frequency) {
    case '3 times a day':
      setFrequencyComponents([
        <>
          <div className="dtx-c-section back-m-white">
            <div className="dtx-c-section-title">
              <p className="label1">Data Update</p>
              <p className="span2">Select when to run the update</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Update Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className="dtx-c-field__picker"
                    format={format}
                    disabled={disabledModal}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                  />
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Second Update Time</p>
                <Form.Item
                  name="select_update_time_2"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_2: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className="dtx-c-field__picker"
                    format={format}
                    disabled={disabledModal}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                  />
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Third Update Time</p>
                <Form.Item
                  name="select_update_time_3"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_3: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className="dtx-c-field__picker"
                    format={format}
                    disabled={disabledModal}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </>,
      ]);
      break;
    case '2 times a day':
      setFrequencyComponents([
        <>
          <div className="dtx-c-section back-m-white">
            <div className="dtx-c-section-title">
              <p className="label1">Data Update</p>
              <p className="span2">Select when data update starts</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Update Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className='dtx-c-field__picker'
                    format={format}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                    disabled={disabledModal}
                  />
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Second Update Time</p>
                <Form.Item
                  name="select_update_time_2"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_2: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className='dtx-c-field__picker'
                    format={format}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_2'] })}
                    disabled={disabledModal}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </>,
      ]);
      break;
    case '1 time a day':
      setFrequencyComponents([
        <>
          <div className="dtx-c-section back-m-white">
            <div className="dtx-c-section-title">
              <p className="label1">Data Update</p>
              <p className="span2">Select when data update starts</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Update Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className='dtx-c-field__picker'
                    format={format}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                    disabled={disabledModal}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </>,
      ]);
      break;
    case '1 time a week':
      return setFrequencyComponents([
        <>
          <div className="dtx-c-section back-m-white">
            <div className="dtx-c-section-title">
              <p className="label1">Data Update</p>
              <p className="span2">Select when to run the update</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Day of the week</p>
                <Form.Item
                  name="select_update_day_of_week"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select day"
                    className="dtx-c-field__select"
                    // onChange={value => {
                    //   getValueForm(value, 'select_update_day_of_week');
                    // }}
                    disabled={disabledModal}
                  >
                    <Option value={1}>Monday</Option>
                    <Option value={2}>Tuesday</Option>
                    <Option value={3}>Wednesday</Option>
                    <Option value={4}>Thursday</Option>
                    <Option value={5}>Friday</Option>
                    <Option value={6}>Saturday</Option>
                    <Option value={7}>Sunday</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className="dtx-c-field__picker"
                    format={format}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                    disabled={disabledModal}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </>,
      ]);
      break;
    case '1 time a month':
      setFrequencyComponents([
        <>
          <div className="dtx-c-section back-m-white">
            <div className="dtx-c-section-title">
              <p className="label1">Data Update</p>
              <p className="span2">Select when to run the update</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Day of the month</p>
                <Form.Item
                  name="select_update_day_of_month"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select day of the month"
                    className="dtx-c-field__select"
                    // onChange={value => {
                    //   getValueForm(value, 'select_update_day_of_week');
                    // }}
                    disabled={disabledModal}
                  >
                    {getDays()}
                  </Select>
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Update Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    className="dtx-c-field__picker"
                    format={format}
                    disabled={disabledModal}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </>,
      ]);
      break;
    case 'once every 2 weeks':
      setFrequencyComponents([
        <>
          <div className="dtx-c-section back-m-white">
            <div className="dtx-c-section-title">
              <p className="label1">Data Update</p>
              <p className="span2">Select when to run the update</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Day of the week</p>
                <Form.Item
                  name="select_update_day_of_week"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select day"
                    className="dtx-c-field__select"
                    // onChange={value => {
                    //   getValueForm(value, 'select_update_day_of_week');
                    // }}
                    disabled={disabledModal}
                  >
                    <Option value={1}>Monday</Option>
                    <Option value={2}>Tuesday</Option>
                    <Option value={3}>Wednesday</Option>
                    <Option value={4}>Thursday</Option>
                    <Option value={5}>Friday</Option>
                    <Option value={6}>Saturday</Option>
                    <Option value={7}>Sunday</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Select time"
                    disabled={disabledModal}
                    format={format}
                    {...(newConfig !== null && { defaultValue: newConfig['select_update_time_1'] })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </>,
      ]);
      break;
    default:
  }
}
