const actions = {
  SEARCH_USERS : 'SEARCH_USERS',
  LOADING_USERS : 'LOADING_USERS',
  SET_FILTERS : 'SET_FILTERS',
  GET_USER_CARD: 'GET_USER_CARD',
  GET_LOADING_USER_CARD: 'GET_LOADING_USER_CARD',
  GET_USERS_TAGS: 'GET_USERS_TAGS',
  SET_NUSERS: 'SET_NUSERS',


  searchUsers: (data) => {
    return {
      type: actions.SEARCH_USERS,
      data
    };
  },

  setNUsers: (data) => {
    return {
      type: actions.SET_NUSERS,
      data
    }
  },

  loading: (data) => {
    return {
      type: actions.LOADING_USERS,
      data
    };
  },

  setFilters: (data) => {
    return {
      type: actions.SET_FILTERS,
      data
    };
  },

  userCard: (data) => {
    return {
      type: actions.GET_USER_CARD,
      data
    };
  },

  loadingUserCard: (data) => {
    return {
      type: actions.GET_LOADING_USER_CARD,
      data
    };
  },

  getUsersTags: (data) => {
    return {
      type: actions.GET_USERS_TAGS,
      data
    };
  },

};

export default actions;
