import React from 'react';
import { Button, Modal } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

export default function SuccessModals({ successShow, setSuccessShow, idSuccess, successItem, setConfigurationModal }) {
  let typeModalShow = [];

  switch (successItem) {
    case 'newBrand':
      typeModalShow.push(
        <div key='newBrand' className="dtx-c-modal-content">
          <div className="dtx-c-modal-text">
            <FeatherIcon icon={'check-square'} />
            <h6>Client created successfully</h6>
          </div>
          <div className="dtx-c-btn-row">
            <Button
              className="dtx-c-btn btn__small dtx-c-btn__outline"
              onClick={() => {
                setSuccessShow(false);
              }}
            >
              Close
            </Button>
            <NavLink
              to={`/brands/detail?id=${idSuccess}`}
              onClick={() => {
                setSuccessShow(false);
              }}
            >
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid">Go To</Button>
            </NavLink>
          </div>
        </div>,
      );
      break;
    case 'newSolution':
      typeModalShow.push(
        <div key='newSolution' className="dtx-c-modal-content">
          <div className="dtx-c-modal-text">
            <div className="dtx-c-modal-icon">
              <FeatherIcon icon={'check-square'} />
              <h6>Solution created successfully</h6>
            </div>
            <p className="span1">
              The solution has been created. If you want to submit the approval request, you need to configure the
              solution. You can proceed with the configuration right now or save it in draft for later.
            </p>
          </div>
          <div className="dtx-c-btn-row">
            <Button
              className="dtx-c-btn btn__small dtx-c-btn__outline"
              onClick={() => {
                setSuccessShow(false);
              }}
            >
              Save in Draft
            </Button>

            <Button
              className="dtx-c-btn btn__small dtx-c-btn__solid"
              onClick={() => {
                setConfigurationModal(true);
                setSuccessShow(false);
              }}
            >
              Configure Now
            </Button>
          </div>
        </div>,
      );
      break;
    case 'updateSolution':
      typeModalShow.push(
        <div key='updateSolution' className="dtx-c-modal-content">
          <div className="dtx-c-modal-text">
            <div className="dtx-c-modal-icon">
              <FeatherIcon icon={'check-square'} />
              <h6>Solution activation successfully</h6>
            </div>
            <p className="span1">
              The solution has been activate.
            </p>
          </div>
          <div className="dtx-c-btn-row">
            <Button
              className="dtx-c-btn btn__small dtx-c-btn__outline"
              onClick={() => {
                setSuccessShow(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>,
      );
      break;
    default:
  }

  const handleCancel = e => {
    setSuccessShow(false);
  };

  return (
    <Modal
      visible={successShow}
      okText="Add Element"
      cancelText="Go Back"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose={true}
      className="dtx-c-modal__success"
    >
      {typeModalShow}
    </Modal>
  );
}
