import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { editSearchSolutionFilters, searchSolutions } from '../../../redux/solutions/actionCreator';
import AllSolutions from '../../tables/solutions/AllSolutions';
import FeatherIcon from 'feather-icons-react';

const { TabPane } = Tabs;

const AllSolutionsTabs = ({ id }) => {

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(3);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nSolution, setNSolution] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [listRowID, setListRowID] = useState([]);

  const [filters, setFilters] = useState({
    filter_service_name: '',
    order_service_name: '',
    order_number_users: '',
    order_service_created_at: '',
  });

  const { updating } = useSelector(state => {
    return {
      updating: state.ChangeLayoutMode,
    };
  });

  function callback(key) {
    setCurrentTab(key);
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      setLoading(true);
    dispatch(
      searchSolutions('', currentTab, currentPage, filters, (response, total) => {
        if (isMounted) {
          let listRowID = [];
          // console.log(response)
          response.map(brand => {
            listRowID.push(brand.id);
          });
          setListRowID(listRowID);
          setData(response);
          setNSolution(total);
          setLoading(false);
        }
      }),
    );
    return () => { isMounted = false };
  }, [dispatch, currentTab, updating, currentPage, filters]);

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    setFilters({
      ...filters,
      filter_service_name: changedValues[key],
    });
  };

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form name="basic" layout="inline" onValuesChange={onValuesChange} onFinish={() => {
          let isMounted = true;
          if (isMounted)
            setLoading(true);
          dispatch(
            searchSolutions('', currentTab, currentPage, filters, (response, total) => {
              if (isMounted) {
                let listRowID = [];
                // console.log(response)
                response.map(brand => {
                  listRowID.push(brand.id);
                });
                setListRowID(listRowID);
                setData(response);
                setNSolution(total);
                setLoading(false);
              }
            }),
          );
          return () => { isMounted = false };
        }}>
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>
            <Form.Item className="dtx-c-field" name="name">
              <Input placeholder="Filter by Solution Name" className="dtx-c-field__input" />
            </Form.Item>
            {/* <Form.Item className="dtx-c-field" name="category">
              <Input placeholder="Filter by Category" className='dtx-c-field__input'/>
            </Form.Item>
            <Form.Item className="dtx-c-field" name="client">
              <Input placeholder="Filter by Client" className='dtx-c-field__input'/>
            </Form.Item>
            <Form.Item className="dtx-c-field" name="brand">
              <Input placeholder="Filter by Brand" className='dtx-c-field__input'/>
            </Form.Item>
            <Form.Item className="dtx-c-field" name="owner">
              <Input placeholder="Filter by Owner" className='dtx-c-field__input'/>
            </Form.Item> */}
            <Form.Item className="">
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="dtx-c-table">
        <div className="dtx-c-table-header">
          <h6>All Solutions</h6>
          <Tabs defaultActiveKey="3" onChange={callback}>
            <TabPane tab="Error" key="-1" />
            <TabPane tab="Draft / Suspended" key="0" />
            <TabPane tab="Active" key="3" />
          </Tabs>
        </div>
        <div className="dtx-c-table-content">
          {/* <div className="dtx-c-table__composed"> */}
          <div>
            <AllSolutions
              currentTab={currentTab}
              data={data}
              nSolution={nSolution}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              listRowID={listRowID}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSolutionsTabs;
