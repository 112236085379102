import React from 'react';
import { HorizontalBar, Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { chartLinearGradient } from '../../../../components/utilities/utilities';
import { format_number } from '../../../../utility/utility';
import { Tooltip } from 'antd';

const getTag = volume => {
  let response = [];

  switch (volume) {
    case 3:
      response.push(
        <p key={volume} className="span4 m-blue">
          NEW
        </p>,
      );
      break;
    case 2:
      response.push(
        <p key={volume} className="span4 s-error">
          high volume
        </p>,
      );
      break;
    case 0:
      response.push(
        <p key={volume} className="span4 s-warning">
          weak volume
        </p>,
      );
      break;
    case 1:
      response.push(
        <p key={volume} className="span4 s-success">
          low volume
        </p>,
      );
      break;
    default:
      <div></div>;
  }

  // console.log(response);

  return response;
};

const KeywordsCardChart = ({ response, title, labels, service_name, service_id }) => {
  // console.log(response);

  let { datasets, options } = {
    options: {
      elements: {
        point: {
          radius: 1,
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 10,
          bottom: 0,
        },
      },
      labels: {
        display: false,
      },
      title: {
        display: false,
        text: 'High Volume Constant Signal',
      },
      scales: {
        yAxes: [
          {
            display: false,
            stacked: true,
            gridLines: {
              display: false,
              color: '#99CBF2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 10,
              display: false,
              stepSize: 20,
            },
          },
        ],
        xAxes: [
          {
            display: false,
            stacked: true,
            gridLines: {
              display: false,
              color: '#99CBF2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 11,
              display: false,
              color: '#99CBF2',
            },
          },
        ],
      },
    },

    datasets: [
      {
        data: response.historical_volume.volume,
        borderColor: '#53a2fc',
        borderWidth: 2,
        fill: true,
        backgroundColor: () =>
          chartLinearGradient(document.getElementById('card-chart'), 300, {
            start: '#53a2fc30',
            end: '#ffffff10',
          }),
        pointHoverBorderColor: 'transparent',
      },
    ],
  };
  const data = {
    datasets,
    labels,
  };

  return (
    <Link
      to={{
        pathname: '/keyword',
        search: `?name=${response.keyword}`,
        state: {
          KeywordData: response,
          service_id: service_id,
          location: {
            link: `/solutions/detail?id=${service_id}`,
            title: `${service_name}`,
          },
        },
      }}
    >
      <div className="dtx-c-card">
        <div className="dtx-c-card-content">
          <div className="dtx-c-card-title">
            {getTag(response.type)}
            {/* <Link to={`/keyword?name=${response.keyword}`} className="m-secondary" keyworddata={response} /> */}
            {/* {response.type} */}
            <div className="m-secondary">
              <p className="label1">{response.keyword}</p>
            </div>
          </div>
          <div className="dtx-c-tag-row">
            {response.category_1 !== 'ND' && (
              <div className="dtx-c-tag">
                <p className="span3">{response.category_1}</p>
              </div>
            )}
            {response.category_2 !== 'ND' && (
              <div className="dtx-c-tag">
                <p className="span3">{response.category_2}</p>
              </div>
            )}
            {response.category_3 !== 'ND' && (
              <div className="dtx-c-tag">
                <p className="span3">{response.category_3}</p>
              </div>
            )}
          </div>
          <div className="dtx-c-card-info-row">
            <div className="dtx-c-card-info">
              <p className="span3">Search Volume</p>
              <div className="dtx-c-table__value">
                <p className="label1">{format_number(response.average_volume, 0)}</p>
              </div>
            </div>
            {response.type !== 3 ? (
              <div className="dtx-c-card-info">
                <p className="span3">Growth</p>
                <span className="growth">
                  {parseInt(response.growth_rate) > 0 ? (
                    <div className="dtx-c-table__value">
                      <p className="label1 s-success">+{format_number(response.growth_rate, 2) + ' %'}</p>
                      <FeatherIcon icon="arrow-up" className="s-success" />
                    </div>
                  ) : (
                    <div className="dtx-c-table__value">
                      <p className="label1 s-error">{format_number(response.growth_rate, 2) + ' %'}</p>
                      <FeatherIcon icon="arrow-down" className="s-error" />
                    </div>
                  )}
                </span>
              </div>
            ) : (
              <div className="dtx-c-card-info-new">
                <div className="dtx-c-tag-new">
                  <p className="span3">NEW</p>
                </div>
                <Tooltip title="This is a new keyword that had not been seen previously">
                <FeatherIcon icon="help-circle" color="#5b5a7c" size="18" className="ml-5px" />
              </Tooltip>
              </div>
            )}
          </div>
        </div>
        <Line id="card-chart" data={data} options={options} />
      </div>
    </Link>
  );
};

export default KeywordsCardChart;
