import React, { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postAllUsersSearchBrands, postAllUsersSearchBrands_new } from '../../../redux/brands/actionCreator';
import { getAllUser_brand_detail, getClientUsers, postDeleteUser } from '../../../redux/users/actionCreator';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import FeatherIcon from 'feather-icons-react';
import Drawers from '../../drawer';


const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const queryString = require('query-string');


const AllUserForThisTable = ({ id }) => {
  // console.log("AllUserForThisTable: ", id)

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [parsed] = useState(queryString.parse(window.location.search));


  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };

  const [recordUpdateUser, setRecordUpdateUser] = useState();
  const [drawerName, setDrawerName] = useState();
  const [visible, setVisible] = useState(false);

  /**
   * Setup Function Drawer
   */
  const showDrawer = value => {
    // console.log(value);
    setDrawerName(value);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Surname',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (value, record) => (
        <div className="dtx-c-table__action">
          <div
            className="dtx-c-btn__icon"
            onClick={() => {
              setRecordUpdateUser(record);

              // console.log(record.user_groups[0]);

              switch (record.user_groups[0]) {
                case 'seouser':
                  showDrawer('updateSeoUser');
                  break;
                case 'seomaster':
                  showDrawer('updateMasterUser');
                  break;
                case 'client':
                  showDrawer('updateClientUser');
                  break;
                default:
              }
            }}
          >
            <FeatherIcon icon={'edit'} className="m-extralightgrey" />
          </div>
          <div className="dtx-c-btn__icon s-error" onClick={() => showModal({ user_id: record.id })}>
            <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
          </div>
        </div>
      ),
    },
  ];

  const { updating, brandsDetail } = useSelector(state => {
    return {
      updating: state.ChangeLayoutMode,
      brandsDetail: state.brandsReducer.brandsDetail,
    };
  });

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAllUser_brand_detail(
        { brand_id: parsed['id'], get_seousers: false, get_clientusers: true, page: 5 },
        response => {
          setData(response);

          setLoading(false);
        },
      ),
    );

    
  }, [dispatch, id, updating]);

  return (
    <div className="dtx-c-table">
      <div className="dtx-c-table-header">
        <h6>All Users</h6>
      </div>
      <div className="dtx-c-table-content">
        <Table columns={columns} dataSource={data} pagination={{pageSize: 5}} loading={loading} rowKey={record => record.id} />
      </div>
      <DeleteConfirmModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        func={postDeleteUser}
        form={formDelete}
      />
      <Drawers
        drawerName={drawerName}
        visible={visible}
        onClose={onClose}
        id={id}
        record={recordUpdateUser}
        idCustomer={parsed['id']}
      />
    </div>
  );
};

export default AllUserForThisTable;
