import React, { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerCard } from '../../../redux/customers/actionCreator';
import IndexModals from '../../../components/modals/indexModals';
import IndexModalsMaster from '../../../components/modals/indexModalsMaster';
import Drawers from '../../../components/drawer';
import ClientsTable from '../../../components/tables/ClientsTable';
import CardItems from '../../../components/homepage/CardItems';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';

const Customer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { customerCard, loadingCustomerCard, level } = useSelector(state => {
    return {
      customerCard: state.customersReducer.customerCard,
      loadingCustomerCard: state.customersReducer.loadingCustomerCard,
      level: state.user.level,
    };
  });

  const [showModalsCard] = useState(
    level.user.is_superuser || level.user_groups[0] === 'seomaster' ? ['newClient','newBrand', 'newUser' ] : ['newBrand'],
  );

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Modal
   */
  const [isModalVisibleSpecialist, setIsModalVisibleSpecialist] = useState(false);

  const showModalSpecialist = () => {
    setIsModalVisibleSpecialist(true);
  };

  const [visible, setVisible] = useState(false);
  const [drawerName, setDrawerName] = useState();

  const showDrawer = name => {
    if ((level.user.is_superuser || level.user_groups[0] === 'seomaster') && name === 'newUser') {
      showModalSpecialist();
    } else if (name === 'newUser') {
      setDrawerName('newClientUser');
      setVisible(true);
    } else {
      setDrawerName(name);
      setVisible(true);
    }
  };

  const onClose = () => {
    // console.log('Close');
    setVisible(false);
  };

  useEffect(() => {
    dispatch(getCustomerCard());
  }, [dispatch]);

  return (
    <div className="dtx-l-main">
      <div className="dtx-l-header">
        <h4>Customers</h4>
        <Button className="dtx-c-btn btn__small dtx-c-btn__solid" onClick={showModal}>
          <FeatherIcon icon={'plus'} />
          Add New
        </Button>
      </div>

      <Row className="dtx-c-card-row mb-3-5">
        <CardItems type={'clients'} values={customerCard} loading={loadingCustomerCard} />
      </Row>

      <ClientsTable />

      <IndexModals
        title="Add New Element"
        containTitle="Select which element you want to add"
        listShowModals={showModalsCard}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <IndexModalsMaster
        listShowModals={['newClientUser', 'newSpecialistUser', 'newMasterUser']}
        isModalVisibleSpecialist={isModalVisibleSpecialist}
        setIsModalVisibleSpecialist={setIsModalVisibleSpecialist}
        showModalSpecialist={showModalSpecialist}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <Drawers drawerName={drawerName} visible={visible} onClose={onClose} />
    </div>
  );
};

export default Customer;
