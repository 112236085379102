const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  RESET_PASSWORD_BEGIN: 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERR: 'RESET_PASSWORD_ERR',

  NEW_PASSWORD_BEGIN: 'NEW_PASSWORD_BEGIN',
  NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_ERR: 'NEW_PASSWORD_ERR',

  GET_EXPIRED: 'GET_EXPIRED',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  resetPasswordBegin: () => {
    return {
      type: actions.RESET_PASSWORD_BEGIN,
    };
  },

  resetPasswordSuccess: data => {
    return {
      type: actions.RESET_PASSWORD_SUCCESS,
      data,
    };
  },

  resetPasswordErr: err => {
    return {
      type: actions.RESET_PASSWORD_ERR,
      err,
    };
  },
  
  newPasswordBegin: () => {
    return {
      type: actions.NEW_PASSWORD_BEGIN,
    };
  },

  newPasswordSuccess: data => {
    return {
      type: actions.NEW_PASSWORD_SUCCESS,
      data,
    };
  },

  newPasswordErr: err => {
    return {
      type: actions.NEW_PASSWORD_ERR,
      err,
    };
  },

  getExpired: data =>{
    return {
      type: actions.GET_EXPIRED,
      data
    }
  }
  
};

export default actions;
