import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import FontAwesome from 'react-fontawesome';

const DeleteConfirmModal = ({ isModalVisible, setIsModalVisible, form, func }) => {
  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(func(form));
    setIsModalVisible(false);
  };

  const handleCancel = e => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Delete Confirm"
      visible={isModalVisible}
      okText="Delete"
      cancelText="Cancel"
      onOk={handleOk}
      onCancel={handleCancel}
      className='dtx-c-modal dtx-c-modal__delete'
    >
      <div className="dtx-c-modal-content">
        <div className="dtx-c-modal-text">
          <p className='span1'>Are you sure you want to remove this?</p>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmModal;
