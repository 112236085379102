import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Switch, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/users/actionCreator';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import TrendSonarConfigurationEdit from '../../modals/TrendSonarConfigurationEdit';
import TrendSonarConfiguration from '../../modals/TrendSonarConfiguration';

import { postDeleteSolution, updateService, updateVisibility } from '../../../redux/solutions/actionCreator';
import FeatherIcon from 'feather-icons-react';
import SuspendedSolutionConfirmModal from '../../modals/SuspendedSolutionConfirmModal';
import { showConfirm, showWarning } from '../../../utility/utility';
import { setUpdating } from '../../../redux/themeLayout/actionCreator';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const getTag = status => {
  // console.log(status);

  let response = [];

  switch (status) {
    case 0:
      response.push(
        <div key={0} className="dtx-c-tag back-b-grey3">
          <p className="span3">Draft</p>
        </div>,
      );
      break;
    case 1:
      response.push(
        <div key={1} className="dtx-c-tag back-b-grey3">
          <p className="span3">Need Approval</p>
        </div>,
      );
      break;
    case 2:
      response.push(
        <div key={2} className="dtx-c-tag back-b-grey3">
          <p className="span3">Under Activation</p>
        </div>,
      );
      break;
    case 3:
      response.push(
        <div key={3} className="dtx-c-tag back-b-grey3">
          <p className="span3">Active</p>
        </div>,
      );
      break;
    default:
      <div></div>;
  }

  // console.log(response);

  return response;
};

const AllSolutions = ({
  listRowID,
  currentTab,
  data,
  nSolution,
  currentPage,
  setCurrentPage,
  filters,
  setFilters,
  loading,
}) => {
  // console.log('currentTab: ===>', currentTab);

  const dispatch = useDispatch();

  const [record, setRecord] = useState();

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };
  const [formSuspended, setFormSuspended] = useState({});
  const [isSupendedModalVisible, setSupendedModalVisible] = useState(false);

  const [stateCheck, setStateCheck] = useState({});

  /**
   * Modal
   */
  const [idSuccess, setIdSuccess] = useState(null);

  const [configurationModal, setConfigurationModal] = useState(false);

  const showConfigurationModal = () => {
    setConfigurationModal(true);
  };

  const [configurationEditModal, setConfigurationEditModal] = useState(false);

  const showConfigurationEditModal = () => {
    setConfigurationEditModal(true);
  };

  useEffect(() => {
    // console.log('currentTab: ', currentTab);

    let newStateCheck = {};

    if (currentTab === '3' || currentTab === 3) {
      // console.log('Entro', data);

      data.map(item => {
        // console.log('Item: ', item);
        newStateCheck[item.id] = item.is_visible;
      });
    } else {
      newStateCheck = {};
    }

    // console.log(newStateCheck)
    setStateCheck(newStateCheck);
  }, [data]);

  const columns = {
    "-1": [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Client',
        dataIndex: 'customer_name',
        key: 'customer_name',
        render: (value, record) => <div>{record.brand.customer !== undefined && record.brand.customer.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand_name',
        render: (value, record) => <div>{record.brand.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (values, record) => {
          return (
            <div className="dtx-c-table__action">
              <div
                className="dtx-c-btn__icon"
                onClick={() => {
                  setRecord(record);
                  // setIdSuccess(record.id)
                  showConfigurationEditModal(true);
                }}
              >
                <FeatherIcon icon={'settings'} className="m-extralightgrey" />
              </div>
              <div className="dtx-c-btn__icon s-error" onClick={() => showModal({ service_id: record.id })}>
                <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
              </div>
            </div>
          );
        },
      },
    ],
    0: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Client',
        dataIndex: 'customer_name',
        key: 'customer_name',
        render: (value, record) => <div>{record.brand.customer !== undefined && record.brand.customer.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand_name',
        render: (value, record) => <div>{record.brand.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (values, record) => {
          return (
            <div className="dtx-c-table__action">
              <div
                className="dtx-c-btn__icon"
                onClick={() => {
                  setRecord(record);
                  // setIdSuccess(record.id)
                  showConfigurationEditModal(true);
                }}
              >
                <FeatherIcon icon={'settings'} className="m-extralightgrey" />
              </div>
              <div className="dtx-c-btn__icon s-error" onClick={() => showModal({ service_id: record.id })}>
                <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
              </div>
            </div>
          );
        },
      },
    ],
    3: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
        render: (value, record) => (
          <Link
            // to={`/solutions/detail?id=${record.id}`}
            to={{
              pathname: '/solutions/detail',
              search: `?id=${record.id}`,
              state: {
                location: {
                  link: `/solutions`,
                  title: `Solutions`,
                },
              },
            }}
            className="m-secondary"
          >
            {value}
          </Link>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Client',
        dataIndex: 'customer_name',
        key: 'customer_name',
        render: (value, record) => <div>{record.brand.customer !== undefined && record.brand.customer.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand_name',
        render: (value, record) => <div>{record.brand.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: 'Client Visibility',
        dataIndex: 'is_visible',
        key: 'is_visible',
        render: (values, record) => (
          <Switch
            // defaultChecked={values}
            checked={stateCheck[record.id]}
            checkedChildren="Visible"
            unCheckedChildren="Invisible"
            onChange={value => {
              if (value) {
                showConfirm(
                  'Solution Visualization',
                  `You are trying to set up the solution to "visibile".
                The Customer User will receive an email and could access to the platform and see the solution. Do you want to proceed?`,
                  condition => {
                    if (condition) {
                      setStateCheck({
                        ...stateCheck,
                        [record.id]: value
                      });

                      dispatch(
                        updateVisibility(
                          {
                            service_id: record.id,
                            is_visible: value,
                          },
                          response => {
                            // console.log(response);
                          },
                        ),
                      );
                    } 
                  },
                );
              } else {
                setStateCheck(value)

                dispatch(
                  updateVisibility(
                    {
                      service_id: record.id,
                      is_visible: value,
                    },
                    response => {
                      // console.log(response);
                    },
                  ),
                );
              }
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (value, record) => {
          return (
            <div className="dtx-c-table__action">
              {record.is_visible ? (
                <div className="dtx-c-btn__icon m-grey">
                  <FeatherIcon
                    icon={'info'}
                    className="m-extralightgrey"
                    onClick={() => {
                      setRecord(record);
                      // setIdSuccess(record.id)
                      showConfigurationEditModal(true);
                    }}
                  />
                </div>
              ) : (
                <div className="dtx-c-btn__icon s-warning">
                  <FeatherIcon
                    icon={'pause-circle'}
                    className="s-warning"
                    onClick={() => {
                      // console.log(record);
                      setFormSuspended({
                        service_id: record.id,
                        status: 0,
                      });

                      setSupendedModalVisible(true);
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {

    // console.log(sorter)

    if (pagination.current !== currentPage) {
      // setStatusTable(true);
      setCurrentPage(pagination.current);
    }

    if (sorter !== undefined) {
      setFilters({
        ...filters,
        order_service_name: '',
        order_number_users: '',
        order_service_created_at: '',
      });

      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        if (order !== undefined) {
          setFilters({
            ...filters,
            [`order_service_${field}`]: order === 'ascend' ? 1 : 2,
          });
        } else {
          setFilters({
            ...filters,
            [`order_service_${field}`]: '',
          });
        }
      }
    }
  };

  return (
    <div className="dtx-c-table__composed">
      <Table
        columns={columns[currentTab]}
        dataSource={data}
        pagination={{ position: ['bottomCenter'], pageSize: 10, total: nSolution }}
        loading={loading}
        rowKey={record => record.id}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: record => {
             return record.service_config.length > 0 && record.service_config[0].keyword_mapping_name !== undefined? (
                <div key={record.id} className="dtx-c-table-subrow">
                  <span className="span3">Data:</span> <div className="dtx-c-tag back-b-grey3">
                  <span className="span3">{record.service_config[0].keyword_mapping_name}</span></div>
                </div>) : <div/>
            }
          ,
          expandedRowKeys: listRowID,
        }}
        defaultExpandAllRows={true}
        showExpandColumn={true}
        expandIconAsCell={true}
        expandIconColumnIndex={-1}
      />

      <TrendSonarConfigurationEdit
        configurationEditModal={configurationEditModal}
        setConfigurationEditModal={setConfigurationEditModal}
        record={record}
        disabledModal={currentTab !== '0' && currentTab !== "-1" ? true : false}
      />
      {/* <TrendSonarConfiguration
        configurationModal={configurationModal}
        setConfigurationModal={setConfigurationModal}
        id={idSuccess}
      /> */}
      <DeleteConfirmModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        func={postDeleteSolution}
        form={formDelete}
      />

      <SuspendedSolutionConfirmModal
        isModalVisible={isSupendedModalVisible}
        setIsModalVisible={setSupendedModalVisible}
        func={updateService}
        form={formSuspended}
      />
    </div>
  );
};

export default AllSolutions;
