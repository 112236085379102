import React, { useEffect, useState } from 'react';
import { Empty, Pagination, Select, Skeleton } from 'antd';

import KeywordsCardChart from '../../charts/chartCards/keywords/KeywordsCardChart';

const { Option } = Select;

const AllKeywordCards = ({
  keywordData,
  keyWordFilters,
  setKeywordFilters,
  currentPage,
  setCurrentPage,
  totals,
  loading,
  service_name,
  service_id,
  defaultFilters,
  setDefaultValue,
  setOrderTable
}) => {
  const [allKeywordCardsData, setAllKeywordCardsData] = useState([]);

  useEffect(() => {
    // console.log("keywordData: ", keywordData);

    let newAllKeywordCardsData = [];

    if (keywordData.length > 0) {
      keywordData.map(keyword => {
        // console.log(keyword);
        newAllKeywordCardsData.push(
          <KeywordsCardChart
            key={keyword.keyword}
            service_id={service_id}
            service_name={service_name}
            response={keyword}
            labels={keyword.historical_volume.date}
          />,
        );
      });

      setAllKeywordCardsData(newAllKeywordCardsData);
    } else {
      newAllKeywordCardsData.push(<Empty />)

      setAllKeywordCardsData(newAllKeywordCardsData);
    }
  }, [keywordData]);

  const onChangeOrder = (field, value) => {
    console.log(field, value);
    console.log('');

    let newKeywordFilters = {
      ...keyWordFilters,
      order_keyword: '',
      order_typology: '',
      order_category_1: '',
      order_category_2: '',
      order_category_3: '',
      order_growth_rate: '',
      order_average_volume: '',
    };

    if (field === 'field_name') {
      newKeywordFilters = {
        ...newKeywordFilters,
        [value]: defaultFilters.value === 'Ascending' ? 1 : 2,
      };

      setDefaultValue({
        ...defaultFilters,
        field: value
      })
    } else {
      newKeywordFilters = {
        ...newKeywordFilters,
        [defaultFilters.field]: value === 'Ascending' ? 1 : 2,
      };

      setDefaultValue({
        ...defaultFilters,
        value: value
      })
      
    }
    
    setOrderTable(true)
    setKeywordFilters(newKeywordFilters);
  };

  return (
    <>
      <div className="dtx-c-sorter mb-1-5">
        <span className="span2 m-grey">Sort by:</span>
        <Select
          defaultValue={defaultFilters.field}
          style={{ width: 120 }}
          bordered={false}
          onChange={value => onChangeOrder('field_name', value)}
          className="dtx-c-field__select"
        >
          <Option value="order_keyword">Name</Option>
          <Option value="order_category_1">Cluster</Option>
          <Option value="order_category_2">Category</Option>
          <Option value="order_category_3">Sub-Category</Option>
          <Option value="order_average_volume">Volume</Option>
          <Option value="order_growth_rate">Growth</Option>
        </Select>
        <Select
          defaultValue={defaultFilters.value}
          style={{ width: 120 }}
          bordered={false}
          className="dtx-c-field__select"
          onChange={value => onChangeOrder('value', value)}
        >
          <Option value="Ascending">Ascending</Option>
          <Option value="Descending">Descending</Option>
        </Select>
      </div>
      <div className="dtx-c-table__card-pagination">
        <div id="kw-card" className="dtx-c-plot-grid">
          {loading ? <Skeleton active paragraph={{ rows: 18 }} className="dtx-c-skeleton-table" /> : allKeywordCardsData}
        </div>
        <Pagination
          className="ant-table-pagination ant-table-pagination-right"
          defaultCurrent={1}
          defaultPageSize={10}
          current={currentPage}
          total={totals}
          onChange={page => {
            setCurrentPage(page);
            setOrderTable(true);
          }}
        />
      </div>
    </>
  );
};

export default AllKeywordCards;
