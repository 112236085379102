import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Cascader, InputNumber, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AllKeywordTable from '../../tables/keywords/AllKeywordTable';
import AllKeywordCards from '../../tables/keywords/AllKeywordCards';
import FeatherIcon from 'feather-icons-react';
import { retrieveFilterValues, searchKewords } from '../../../redux/keywords/actionCreator';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SearchVolumeSwitch = ({ service_name, service_id, time_interval_visualization }) => {
  const [maxValue, setMaxValue] = useState('');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tableLayout, setTableLayout] = useState(true);
  const [keywordData, setKeywordData] = useState([]);
  const [typologyOptions, setTypologyOptions] = useState([]);
  const [cascadeItem, setCascadeItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totals, setTotals] = useState();
  const [loading, setLoading] = useState(true);
  const [keyWordFilters, setKeywordFilters] = useState({
    return_filter_values: true,
    service_id: service_id,
    page: currentPage,
    filter_keyword: '',
    filter_typology: '',
    filter_category_1: '',
    filter_category_2: '',
    filter_category_3: '',
    filter_volume_lower_boundary: '',
    filter_volume_upper_boundary: '',
    order_keyword: '',
    order_typology: '',
    order_category_1: '',
    order_category_2: '',
    order_category_3: '',
    order_growth_rate: '2',
    order_volume: '',
  });
  const [orderTable, setOrderTable] = useState(true);

  const [defaultFilters, setDefaultValue] = useState({
    field: 'order_growth_rate',
    value: 'Descending',
  });

  const { updating } = useSelector(state => {
    return {
      updating: state.ChangeLayoutMode,
    };
  });

  /**
   * Call for UserData
   */
  useEffect(() => {
    dispatch(
      retrieveFilterValues({ service_id: service_id }, response => {
        // console.log(response);

        if (!response.message) {
          let filterOptions = response;

          /** TYPOLOGY */

          let newTypologyOptions = [];

          newTypologyOptions.push(
            filterOptions['typology'].map(typology => (
              <Option key={typology} value={typology}>
                {typology}
              </Option>
            )),
          );

          setTypologyOptions(newTypologyOptions);

          // console.log(newTypologyOptions);

          // /** CATEGORY_1 */

          let new_config = [
            {
              value: '',
              label: 'All',
              children: [],
            },
          ];

          for (let key in filterOptions['categories']) {
            // console.log(key, filterOptions['categories'][key]);

            let item = {
              value: key,
              label: key,
              children: [],
            };

            for (let child in filterOptions['categories'][key]) {
              // console.log(filterOptions['categories'][key][child]);

              if (child !== 'ND') {
                let children = {
                  value: child,
                  label: child,
                  children: [],
                };

                for (let subChild in filterOptions['categories'][key][child]) {
                  // console.log(filterOptions['categories'][key][child][subChild]);

                  if (filterOptions['categories'][key][child][subChild] !== 'ND') {
                    let subChildren = {
                      value: filterOptions['categories'][key][child][subChild],
                      label: filterOptions['categories'][key][child][subChild],
                    };

                    children.children.push(subChildren);
                  }
                }

                item.children.push(children);
              }
            }
            new_config.push(item);
          }
          setCascadeItem(new_config);

          setMaxValue(response.max_keyword_volume);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (orderTable) {
      setLoading(true);
      dispatch(
        searchKewords(
          { ...keyWordFilters, page: currentPage, time_interval_visualization: time_interval_visualization },
          response => {
            setTotals(response.total_filtered_records);
            setKeywordData(response.service_keywords);
            setLoading(false);
            setOrderTable(false);
          },
        ),
      );
    }
  }, [currentPage, orderTable]);

  // const debounced = useDebouncedCallback((key, value) => {
  //   console.log(`filter_${key}`, value[key]);

  //   if (key === 'volume_upper_boundary') {
  //     if (parseInt(value[key]) > parseInt(maxValue)) {
  //       form.setFieldsValue({ volume_upper_boundary: parseInt(maxValue) });

  //       setKeywordFilters({
  //         ...keyWordFilters,
  //         [`filter_${key}`]: maxValue,
  //       });
  //     } else {
  //       setKeywordFilters({
  //         ...keyWordFilters,
  //         [`filter_${key}`]: value[key],
  //       });
  //     }
  //   } else {
  //     setKeywordFilters({
  //       ...keyWordFilters,
  //       [`filter_${key}`]: value[key],
  //     });
  //   }
  // }, 1000);

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    if (key === 'category_1') {
      let clusters = {
        filter_category_1: '',
        filter_category_2: '',
        filter_category_3: '',
      };

      changedValues[key].map((value, i) => {
        switch (i) {
          case 0:
            clusters['filter_category_1'] = value;
            break;
          case 1:
            clusters['filter_category_2'] = value;
            break;
          case 2:
            clusters['filter_category_3'] = value;
            break;
          default:
        }
      });

      setKeywordFilters({
        ...keyWordFilters,
        ...clusters,
      });
    } else {
      setKeywordFilters({
        ...keyWordFilters,
        [`filter_${key}`]: changedValues[key],
      });
    }
  };

  function onChange(value, selectedOptions) {
    // console.log(value, selectedOptions);
  }

  function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form
          name="basic"
          form={form}
          layout="inline"
          onValuesChange={onValuesChange}
          onFinish={() => {
            setOrderTable(true);
          }}
          {...layout}
        >
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>

            <Form.Item className="dtx-c-field" name="keyword">
              <Input placeholder="Filter by Keyword" className="dtx-c-field__input" />
            </Form.Item>

            <Form.Item className="dtx-c-field" name="typology">
              <Select allowClear placeholder="Filter by Typology" className="dtx-c-field__select">
                {typologyOptions}
              </Select>
            </Form.Item>

            <Form.Item className="dtx-c-field" name="category_1">
              <Cascader
                options={cascadeItem}
                onChange={onChange}
                placeholder="Filter by Category"
                className="dtx-c-field__cascader"
                showSearch={{ filter }}
                changeOnSelect
                // onSearch={value => console.log(value)}
              />
            </Form.Item>

            <Form.Item className="dtx-c-field" name="volume_lower_boundary" rules={[{ type: 'number' }]}>
              <InputNumber
                placeholder="Search Volume MIN"
                min={0}
                max={parseInt(maxValue) - 1}
                className="dtx-c-field__number"
              />
            </Form.Item>
            <Form.Item
              className="dtx-c-field"
              name="volume_upper_boundary"
              getValueProps={value => {
                if (parseInt(value) > parseInt(maxValue)) {
                  // form.setFieldsValue({'volume_upper_boundary': maxValue})
                  const fields = form.getFieldsValue();

                  let newForm = {
                    ...fields,
                    volume_upper_boundary: maxValue,
                  };

                  form.setFieldsValue(newForm);
                }
              }}
              rules={[
                { type: 'number' },
                {
                  validator: (_, value) => (value > maxValue ? Promise.resolve(maxValue) : Promise.resolve(value)),
                },
              ]}
            >
              <InputNumber placeholder="Search Volume Max" max={parseInt(maxValue)} className="dtx-c-field__number" />
            </Form.Item>
            <Form.Item className="">
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className="dtx-c-switcher">
          <div
            className={tableLayout ? `dtx-c-switcher-icon dtx-u-active` : 'dtx-c-switcher-icon'}
            onClick={() => {
              setTableLayout(true);
            }}
          >
            <FeatherIcon icon={'grid'} />
          </div>
          <div
            className={tableLayout ? 'dtx-c-switcher-icon' : `dtx-c-switcher-icon dtx-u-active`}
            onClick={() => {
              setTableLayout(false);
            }}
          >
            <FeatherIcon icon={'list'} />
          </div>
        </div>
      </div>

      {tableLayout ? (
        <AllKeywordCards
          keywordData={keywordData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totals={totals}
          loading={loading}
          service_name={service_name}
          service_id={service_id}
          keyWordFilters={keyWordFilters}
          setKeywordFilters={setKeywordFilters}
          defaultFilters={defaultFilters}
          setDefaultValue={setDefaultValue}
          setOrderTable={setOrderTable}
        />
      ) : (
        <div className="dtx-c-homepage__single-card__table">
          <div className="single-card__table-content_noEx">
            <AllKeywordTable
              keywordData={keywordData}
              keyWordFilters={keyWordFilters}
              setKeywordFilters={setKeywordFilters}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totals={totals}
              loading={loading}
              service_name={service_name}
              service_id={service_id}
              defaultFilters={defaultFilters}
              setDefaultValue={setDefaultValue}
              setOrderTable={setOrderTable}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchVolumeSwitch;