import { Skeleton } from 'antd';
import React from 'react';
import { HorizontalBar, Line } from 'react-chartjs-2';

const KeywordLineChart = ({ response, title, labels }) => {
  // console.log(response);
  // console.log(labels);

  let { datasets, options } = {
    options: {
      responsive: true,

      legend: {
        display: true,
        position: 'bottom',
      },
      labels: {
        display: true,
      },
      title: {
        display: false,
        text: 'High Volume Constant Signal',
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: '#e5e9f2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 10,
              fontColor: '#182b49',
            },
          },
        ],
      },
    },

    datasets: response,
  };
  const data = {
    datasets,
    labels,
  };

  return <Line data={data} options={options} height={70} />;
};

export default KeywordLineChart;
