const actions = {
  SEARCH_BRANDS : 'SEARCH_BRANDS',
  LOADING_BRANDS : 'LOADING_BRANDS',
  SET_N_BRANDS: 'SET_N_BRANDS',
  SET_FILTERS: 'SET_FILTERS',
  SET_BRANDS_DETAIL: 'SET_BRANDS_DETAIL',

  searchBrands: (data) => {
    return {
      type: actions.SEARCH_BRANDS,
      data
    };
  },

  loading: (data) => {
    return {
      type: actions.LOADING_BRANDS,
      data
    };
  },

  setNBrands: (data) => {
    return {
      type: actions.SET_N_BRANDS,
      data
    };
  },

  setFilters: (data) => {
    return {
      type: actions.SET_FILTERS,
      data
    };
  },

  setBrandsDetail: (data) => {

    return {
      type: actions.SET_BRANDS_DETAIL,
      data
    };
  },
};

export default actions;
