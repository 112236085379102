import { Button, Col, Form, Input, message, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CardEditProfile from '../components/cards/extra/CardEditProfile';
import CardNewPassword from '../components/cards/extra/CardNewPassword';

const Profilepage = () => {
  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  const [cardView, setCardView] = useState(1);

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    // dispatch(
    //   editSearchSolutionFilters({
    //     ...filters,
    //     name: changedValues[key],
    //   }),
    // );
  };

  const onValuesChangePassword = (changedValues, allValues) => {
    // console.log(allValues)
    // let key = Object.keys(changedValues)[0];
    // console.log(changedValues);
    // dispatch(
    //   editSearchSolutionFilters({
    //     ...filters,
    //     name: changedValues[key],
    //   }),
    // );
  };

  const handleSubmit = values => {
    console.log('error');
    if (values.current_password.length > 0 && values.new_password === values.re_new_password) {
      dispatch(newPassword());
    } else {
      message.error('error');
    }
  };

  return (
    <div className="dtx-l-homepage">
      <div className="Title-Row-homepage">
        <span className="Hi-Name">Profile Settings</span>
      </div>
      <Row gutter={[16, 8]} className="dtx-l-page__content no-padding">
        <Col xl={8} lg={8} md={12}>
          <div className="dtx-l-content__view no-padding">
            <div className="profile_avatar">
              <div className="Avatar-Section">
                <div className="Avatar">
                  <img src={'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'} alt="" />
                </div>
                <span className="Mariello-Pratapappo">{level.user.username}</span>
                <span className="SEM-Specialist">{level.user.email}</span>
              </div>
            </div>
            <div className="profile_actions">
              <Button
                className={`edit_profile_btn ${cardView === 1 ? 'active' : ''}`}
                onClick={() => {
                  setCardView(1);
                }}
              >
                edit
              </Button>
              <Button
                className={`edit_profile_btn ${cardView === 2 ? 'active' : ''}`}
                onClick={() => {
                  setCardView(2);
                }}
              >
                new password{' '}
              </Button>
            </div>
          </div>
        </Col>
        <Col xl={16} lg={16} md={12}>
          {cardView === 1 ? <CardEditProfile /> : <CardNewPassword />}
        </Col>
      </Row>
    </div>
  );
};

export default Profilepage;
