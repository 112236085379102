import { Button, Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawers from '../../../components/drawer';
import CardItems from '../../../components/homepage/CardItems';
import IndexModals from '../../../components/modals/indexModals';
import IndexModalsMaster from '../../../components/modals/indexModalsMaster';
import SuccessModals from '../../../components/modals/SuccessModals';
import TrendSonarConfiguration from '../../../components/modals/TrendSonarConfiguration';
import AllUserForThisTable from '../../../components/tables/users/AllUserForThisTable';
import SolutionsTabs from '../../../components/tabs/solutions/SolutionsTabs';
import { postReadBrands } from '../../../redux/brands/actionCreator';

import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import TrendSonarConfigurationEdit from '../../../components/modals/TrendSonarConfigurationEdit';

const queryString = require('query-string');

const checkLoading = (container, key) => {

  if (container !== undefined && container !== null) {
    return container;
  } else {
    return <Spin />;
  }
};

const BrandDetailpage = ({ props }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  let parsed = queryString.parse(window.location.search);

  const [test, setTest] = useState(null);
  const [recordUpdateBrand, setRecordUpdateBrand] = useState();

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  const [configurationEditModal, setConfigurationEditModal] = useState(false);

  const showConfigurationEditModal = () => {
    setConfigurationEditModal(true);
  };

  /**
   * UseEffect:
   *  -
   */

  /**
   * UseAsyncParamEffect: What does it do?
   * => use for avoid memory leak by use a flag to mark when the component is unmounted, if the component is unmounted, the success function won't be called.
   * Params list:
   *  - param for request (like id)
   *  - redux action, mus have two params are param and callback
   *  - onSuccess function that will be perform if component is still mounting.
   */

  useEffect(() => {
    dispatch(
      postReadBrands(parsed['id'], response => {
        let data = response.data.brands;
        let newTest = data.find(x => x.id === parseInt(parsed['id']));

        console.log(newTest)

        setTest(newTest);
      }),
    );
  }, []);

  /**
   * MODALS
   * - Couple of element for all modals:
   *  - isVisible, setVisible: for opened the modal
   *  - showModal: function for open the modal
   *
   * In the future i will delete the showModal for have one only function (setVisible in this case)
   *
   * */

  // this useState determinated how the user see in base pf the role of the your account
  const [showModalsCard] = useState(
    level.user.is_superuser || level.user_groups[0] === 'seomaster'
      ? ['newSolution', 'newUser', 'newExisting']
      : ['newSolution', 'newUser', 'newExisting'],
  );

  const [successItem, setSuccessItem] = useState();
  const [idSuccess, setIdSuccess] = useState(null);

  // indexModals variables
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // successModal variables
  const [successShow, setSuccessShow] = useState(false);

  const showSuccessModal = () => {
    setSuccessShow(true);
  };

  // configureModal variables
  const [configurationModal, setConfigurationModal] = useState(false);

  const showConfigurationModal = () => {
    setConfigurationModal(true);
  };

  // indexModalsMaster variable
  const [isModalVisibleSpecialist, setIsModalVisibleSpecialist] = useState(false);

  const showModalSpecialist = () => {
    setIsModalVisibleSpecialist(true);
  };

  /**
   * Drawers
   * - set list of item for open the one determinated drawer
   * */

  const [visible, setVisible] = useState(false);
  const [drawerName, setDrawerName] = useState();

  const showDrawer = name => {
    if ((level.user.is_superuser || level.user_groups[0] === 'seomaster') && name === 'newUser') {
      showModalSpecialist();
    } else if (name === 'newUser') {
      setDrawerName('newClientUser');
      setVisible(true);
    } else {
      setDrawerName(name);
      setVisible(true);
    }
  };

  const onClose = () => {
    // console.log('Close');
    setVisible(false);
  };

  // if (test === null) {
  //   return <Spin />
  // }

  /**
   * Return of this function
   */
  return (
    <div className="dtx-l-main">
      <div
        className="dtx-c-icon mb-1-5"
        onClick={() => {
          history.goBack();
        }}
      >
        <FeatherIcon icon="arrow-left" className="m-secondary" />
        <p className="label1 m-secondary">Back</p>
      </div>
      <div className="dtx-l-header">

        {
          test !== null ? <div className="dtx-c-header-info"> <h4>{test.name}</h4>

            <p className="span1 m-lightgrey">Created at {moment(test["created_at"]).format('DD/MM/YYYY')}</p></div> : <Spin />
        }


        <div className="dtx-c-header-action">
          <Button
            className="dtx-c-btn btn__small dtx-c-btn__secondary"
            onClick={() => {
              // console.log(test);
              setDrawerName('updateBrand');
              setRecordUpdateBrand(test);
              showDrawer('updateBrand');
            }}
          >
            <FeatherIcon icon="settings" className="m-primary" size={14} />
            Edit Brand
          </Button>
          <Button className="dtx-c-btn btn__small dtx-c-btn__solid" onClick={() => showModal()}>
            <FeatherIcon icon="plus" size={14} />
            Add New
          </Button>
        </div>
      </div>
      <Row className="mb-3-5">
        <div className="dtx-c-card-box">
          <h6 className="m-white">Overview</h6>
          {
            test !== null ? <div className="dtx-c-card-column">
              <CardItems type={'activeSolution'} values={test.number_active_solutions} loading={false} />
              <CardItems type={'activeToApprove'} values={test.number_solutions_under_approval} loading={false} />
            </div> : <Spin />
          }

        </div>
        <AllUserForThisTable id={parsed['id']} />
      </Row>
      <Row>
        <SolutionsTabs id={parsed['id']} brandName={test !== null ? test.name : ''} />


      </Row>

      <IndexModals
        title="Add New Element"
        containTitle="Select which element you want to add"
        listShowModals={showModalsCard}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <SuccessModals
        successShow={successShow}
        setSuccessShow={setSuccessShow}
        idSuccess={idSuccess}
        successItem={successItem}
        setConfigurationModal={setConfigurationEditModal}
      />

      <TrendSonarConfiguration
        configurationModal={configurationModal}
        setConfigurationModal={setConfigurationModal}
        id={idSuccess}
      />

      <TrendSonarConfigurationEdit
        configurationEditModal={configurationEditModal}
        setConfigurationEditModal={setConfigurationEditModal}
        record={{ id: idSuccess }}
      />

      <IndexModalsMaster
        listShowModals={['newClientUser', 'newSpecialistUser', 'newMasterUser']}
        isModalVisibleSpecialist={isModalVisibleSpecialist}
        setIsModalVisibleSpecialist={setIsModalVisibleSpecialist}
        showModalSpecialist={showModalSpecialist}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <Drawers
        drawerName={drawerName}
        visible={visible}
        onClose={onClose}
        id={level.user.id}
        setSuccessShow={setSuccessShow}
        setIdSuccess={setIdSuccess}
        setSuccessItem={setSuccessItem}
        record={recordUpdateBrand}
        idBrand={parsed['id']}
      />
    </div>
  );
};

export default BrandDetailpage;
