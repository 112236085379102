import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select } from 'antd';
import { postCreateCustomer, postUpdateCustomer } from '../../../redux/customers/actionCreator';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../../redux/users/actionCreator';

const { Option } = Select;

const NewClient = ({ visible, onClose, id, record }) => {
  // console.log(record);

  const dispatch = useDispatch();

  const [listUsers, setListUsers] = useState([]);

  useEffect(() => {
    dispatch(
      getUsers({ get_seousers: 'True', get_clients: 'False' }, values => {
        let newListUsers = [];

        newListUsers.push(
          values.map(user => <Option value={user.id}>{user.first_name + ' ' + user.last_name}</Option>),
        );

        setListUsers(newListUsers);
      }),
    );
  }, [dispatch]);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    let payload = {
      ...values,
      customer_id: record.id,
    };

    dispatch(
      postUpdateCustomer(payload, status => {
        if (status === 200) {
          setLoading(false);
          onClose();
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getUsersSelect = value => {
    let newUsers = [];

    value.map(user => {
      newUsers.push(user.id);
    });

    return newUsers;
  };

  return (
    <Drawer
      title={'Edit Customer'}
      className="dtx-c-drawer"
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            name: record !== undefined && record.name,
            website: record !== undefined && record.website,
            users:
              record !== undefined && record.seo_users !== undefined && record.seo_users.length > 0
                ? getUsersSelect(record.seo_users)
                : record.users !== undefined
                ? getUsersSelect(record.users)
                : undefined,
          }}
          {...layout}
        >
          <div className="dtx-c-field">
            <p className="label2 m-grey">Customer Name</p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="e.g. L’Oréal" className="dtx-c-field__input" />
            </Form.Item>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey">Customer Website (optional):</p>
            <Form.Item
              name="website"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="e.g. www.loreal.com/en" className="dtx-c-field__input" />
            </Form.Item>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey"> Select the Specialists to associate the Customer with:</p>
            <Form.Item
              name="users"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select mode="multiple" placeholder="Select a Specialist" className="dtx-c-field__select">
                {listUsers}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
              {loading ? <span className="Label">Loading...</span> : <span>Save Changes</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default NewClient;
