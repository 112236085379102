import Cookies from 'js-cookie';
import actions from './actions';

const {
  SET_PROFILE,
  SET_OVERVIEW_CARDS_VALUES,
  SET_OVERVIEW_CARDS_LOADING,
} = actions;

console.log(Cookies.get('profile'))

const initState = {
  level: Cookies.get('profile') !== undefined ? JSON.parse(Cookies.get('profile')) : {  user: {first_name: "", last_name: ""}, user_groups: [],
    is_superuser: false},
  name: '',
  email: '',
  overviewCardsValues: {
    myCustomers: null,
    myBrands: null,
    myUsers: null,
  },
  user_groups: [],
  is_superuser: false
};

/**
 *
 * @todo impure state mutation/explaination
 */
const userReducer = (state = initState, action) => {

  const { type, data, /* err */ } = action;

  switch (type) {
    case SET_PROFILE:
      return {
        ...state,
        level: data,
      };

      case SET_OVERVIEW_CARDS_VALUES:
        return {
          ...state,
          overviewCardsValues: data
      }; 

      case SET_OVERVIEW_CARDS_LOADING:
        return {
          ...state,
          overviewCardsLoading: data
      }; 

    default:
      return state;
  }
};
export default userReducer;