import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Switch, Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/users/actionCreator';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import TrendSonarConfigurationEdit from '../../modals/TrendSonarConfigurationEdit';
import TrendSonarConfiguration from '../../modals/TrendSonarConfiguration';

import { postDeleteSolution, searchSolutions, updateVisibility } from '../../../redux/solutions/actionCreator';
import FeatherIcon from 'feather-icons-react';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const getTag = status => {
  // console.log(status);

  let response = [];

  switch (status) {
    case 0:
      response.push(
        <div key={'0'} className="dtx-c-tag back-b-grey3">
          <p className="span3">Draft</p>
        </div>,
      );
      break;
    case 1:
      response.push(
        <div key={'1'} className="dtx-c-tag back-b-grey3">
          <p className="span3">Need Approval</p>
        </div>,
      );
      break;
    case 2:
      response.push(
        <div key={'2'} className="dtx-c-tag back-b-grey3">
          <p className="span3">Under Activation</p>
        </div>,
      );
      break;
    case 3:
      response.push(
        <div key={'3'} className="dtx-c-tag back-b-grey3">
          <p className="span3">Active</p>
        </div>,
      );
      break;
    default:
      <div></div>;
  }

  // console.log(response);

  return response;
};

const AllSolutionsClientUser = ({}) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const [filters, setFilters] = useState({
    filter_service_name: '',
    order_service_name: '',
    order_number_users: '',
    order_service_created_at: '',
  });

  const [nSolution, setNSolution] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchSolutions('', 3, currentPage, filters, (response, total) => {
        // let newData = [];

        // response.map(row => {
        //   if (row.is_visible) newData.push(row);
        // });

        // setData(newData);
        setData(response);
        setNSolution(total);
        setLoading(false);
      }),
    );
  }, [currentPage]);

  const columns = [
    {
      title: 'Solution Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        if (record.is_visible) {
          return (
            <Link
              to={{
                pathname: '/solutions/detail',
                search: `?id=${record.id}`,
                state: {
                  location: {
                    link: `/solutions`,
                    title: `Solutions`,
                  },
                },
              }}
              className="m-secondary"
            >
              {value}
            </Link>
          );
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: 'Category',
      dataIndex: 'service_type',
      key: 'service_type',
      render: (value, record) => <div>{value.name}</div>,
    },
    {
      title: 'Client',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (value, record) => <div>{record.brand.customer !== undefined && record.brand.customer.name}</div>,
      sorter: {
        compare: (a, b) => a.customer - b.customer,
      },
    },
    {
      title: 'Brand',
      dataIndex: 'brand_name',
      key: 'brand_name',
      render: (value, record) => <div>{record.brand.name}</div>,
      sorter: {
        compare: (a, b) => a.customer - b.customer,
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: value => getTag(value),
    },
  ];

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    if (pagination.current !== currentPage) {
      // setStatusTable(true);
      setCurrentPage(pagination.current);
    }

    if (sorter !== undefined) {
      setFilters({
        ...filters,
        order_service_name: '',
        order_number_users: '',
        order_service_created_at: '',
      });

      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        if (order !== undefined) {
          setFilters({
            ...filters,
            [`order_service_${field}`]: order === 'ascend' ? 1 : 2,
          });
        } else {
          setFilters({
            ...filters,
            [`order_service_${field}`]: '',
          });
        }
      }
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    setFilters({
      ...filters,
      filter_service_name: changedValues[key],
    });
  };

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form
          name="basic"
          layout="inline"
          onValuesChange={onValuesChange}
          onFinish={() => {
            setLoading(true);
            dispatch(
              searchSolutions('', 3, currentPage, filters, (response, total) => {
                // let newData = [];

                // response.map(row => {
                //   if (row.is_visible) newData.push(row);
                // });

                // setData(newData);
                setData(response);
                setNSolution(total);
                setLoading(false);
              }),
            );
          }}
        >
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>
            <Form.Item className="dtx-c-field" name="name">
              <Input placeholder="Filter by Solution Name" className="dtx-c-field__input" />
            </Form.Item>

            <Form.Item className="">
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="dtx-c-table">
        <div className="dtx-c-table-header">
          <h6>All Solutions</h6>
        </div>

        <div className="dtx-c-table-content">
          <div>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ position: ['bottomCenter'], pageSize: 10, total: nSolution }}
              loading={loading}
              onChange={handleTableChange}
              rowKey={record => record.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSolutionsClientUser;
