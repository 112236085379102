import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { getCustomerList } from '../../../redux/customers/actionCreator';
import { getBrandList } from '../../../redux/brands/actionCreator';
import { postCreateUser, postUpdateUser } from '../../../redux/users/actionCreator';

const { Option } = Select;

const ModifyMasterUser = ({ visible, onClose, id, record }) => {
  const dispatch = useDispatch();

  const onFinish = values => {
    let newValues = {};

    newValues = {
      ...values,
      user_groups: ['client'],
      username: values['email'],
      user_id: record.id,
      seomaster: 'True',
      seouser: 'False',
      clientuser: 'False',
    };

    // dispatch(postCreateUser(newValues));
    dispatch(postUpdateUser(newValues, values => {
      onClose();
    }))
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Drawer
      title={'Edit SEO Master'}
      destroyOnClose={true}
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
      preserve={false}
      className="dtx-c-drawer"
    >
      <div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            first_name: record !== undefined && record.first_name,
            last_name: record !== undefined && record.last_name,
            email: record !== undefined && record.email,
            client: record !== undefined && record.client,
            brand_ids: record !== undefined && record.brand_ids,
          }}
          {...layout}
        >
          <div className="dtx-c-field-row">
            <div className="dtx-c-field">
              <p className="label2 m-grey">Name:</p>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="dtx-c-field__input" placeholder="e.g. Mario" />
              </Form.Item>
            </div>

            <div className="dtx-c-field">
              <p className="label2 m-grey">Surname:</p>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="dtx-c-field__input" placeholder="e.g. Rossi" />
              </Form.Item>
            </div>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey">Email:</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input className="dtx-c-field__input" placeholder="e.g. mario.rossi@gmail.com" />
            </Form.Item>
          </div>
          <Form.Item>
            <Button className='dtx-c-btn btn__large dtx-c-btn__solid' htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default ModifyMasterUser;
