import actions from './actions';

const {
  SEARCH_CUSTOMER,
  LOADING_CUSTOMER
} = actions;

const initState = {
  customer: [],
  loading: false,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const customerDetailReducer = (state = initState, action) => {

  const { type, data, /* err */ } = action;

  switch (type) {
    case SEARCH_CUSTOMER:
      return {
        ...state,
        customer: data
      };
      case LOADING_CUSTOMER:
        return {
          ...state,
          loading: data
        };
    default:
      return state;
  }
};
export default customerDetailReducer;