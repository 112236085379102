import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { changePassword } from '../../../redux/authentication/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { postEditProfile, postUpdateUser } from '../../../redux/users/actionCreator';

const CardEditProfile = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  // console.log(level)

  const onFinish = values => {
    // console.log(values)

    let newValues = {};

    newValues = {
      ...values,
      user_id: level.user.id,
      seomaster: level.user_groups[0] === "seomaster" ? 'true' : 'false' ,
      seouser: level.user_groups[0] === "seouser" ? 'true' : 'false',
      clientuser: level.user_groups[0] === "client" ? 'true' : 'false',
    };

    // console.log(newValues)

    dispatch(
      postEditProfile(newValues, response => {
        // console.log(response)
      }),
    );
  };

  return (
    <div className="dtx-c-homepage__single-card__table">
      <div className="single-card__tabs_table-header">
        <span>Edit Profile</span>
        <span>Set Up Your Personal Information</span>
      </div>
      <div className="edit-profile_content">
        <Form
          name="EditProfile"
          layout="inline"
          onFinish={onFinish}
          initialValues={{
            first_name: level.user.first_name,
            last_name: level.user.last_name,
            email: level.user.email,
          }}
        >
          <div className="pc100">
            <div className="pc50">
              <span>Name*</span>
              <Form.Item name="first_name">
                <Input placeholder="Enter new password" />
              </Form.Item>
            </div>
            <div className="pc50">
              <span>Surname*</span>
              <Form.Item name="last_name">
                <Input placeholder="Repeat new password" />
              </Form.Item>
            </div>
          </div>{' '}
          <div className="pc100">
            <div className="pc50">
              <span>Email*</span>
              <Form.Item name="email">
                <Input placeholder="Enter old password" />
              </Form.Item>
            </div>
          </div>
          <div className="pc100">
            <div className="pc50">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {loading ? <span className="Label">Loading...</span> : <span>Save Changes</span>}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CardEditProfile;
