import axios from './Api'
import Cookies from 'js-cookie';

function refreshToken() {
  if (localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== 'undefined') {
    axios
      .post(`/auth/jwt/refresh/`, { refresh: JSON.parse(localStorage.getItem('refreshToken')) })
      .then(response => {
        if (response.data.access && response.data.refresh) {
          localStorage.setItem('accessToken', JSON.stringify(response.data.access));
          localStorage.setItem('refreshToken', JSON.stringify(response.data.refresh));
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
          Cookies.set('logedIn', true);
          window.location.reload(true);
        } else {
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          Cookies.remove('logedIn');
          Cookies.remove('profile');
          window.location.reload(true);
        }
      })
      .catch(function (error) {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        Cookies.remove('logedIn');
        Cookies.remove('profile');
        window.location.reload(true);
      });
  } else {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    Cookies.remove('logedIn');
    Cookies.remove('profile');
  }
}

export const postUpdateService = (form, endpoint, callback, callbackError) => {

  axios
    .post(endpoint, form)
    .then(({ data }) => {
      if (data) {
        callback(data)
      } else {
        callback(data)
      }
    })
    .catch(function ({ response }) {
      if (response.status === 401) {
        refreshToken();
      } else {
        callbackError(response)

      }
    })
}

