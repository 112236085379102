import { DataService } from '../../config/dataService/dataService';
import actions from './actions';

const { searchCustomer, loading } = actions;

const postSearchCustomer = (id, callback) => {
  return async dispatch => {
    dispatch(loading(true));

    const search_customers = await DataService.post(`/customer/read_customer/`, {
      customer_ids: [id],
    });

    if (search_customers.status === 200) {
      dispatch(searchCustomer(search_customers.data.customers[0]));
      dispatch(loading(false));
      callback(search_customers.data)
    }
  };
};

const getSearchCustomer = (id, callback) => {
  return async dispatch => {

    const respose = await DataService.get(`/customer/read_customer/`);

    if (respose.status === 200) {
      callback(respose.data);
    }
  };
};

export { postSearchCustomer, getSearchCustomer };
