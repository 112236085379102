import { Empty, Row, Skeleton, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeywordLineChart from '../../../components/charts/chartCards/KeywordLineChart';
import LineChart from '../../../components/charts/chartCards/LineChart';
import { getRSSgoogleFeed } from '../../../redux/keywords/actionCreator';
import { format_number } from '../../../utility/utility';

import FeatherIcon from 'feather-icons-react';
import { Link, Redirect, useHistory } from 'react-router-dom';

const KeywordDetails = ({ location }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [keywordData, setKeywordData] = useState(location.state !== undefined && location.state.KeywordData);
  const [locationData] = useState(location.state !== undefined && location.state.location);

  const [service_id] = useState(location.state !== undefined && location.state.service_id);

  const [feed, setFeed] = useState([]);

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  useEffect(() => {
    let isMounted = true;

    if (location.state !== undefined) {
      dispatch(
        getRSSgoogleFeed({ service_id: service_id, keyword: keywordData.keyword }, response => {
          if (isMounted) {
            // console.log(response);

            let newFeed = [];

            if (response.news.length > 0) {
              for (let i = 0; i < 9; i++) {
                // console.log('Feed: ', response.news[i]);
                if (response.news[i] !== undefined) {
                  newFeed.push(
                    <a key={i} href={response.news[i].link} target="_blank">
                      <div className="test_news_card_container">
                        <span className="news-title">{response.news[i].title}</span>
                        <div className="news-info">
                          <div className="news-source">{response.news[i].source.title}</div>
                          <span>|</span>
                          <span className="news-published">{response.news[i].published}</span>
                        </div>
                      </div>
                    </a>,
                  );
                }
              }
            } else {
              newFeed = undefined;
            }

            setFeed(newFeed);
          }
        }),
      );
      return () => {
        isMounted = false;
      };
    }
  }, []);

  return (
    <div id="solution" className="dtx-l-main">
      {location.state !== undefined ? (
        <>
          <div className="dtx-l-header">
            <div className="dtx-c-header-info">
              <Row>
                <div
                  className="dtx-c-icon mb-1-5"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <FeatherIcon icon="arrow-left" className="m-secondary" />
                  <p className="label1 m-secondary">Back</p>
                </div>
              </Row>
              <Row>
                <h4>{keywordData.keyword}</h4>
              </Row>
              <Row>
                <p className="span1 m-lightgrey">
                  Data updated to:{' '}
                  {keywordData !== undefined ? (
                    moment(keywordData.updated_at).format('DD/MM/YYYY at HH:mm')
                  ) : (
                    <Skeleton active paragraph={{ rows: 0 }} />
                  )}
                </p>
                {/* <p className="span1 m-lightgrey mx-0-5">|</p>
            <p className="span1 m-lightgrey">
              Next update on:{' '}
              {keywordData !== undefined ? (
                moment(keywordData.updated_at).format('DD/MM/YYYY at HH:mm')
              ) : (
                <Skeleton active size={100} paragraph={{ rows: 0 }} />
              )} 
            </p>*/}
              </Row>
            </div>
          </div>

          <div className="dtx-c-plot__section">
            <div className="dtx-c-table">
              <div className="dtx-c-table-header">
                <h6>Keyword Insight</h6>
              </div>
              <div className="dtx-c-table-content">
                <div className="test__container-label-data">
                  <div className="test__label-data">
                    <span className="title">Category</span>
                    <div className="tag-row">
                      <span className="category_1">{keywordData.category_1}</span>
                      {keywordData.category_2 !== 'ND' && <span className="category_2">keywordData.category_2</span>}
                      {keywordData.category_3 !== 'ND' && <span className="category_3">keywordData.category_3</span>}
                    </div>
                  </div>
                  <div className="test__label-data">
                    <span className="title">Search Volume</span>
                    <div className="value-row">{format_number(keywordData.average_volume, 0)}</div>
                  </div>

                  {keywordData.type !== 3 ? (
                    <div className="test__label-data">
                      <span className="title">Growth</span>
                      <div className="value-row">
                        {parseInt(keywordData.growth_rate) > 0 ? (
                          <div className="dtx-c-table__value">
                            <p className="test__positive">+{format_number(keywordData.growth_rate, 2) + ' %'}</p>
                            <FeatherIcon icon="arrow-up" className="test__positive" />
                          </div>
                        ) : (
                          <div className="dtx-c-table__value">
                            <p className="test__negative">{format_number(keywordData.growth_rate, 2) + ' %'}</p>
                            <FeatherIcon icon="arrow-down" className="test__negative" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="test__label-data">
                      <span className="title">Growth</span>
                      <div className="value-row-new">
                        <div className="dtx-c-tag-new">
                          <p className="span3">NEW</p>
                        </div>
                        <Tooltip title="This is a new keyword that had not been seen previously">
                          <FeatherIcon icon="help-circle" color="#5b5a7c" size="18" className="ml-5px" />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>

                <div className="linechart">
                  <KeywordLineChart
                    response={[
                      {
                        data: keywordData.historical_volume.volume,
                        borderColor: '#3997e3',
                        backgroundColor: '#D7EAF9',
                        borderWidth: 1,
                        fill: true,
                        label: 'Search Volume',
                      },
                    ]}
                    labels={keywordData.historical_volume.date}
                    title={'Search Volume '}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="dtx-c-plot__section">
            <h5>Relevant News Related to this Keyword</h5>

            {feed !== undefined && feed.length > 0 ? (
              <div className="dtx-l-content">
                <div className="test__card-grid">{feed}</div>{' '}
              </div>
            ) : feed === undefined ? (
              <Empty />
            ) : (
              <Skeleton active />
            )}
          </div>
        </>
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
};

export default KeywordDetails;
