import React from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SuspendedSolutionConfirmModal = ({ isModalVisible, setIsModalVisible, form, func }) => {
  const dispatch = useDispatch();
  const history = useHistory()

  const handleOk = () => {
    dispatch(func(form));
    setIsModalVisible(false);
    // console.log(history.location)
    if (history.location.pathname === '/solution/detail'){
      history.goBack();
    }
  };

  const handleCancel = e => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Suspend Solution"
      visible={isModalVisible}
      okText="Suspend Solution"
      cancelText="Cancel"
      onOk={handleOk}
      onCancel={handleCancel}
      className='dtx-c-modal dtx-c-modal__delete'
    >
      <div className="dtx-c-modal-content">
        <div className="dtx-c-modal-text">
          <p className='span1'>Are you sure you want to suspend this solution?</p>
          <p className='span2 mt-1'>Warning: A suspended solution can not be re-activated without the intervention of an Admin! Please contact Marketing Science team!</p>
        </div>
      </div>
    </Modal>
  );
};

export default SuspendedSolutionConfirmModal;
