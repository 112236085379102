import React, { useEffect, useState } from 'react';
import { Upload, Button, Modal, Select, Steps, TimePicker, DatePicker, Form, message, Input, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  postCreationConfiguration,
  readKeywordMapping,
  readService,
  readUpdateFrequency,
  updateServiceCallback,
} from '../../redux/solutions/actionCreator';
import { getDaysInMonth } from 'date-fns';
import moment from 'moment';

const { Step } = Steps;

const { Option } = Select;

const format = 'HH:mm';

export default function TrendSonarConfiguration({ configurationModal, setConfigurationModal, id }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [listKeywordMapping, setListKeywordMapping] = useState([]);
  const [listUpdateFrequency, setListUpdateFrequency] = useState([]);
  const [updateFrequencyData, setUpdateFrequencyData] = useState({});
  const [listSearchVolumeVisualization, setListSearchVolumeVisualization] = useState([]);

  const [myFormValues, setMyFormValues] = useState({
    service_id: 1,
    keyword_mapping_id: null,
    select_update_frequency: null,
    select_time_interval_visualization: null,
  });

  useEffect(() => {
    if (configurationModal) {
      dispatch(
        readKeywordMapping(keywordsMapping => {
          let newListKeywordMapping = [];

          for (let key in keywordsMapping) {
            newListKeywordMapping.push(<Option value={key}>{keywordsMapping[key]}</Option>);
          }

          setListKeywordMapping(newListKeywordMapping);
        }),
      );

      dispatch(
        readUpdateFrequency(updateFrequency => {
          setUpdateFrequencyData(updateFrequency);

          let newListUpdateFrequency = [];

          for (let key in updateFrequency) {
            newListUpdateFrequency.push(<Option value={key}>{key}</Option>);
          }

          setListUpdateFrequency(newListUpdateFrequency);
        }),
      );
    }
  }, [configurationModal]);

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCancel = e => {
    setConfigurationModal(false);
  };

  const getDays = () => {
    let listDays = [];
    for (let i = 1; i <= 28; i++) {
      listDays.push(<Option value={i}>{i}</Option>);
    }

    return listDays;
  };

  const getContent = () => {
    switch (myFormValues.select_update_frequency) {
      case '3 times a day':
        return (
          <>
            <div className="dtx-c-section back-m-white">
              <div className="dtx-c-section-title">
                <p className="label1">Data Update</p>
                <p className="span2">Select when to run the update</p>
              </div>
              <div className="dtx-c-field-row">
                <div className="dtx-c-field">
                  <p className='label2'>Update Time</p>
                  <Form.Item
                    name="select_update_time_1"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
                <div className="dtx-c-field">
                  <p className='label2'>Second Update Time</p>
                  <Form.Item
                    name="select_update_time_2"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_2: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
                <div className="dtx-c-field">
                  <p className='label2'>Third Update Time</p>
                  <Form.Item
                    name="select_update_time_3"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_3: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case '2 times a day':
        return (
          <>
            <div className="dtx-c-section back-m-white">
              <div className="dtx-c-section-title">
                <p className="label1">Data Update</p>
                <p className="span2">Select when to run the update</p>
              </div>
              <div className="dtx-c-field-row">
                <div className="dtx-c-field">
                  <p className='label2'>Update Time</p>{' '}
                  <Form.Item
                    name="select_update_time_1"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
                <div className="dtx-c-field">
                  <p className='label2'>Second Update Time</p>{' '}
                  <Form.Item
                    name="select_update_time_2"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_2: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case '1 time a day':
        return (
          <>
            <div className="dtx-c-section back-m-white">
              <div className="dtx-c-section-title">
                <p className="label1">Data Update</p>
                <p className="span2">Select when to run the update</p>
              </div>
              <div className="dtx-c-field">
                <p className='label2'>Update Time</p>
                <Form.Item
                  name="select_update_time_1"
                  getValueProps={value => {
                    form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TimePicker placeholder="Select time" className='dtx-c-field__picker' minuteStep={15} format={format} />
                </Form.Item>
              </div>
              <div className="Filed-Info"></div>
            </div>
          </>
        );
        break;
      case '1 time a week':
        return (
          <>
            <div className="dtx-c-section back-m-white">
              <div className="dtx-c-section-title">
                <p className="label1">Data Update</p>
                <p className="span2">Select when to run the update</p>
              </div>
              <div className="dtx-c-field-row">
                <div className="dtx-c-field">
                  <p className='label2'>Day of the week</p>
                  <Form.Item
                    name="select_update_day_of_week"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select day"
                      className='dtx-c-field__select'
                      onChange={value => {
                        getValueForm(value, 'select_update_day_of_week');
                      }}
                    >
                      <Option value={1}>Monday</Option>
                      <Option value={2}>Tuesday</Option>
                      <Option value={3}>Wednesday</Option>
                      <Option value={4}>Thursday</Option>
                      <Option value={5}>Friday</Option>
                      <Option value={6}>Saturday</Option>
                      <Option value={7}>Sunday</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="dtx-c-field">
                  <p className='label2'>Time</p>
                  <Form.Item
                    name="select_update_time_1"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case '1 time a month':
        return (
          <>
            <div className="dtx-c-section back-m-white">
              <div className="dtx-c-section-title">
                <p className="label1">Data Update</p>
                <p className="span2">Select when to run the update</p>
              </div>
              <div className="dtx-c-field-row">
                <div className="dtx-c-field">
                  <p className='label1'>Day of month</p>
                  <Form.Item
                    name="select_update_day_of_month"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select day of the month"
                      className='dtx-c-field__select'
                      onChange={value => {
                        getValueForm(value, 'select_update_day_of_month');
                      }}
                    >
                      {getDays()}
                    </Select>
                  </Form.Item>
                </div>
                <div className="dtx-c-field">
                  <p className='label2'>Update Time</p>
                  <Form.Item
                    name="select_update_time_1"
                    getValueProps={value => {
                      form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('hh:mm') });
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case 'once every 2 weeks':
        return (
          <>
            <div className="dtx-c-section back-m-white">
              <div className="dtx-c-section-title">
                <p className="label1">Data Update</p>
                <p className="span2">Select when to run the update</p>
              </div>
              <div className="dtx-c-field-row">
                <div className="dtx-c-field">
                  <p className='label2'>Day of the month</p>
                  <DatePicker picker="week" className='dtx-c-field__picker' />
                </div>
                <div className="dtx-c-field">
                  <p className='label2'>Update Time</p>
                  <TimePicker placeholder="Select time" className='dtx-c-field__picker' format={format} />
                </div>
              </div>
            </div>
          </>
        );
        break;
      default:
    }
  };

  const steps = [
    {
      title: 'Configuration',
      content: (
        <>
          <div className="dtx-c-section back-m-white">
            <div className='dtx-c-section-title'>
              <p className="label1">Data Source</p>
              <p className="span2">Select a keyword mapping in Intent Sonar</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Keyword Mapping</p>
                <Form.Item
                  name="keyword_mapping_id"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Keyword Mapping"
                    className='dtx-c-field__select'
                    disabled={listKeywordMapping.length > 0 ? false : true}
                    onChange={value => {
                      getValueForm(value, 'keyword_mapping_id');
                    }}
                  >
                    {listKeywordMapping}
                    {/* <Option value={0}>Keyword Mapping L’Oréal</Option> */}
                  </Select>
                </Form.Item>
                <p className="span3 m-secondary">Can't find your file? Open a ticket</p>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Negative Keyword</p>
                <Form.Item name="negative_keywords_file" className='dtx-c-field__input'>
                  <input
                    type="file"
                    accept="text/csv"
                    onChange={e => {
                      getValueForm(e.target.files[0], 'negative_keywords_file');
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="dtx-c-section back-m-white">
            <div className='dtx-c-section-title'>
              <p className="label1">Data Visualization</p>
              <p className="span2">Select the update frequency and the search volume visualization</p>
            </div>
            <div className="dtx-c-field-row">
              <div className="dtx-c-field">
                <p className="label2">Update Frequency</p>
                <Form.Item
                  name="select_update_frequency"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Update Frequency"
                    className='dtx-c-field__select'
                    disabled={listUpdateFrequency.length > 0 ? false : true}
                    onChange={value => {
                      let newForm = {
                        ...form,
                        select_time_interval_visualization: null,
                      };

                      form.setFieldsValue(newForm);

                      getValueForm(value, 'select_update_frequency');
                      let newListSearchVolumeVisualization = [];
                      newListSearchVolumeVisualization.push(
                        updateFrequencyData[value].map(time => <Option value={time}>{time}</Option>),
                      );

                      setListSearchVolumeVisualization(newListSearchVolumeVisualization);
                    }}
                  >
                    {listUpdateFrequency}
                  </Select>
                </Form.Item>
              </div>
              <div className="dtx-c-field">
                <p className="label2">Search Volume Visualization</p>
                <Form.Item
                  name="select_time_interval_visualization"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Update Frequency"
                    className='dtx-c-field__select'
                    disabled={listSearchVolumeVisualization.length > 0 ? false : true}
                    onChange={value => {
                      getValueForm(value, 'select_time_interval_visualization');
                    }}
                  >
                    {listSearchVolumeVisualization}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          {/* </Form> */}
        </>
      ),
    },
    {
      title: 'Submit',
      content: getContent(),
    },
  ];

  const getValueForm = (value, key) => {
    let newForms = {};
    newForms = {
      ...myFormValues,
      [key]: value,
    };
    setMyFormValues(newForms);
  };

  const onConfigurationClose = values => {
    const fields = form.getFieldsValue();

    let newForm = {
      ...fields,
    };

    form.setFieldsValue(newForm);

    setConfigurationModal(false);
  };

  return (
    <Modal
      visible={configurationModal}
      // visible={true}
      title="Trend Sonar Configuration"
      cancelText="Go Back"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose={true}
      className="dtx-c-modal__composed"
      onClose={onConfigurationClose}
    >
      <div className="modal-Body-configuration">
        <Form
          name="basic"
          form={form}
          onFinish={values => {
            let newForms = {
              ...values,
              ...myFormValues,
              service_id: id,
            };

            // console.log(newForms);

            dispatch(
              postCreationConfiguration(newForms, {}, response => {
                // console.log(response);
                
                dispatch(
                  updateServiceCallback({ service_id: id, status: 1 }, responseUpdate => {
                    // console.log(responseUpdate)
                    setConfigurationModal(false);
                    message.success('configuration created');
                  }),
                );
              }),
            );
          }}
          onFinishFailed={values => {
            // console.log(values);
          }}
        >
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="dtx-c-modal-content">{steps[current].content}</div>
          <div className="dtx-c-modal-footer">
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                className='dtx-c-btn dtx-c-btn__solid btn__small'
                onClick={() => {
                  // console.log(form.getFieldValue());
                  // console.log(myFormValues);

                  if (Object.values(myFormValues).every(x => x !== null)) {
                    next();
                  }
                }}
                disabled={Object.values(myFormValues).every(x => x !== null) ? false : true}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button htmlType="submit" className="dtx-c-btn dtx-c-btn__solid btn__small">
                Submit request
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
}
