import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'antd';
import CardItems from '../../../components/homepage/CardItems';
import LastBrands from '../../../components/tables/brands/LastBrands';
import IndexModals from '../../../components/modals/indexModals';
import IndexModalsMaster from '../../../components/modals/indexModalsMaster';
import Drawers from '../../../components/drawer';
import { postOverviewCards } from '../../../redux/brands/actionCreator';
import SuccessModals from '../../../components/modals/SuccessModals';
import FeatherIcon from 'feather-icons-react';
import AllSolutionsClientUser from '../../../components/tables/solutions/AllSolutionsClientUser';

const Homepage = () => {
  const dispatch = useDispatch();

  const [overviewCardsLoading, setOverviewCards] = useState(null);

  /**
   *  Redux
   */
  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  // console.log(level)

  /**
   * Set which cards view in top the page
   */
  const [showModalsCard] = useState(
  level !== undefined && level !== null && level.user.is_superuser || level.user_groups[0] === 'seomaster'
      ? ['newClient','newBrand', 'newUser', ]
      : ['newBrand', 'newUser'],
  );

  /**
   * Drawers
   */
  const [visible, setVisible] = useState(false);
  const [drawerName, setDrawerName] = useState();

  /**
   * Function for set which drawer to view
   * @param {*} name
   */
  const showDrawer = name => {
    if ((level.user.is_superuser || level.user_groups[0] === 'seomaster') && name === 'newUser') {
      showModalSpecialist();
    } else if (name === 'newUser') {
      setDrawerName('newClientUser');
      setVisible(true);
    } else {
      setDrawerName(name);
      setVisible(true);
    }
  };

  /**
   * Function for close the drawer
   */
  const onClose = () => {
    // console.log('Close');
    setVisible(false);
  };

  /**
   * Modal:
   *  - Index Modals
   */
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Modal:
   *  - Specialist Modals
   */
  const [isModalVisibleSpecialist, setIsModalVisibleSpecialist] = useState(false);

  const showModalSpecialist = () => {
    setIsModalVisibleSpecialist(true);
  };

  /**
   * Modal:
   *  - Success Modals
   */
  const [successShow, setSuccessShow] = useState(false);
  const [successItem, setSuccessItem] = useState();

  const showSuccessModal = () => {
    setSuccessShow(true);
  };

  const [idSuccess, setIdSuccess] = useState(null);

  /**
   * This useEffect is for to fill the cards overview
   */
  useEffect(() => {
    dispatch(
      postOverviewCards(response => {
        /**
         * SET VALUES CARDS
         */
        setOverviewCards({
          myBrands: response.number_of_brands,
          myCustomers: response.number_of_customers,
          myUsers: response.number_of_users,
        });
      }),
    );
  }, []);

  return (
    <div className="dtx-l-main">
      <div className="dtx-l-header">
        <h4>{`Hi ${level.user.first_name} ${level.user.last_name}`}</h4>
        {level.user_groups[0] !== 'client' && (
          <Button className="dtx-c-btn btn__small dtx-c-btn__solid" onClick={() => showModal()}>
            <FeatherIcon icon={'plus'} />
            Add New
          </Button>
        )}
      </div>

      {level.user.is_superuser || level.user_groups[0] !== 'client' ? (
        <Row className="dtx-c-card-row mb-3-5">
          <CardItems
            type={'clients'}
            values={overviewCardsLoading !== null && overviewCardsLoading.myCustomers}
            loading={overviewCardsLoading !== null ? false : true}
          />
          <CardItems
            type={'brands'}
            values={overviewCardsLoading !== null && overviewCardsLoading.myBrands}
            loading={overviewCardsLoading !== null ? false : true}
          />
          <CardItems
            type={'users'}
            values={overviewCardsLoading !== null && overviewCardsLoading.myUsers}
            loading={overviewCardsLoading !== null ? false : true}
          />
        </Row>
      ) : (
        <Row className="dtx-c-card-row mb-3-5">
          <CardItems
            type={'solutions'}
            values={overviewCardsLoading !== null && overviewCardsLoading.myCustomers}
            loading={overviewCardsLoading !== null ? false : true}
          />
        </Row>
      )}

      {level.user.is_superuser || level.user_groups[0] !== 'client' ? (
        <div className="dtx-c-table">
          <div className="dtx-c-table-header">
            <h6>Last Brands</h6>
          </div>
          <div className="dtx-c-table-content">
            <LastBrands id={level.user.id} />
          </div>
        </div>
      ) : (
        <AllSolutionsClientUser />
      )}

      {/******* MODALS *******/}

      <IndexModals
        title="Add New Element"
        containTitle="Select which element you want to add"
        listShowModals={showModalsCard}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showDrawer={showDrawer}
      />

      <IndexModalsMaster
        listShowModals={['newClientUser', 'newSpecialistUser', 'newMasterUser']}
        isModalVisibleSpecialist={isModalVisibleSpecialist}
        setIsModalVisibleSpecialist={setIsModalVisibleSpecialist}
        showModalSpecialist={showModalSpecialist}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <SuccessModals
        successShow={successShow}
        successItem={successItem}
        setSuccessShow={setSuccessShow}
        idSuccess={idSuccess}
      />

      {/******* DRAWERS *******/}

      <Drawers
        drawerName={drawerName}
        visible={visible}
        onClose={onClose}
        id={level.user.id}
        setSuccessShow={setSuccessShow}
        setIdSuccess={setIdSuccess}
        setSuccessItem={setSuccessItem}
      />
    </div>
  );
};

export default Homepage;
