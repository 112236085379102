import React, { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCard } from '../../../redux/users/actionCreator';
import CardItems from '../../../components/homepage/CardItems';
import IndexModals from '../../../components/modals/indexModals';
import Drawers from '../../../components/drawer';
import FeatherIcon from 'feather-icons-react';
import UsersTable from "../../../components/tables/users/UsersTable";

const Users = () => {
  const dispatch = useDispatch();

  /**
   * Redux Variable
   */
  const { level, userCard, loadingUserCard } = useSelector(state => {
    return {
      level: state.user.level,
      userCard: state.usersReducer.userCard,
      loadingUserCard: state.usersReducer.loadingUserCard,
    };
  });

  const [showModalsCard] = useState(
    level.user.is_superuser || level.user_groups[0] === 'seomaster'
      ? ['newClientUser', 'newSpecialistUser', 'newMasterUser']
      : ['newClientUser'],
  );

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Modal
   */
  const [isModalVisibleSpecialist, setIsModalVisibleSpecialist] = useState(false);

  const showModalSpecialist = () => {
    setIsModalVisibleSpecialist(true);
  };

  const [visible, setVisible] = useState(false);
  const [drawerName, setDrawerName] = useState();

  const showDrawer = name => {
    if ((level.user.is_superuser || level.user_groups[0] === 'seomaster') && name === 'newUser') {
      showModalSpecialist();
    } else if (name === 'newUser') {
      setDrawerName('newClientUser');
      setVisible(true);
    } else {
      setDrawerName(name);
      setVisible(true);
    }
  };

  const onClose = () => {
    // console.log('Close');
    setVisible(false);
  };

  useEffect(() => {
      dispatch(getUserCard());
  }, [dispatch]);

  return (
    <div className="dtx-l-main">
      <div className="dtx-l-header">
        <h4>Users</h4>

        <div className="button-row">
          <Button className="dtx-c-btn btn__small dtx-c-btn__secondary" disabled={true}>
            <FeatherIcon icon={'download'} className="m-primary" />
            <span>Export Users</span>
          </Button>
          <Button className="dtx-c-btn btn__small dtx-c-btn__solid" onClick={showModal}>
            <FeatherIcon icon={'plus'} />
            Add New
          </Button>
        </div>
      </div>

      <Row className="dtx-c-card-row mb-3-5">
        <CardItems type={'users'} values={userCard} loading={loadingUserCard} />
      </Row>

      <UsersTable />

      {/**
       * Kit Show Modals:
       *  - IndexModals: show the first level of modal
       *  - IndexModalsMaster: show the second level fo the form
       *    - Only for the Admin and SeoMaster
       */}
      <IndexModals
        title="Add New User"
        containTitle="Select what kind of user  you want to add"
        listShowModals={showModalsCard}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <Drawers drawerName={drawerName} visible={visible} onClose={onClose} />
    </div>
  );
};

export default Users;
