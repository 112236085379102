import React, { useState } from 'react';
import { Modal } from 'antd';
import FeatherIcon from 'feather-icons-react';

// title="Add New Element"
// containTitle="Select which element you want to add"

export default function indexModals({
  title,
  containTitle,
  listShowModals,
  isModalVisible,
  setIsModalVisible,
  showDrawer,
}) {
  // console.log('Modal View: ', isModalVisible, 'List of Modals: ', listShowModals);

  let drawer = <div />;

  let showModals = [];

  const [drawerSelected, setDrawerSelected] = useState();

  listShowModals.map(isModal => {
    if (isModalVisible) {
      switch (isModal) {
        case 'newBrand':
          showModals.push(
            <div
              key="newBrand"
              className={drawerSelected === 'newBrand' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newBrand');
              }}
            >
              <FeatherIcon icon={'award'} className="m-secondary" />
              <p className="span3 m-grey">New Brand</p>
            </div>,
          );
          break;
        case 'newUser':
          showModals.push(
            <div
              key="newUser"
              className={drawerSelected === 'newUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newUser');
              }}
            >
              <FeatherIcon icon={'user'} className="m-secondary" />
              <p className="span3 m-grey">New User</p>
            </div>,
          );
          break;
        case 'newClient':
          showModals.push(
            <div
              key="newClient"
              className={drawerSelected === 'newClient' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newClient');
              }}
            >
              <FeatherIcon icon={'briefcase'} className="m-secondary" />
              <p className="span3 m-grey">New Customer</p>
            </div>,
          );
          break;
        case 'newClientUser':
          showModals.push(
            <div
              key="newClientUser"
              className={drawerSelected === 'newClientUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newClientUser');
              }}
            >
              <FeatherIcon icon={'briefcase'} className="m-secondary" />
              <p className="span3 m-grey">Customer User</p>
            </div>,
          );
          break;
        case 'newSpecialistUser':
          showModals.push(
            <div
              key="newSpecialistUser"
              className={drawerSelected === 'newSpecialistUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newSpecialistUser');
              }}
            >
              <FeatherIcon icon={'user'} className="m-secondary" />
              <p className="span3 m-grey">SEO User</p>
            </div>,
          );
          break;
        case 'newMasterUser':
          showModals.push(
            <div
              key="newMasterUser"
              className={drawerSelected === 'newMasterUser' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newMasterUser');
              }}
            >
              <FeatherIcon icon={'user-check'} className="m-secondary" />
              <p className="span3 m-grey">SEO Master</p>
            </div>,
          );
          break;
        case 'newExisting':
          showModals.push(
            <div
              key="newExisting"
              className={drawerSelected === 'newExisting' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newExisting');
              }}
            >
              <FeatherIcon icon={'user'} className="m-secondary" />
              <p className="span3 m-grey">Existing User</p>
            </div>,
          );
          break;
        case 'newSolution':
          showModals.push(
            <div
              key="newSolution"
              className={drawerSelected === 'newSolution' ? 'dtx-c-card-icon dtx-u-selected' : 'dtx-c-card-icon'}
              onClick={() => {
                setDrawerSelected('newSolution');
              }}
            >
              <FeatherIcon icon={'bar-chart-2'} className="m-secondary" />
              <p className="span3 m-grey">New Solution</p>
            </div>,
          );
          break;
        default:
          drawer = <div></div>;
      }
    }
  });

  const handleOk = () => {
    showDrawer(drawerSelected);
    setIsModalVisible(false);
  };

  const handleCancel = e => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      okText="Add Element"
      cancelText="Go Back"
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      className="dtx-c-modal dtx-c-modal__add"
    >
      <div className="dtx-c-modal-content">
        <div className="dtx-c-modal-text">
          <p className="span1">{containTitle}</p>
        </div>
        <div className="dtx-c-card-row">{showModals}</div>
      </div>
    </Modal>
  );
}
