import actions from './actions';

const { SEARCH_BRANDS, LOADING_BRANDS, SET_N_BRANDS, SET_FILTERS, SET_BRANDS_DETAIL } = actions;

const initState = {
  brands: [],
  brandsDetail: {},
  loading: false,
  nBrands: null,
  filters: {
    filter_brand_name: '',
    order_brand_name: '',
    order_number_users: '',
    order_number_projects: '',
    order_brand_created_at: '',
  },
};

/**
 *
 * @todo impure state mutation/explaination
 */
const brandsReducer = (state = initState, action) => {
  const { type, data /* err */ } = action;

  // console.log(type)
  // console.log(data)

  switch (type) {
    case SEARCH_BRANDS:
      return {
        ...state,
        brands: data,
      };
    case LOADING_BRANDS:
      return {
        ...state,
        loading: data,
      };
    case SET_N_BRANDS:
      return {
        ...state,
        nBrands: data,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: data,
      };

    case SET_BRANDS_DETAIL:
      return {
        ...state,
        brandsDetail: data,
      };
    default:
      return state;
  }
};
export default brandsReducer;
