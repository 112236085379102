const actions = {
  SEARCH_CUSTOMER : 'SEARCH_CUSTOMER',
  LOADING_CUSTOMER : 'LOADING_CUSTOMER',

  searchCustomer: (data) => {
    return {
      type: actions.SEARCH_CUSTOMER,
      data
    };
  },

  loading: (data) => {
    return {
      type: actions.LOADING_CUSTOMER,
      data
    };
  },
};

export default actions;
