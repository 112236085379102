const actions = {
  CHANGE_FILTERS: 'CHANGE_FILTERS',

  changeFilters: (data) => {
    return {
      type: actions.CHANGE_FILTERS,
      data
    };
  },

};

export default actions;
