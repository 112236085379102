import React from 'react';

import NewClient from './type/NewClient';
import NewBrand from './type/NewBrand';
import NewClientUser from './type/NewClientUser';
import NewSpecialistUser from './type/NewSpecialistUser';
import ModifyBrand from './type/ModifyBrand';
import ModifyNewClient from './type/ModifyNewClient';
import ModifyClientUser from './type/ModifyClientUser';
import ModifySeoUser from './type/ModifySeoUser';
import ModifyMasterUser from './type/ModifyMasterUser';
import NewExisting from './type/NewExisting';
import NewSolution from './type/NewSolution';
import NewSolutionSpecialist from './type/NewSolutionSpecialist';
import NewMasterUser from './type/NewMasterUser';

export default function Drawers({
  drawerName,
  visible,
  onClose,
  id,
  record,
  setSuccessShow,
  setIdSuccess,
  setSuccessItem,
  idCustomer,
  idBrand,
}) {
  // console.log('DRAWER: ', drawerName);

  let drawer = <div />;

  if (visible) {
    switch (drawerName) {
      case 'newClientUser':
        drawer = <NewClientUser visible={visible} onClose={onClose} idCustomer={idCustomer} />;
        break;
      case 'newSpecialistUser':
        drawer = <NewSpecialistUser visible={visible} onClose={onClose} id={id} idCustomer={idCustomer} />;
        break;
      case 'newMasterUser':
        drawer = <NewMasterUser visible={visible} onClose={onClose} id={id} idCustomer={idCustomer} />;
        break;
      case 'newClient':
        drawer = <NewClient visible={visible} onClose={onClose} id={id} idCustomer={idCustomer} />;
        break;
      case 'newBrand':
        drawer = (
          <NewBrand
            visible={visible}
            onClose={onClose}
            id={id}
            setSuccessShow={setSuccessShow}
            setIdSuccess={setIdSuccess}
            setSuccessItem={setSuccessItem}
            idCustomer={idCustomer}
          />
        );
        break;
      case 'updateBrand':
        drawer = <ModifyBrand visible={visible} onClose={onClose} id={id} record={record} idCustomer={idCustomer} />;
        break;
      case 'updateCustomer':
        drawer = <ModifyNewClient visible={visible} onClose={onClose} id={id} record={record} />;
        break;
      case 'updateClientUser':
        drawer = (
          <ModifyClientUser visible={visible} onClose={onClose} id={id} record={record} idCustomer={idCustomer} />
        );
        break;
      case 'updateSeoUser':
        drawer = <ModifySeoUser visible={visible} onClose={onClose} id={id} record={record} />;
        break;
      case 'updateMasterUser':
        drawer = <ModifyMasterUser visible={visible} onClose={onClose} id={id} record={record} />;
        break;
      case 'newSolution':
        drawer = (
          <NewSolution
            visible={visible}
            onClose={onClose}
            id={id}
            record={record}
            setSuccessShow={setSuccessShow}
            setIdSuccess={setIdSuccess}
            setSuccessItem={setSuccessItem}
          />
        );
        break;
      case 'newSolutionSpecialist':
        drawer = (
          <NewSolutionSpecialist
            visible={visible}
            onClose={onClose}
            id={id}
            record={record}
            setSuccessShow={setSuccessShow}
            setIdSuccess={setIdSuccess}
            setSuccessItem={setSuccessItem}
          />
        );
        break;
      case 'newExisting':
        drawer = (
          <NewExisting
            visible={visible}
            onClose={onClose}
            id={id}
            idCustomer={idCustomer}
            idBrand={idBrand}
            record={record}
          />
        );
        break;
      default:
        drawer = <div></div>;
    }
  }
  return drawer;
}
