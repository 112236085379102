import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../layout/withAdminLayout';
import Homepage from './pages/homepage/Homepage';
import BrandDetailpage from './pages/brands/BrandDetailpage';
import Profilepage from '../container/Profilepage';
import SolutionsPage from './pages/solutions/SolutionsPage';
import SolutionDetailPage from './pages/solutions/SolutionDetailPage';
import KeywordDetails from './pages/keywords/KeywordDetails';
import Customer from './pages/customers/Customer';
import CustomerDetails from './pages/customers/CustomerDetails';
import Users from './pages/users/Users';
import { useSelector } from 'react-redux';
import NotFound from './pages/NotFoundPage';
import ScrollToTop from '../utility/ScrollToTop';

const Admin = () => {
  const { path } = useRouteMatch();

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  let pathname = window.location.pathname.split('/')[1];

  return (
    <Switch>
      
      {pathname === 'newpassword' && <Redirect to="/" />}
      {pathname === 'forgotPassword' && <Redirect to="/" />}
      {pathname === 'firstpasswordset' && <Redirect to="/" />}


      {level !== undefined && level !== null && level.user_groups[0] === 'client' ? (
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <ScrollToTop />
          <Route exact path={path} component={Homepage} />
          <Route exact path={'/profile'} component={Profilepage} />
          <Route exact path={'/solutions/detail'} component={SolutionDetailPage} />
          <Route exact path={'/keyword'} component={KeywordDetails} />

          <Route exact path={'/solutions'} component={NotFound} />
          <Route exact path={'/clients'} component={NotFound} />
          <Route exact path={'/users'} component={NotFound} />
          <Route exact path={'/clients/detail'} component={NotFound} />
          <Route exact path={'/brands/detail'} component={NotFound} />
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route exact path={path} component={Homepage} />
          <Route exact path={'/profile'} component={Profilepage} />
          <Route exact path={'/solutions/detail'} component={SolutionDetailPage} />
          <Route exact path={'/keyword'} component={KeywordDetails} />
          <Route exact path={'/solutions'} component={SolutionsPage} />
          <Route exact path={'/clients'} component={Customer} />
          <Route exact path={'/users'} component={Users} />
          <Route exact path={'/clients/detail'} component={CustomerDetails} />
          <Route exact path={'/brands/detail'} component={BrandDetailpage} />
        </Suspense>
      )}
    </Switch>
  );
};

export default withAdminLayout(Admin);
