import Cookies from 'js-cookie';
import actions from './actions';

const {
  CHANGE_FILTERS,
} = actions;


const initState = {
  filters: {
    "name": ""
  }
};

/**
 *
 * @todo impure state mutation/explaination
 */
const solutionReducer = (state = initState, action) => {

  const { type, data, /* err */ } = action;

  switch (type) {
    case CHANGE_FILTERS:
      return {
        ...state,
        level: data,
      };

    default:
      return state;
  }
};
export default solutionReducer;