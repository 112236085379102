import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { changePassword } from '../../../redux/authentication/actionCreator';
import { useDispatch } from 'react-redux';

const CardNewPassword = () => {

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);

  const onValuesChangePassword = (changedValues, allValues) => {
    // console.log(allValues);
  };

  const onFinish = (values) => {

    console.log('error');

    if (values.current_password.length > 0 && values.new_password === values.re_new_password) {
      setLoading(true)
      dispatch(changePassword(values, (response)=>{
        if (response === 204){
          setLoading(false)
          message.success('Password Changed')
        } else {
          setLoading(false)
          message.error('Password Changed error')
        }
      }))
    } else {
      message.error('error');
    }
  };

  return (
    <div className="dtx-c-homepage__single-card__table">
      <div className="single-card__tabs_table-header">
        <span>Change Password</span>
      </div>
      <div className="edit-profile_content">
        <Form
          name="ChangePassword"
          layout="inline"
          onValuesChange={onValuesChangePassword}
          onFinish={onFinish}
          initialValues={{
            new_password: '',
            re_new_password: '',
            current_password: '',
          }}
        >
          <div className="pc100">
            <div className="pc50">
              <span>Old Password*</span>
              <Form.Item name="current_password">
                <Input placeholder="Enter old password" />
              </Form.Item>
            </div>
          </div>
          <div className="pc100">
            <div className="pc50">
              <span>New Password*</span>
              <Form.Item name="new_password">
                <Input.Password placeholder="Enter new password" />
              </Form.Item>
            </div>
            <div className="pc50">
              <span>Repeat New Password*</span>
              <Form.Item name="re_new_password">
                <Input.Password placeholder="Repeat new password" />
              </Form.Item>
            </div>
          </div>
          <div className="pc100">
            <div className="pc50">
              <Form.Item>
                <Button type="primary" htmlType="submit">            
                  {loading ? <span className="Label">Loading...</span> : <span>Save Changes</span>}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CardNewPassword;
