import { Row, Skeleton, Tooltip } from 'antd';
import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import FeatherIcon from 'feather-icons-react';

const chartLinearGradient = (canvas, height, color) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, height);
  gradient.addColorStop(0, `${color.start}`);
  gradient.addColorStop(1, `${color.end}`);
  return gradient;
};

const ChartCards = ({ response, title, labels, tootlipText }) => {
  // console.log("ChartCards: ", response);

  let colours = response && response.map(value => (value < 0 ? '#ff92b7' : '#93c7f2'));
  let bColours = response && response.map(value => (value < 0 ? '#ff606d' : '#3997e3'));

  let { datasets, options, height } = {
    options: {
      responsive: true,

      legend: {
        display: false,
      },
      labels: {
        display: false,
      },
      title: {
        display: false,
        text: 'High Volume Constant Signal',
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: '#e5e9f2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 10,
              fontColor: '#182b49',
            },
          },
        ],
      },
    },

    datasets: [
      {
        borderWidth: 2,
        data: response,
        borderColor: bColours,
        backgroundColor: colours,
        barPercentage: 0.6,
      },
    ],
  };
  const data = {
    datasets,
    labels,
  };

  return (
    <div>
      {response !== false ? (
        <div className="dtx-c-plot-content">
          <div className="dtx-c-plot-header-tooltip">
            <h6>{title}</h6>
            <Tooltip title={tootlipText}>
              <FeatherIcon icon="help-circle" color="#5b5a7c" size="18" className="ml-5px" />
            </Tooltip>
          </div>
          <div className="dtx-c-plot-body">
            <div className="dtx-c-plot-chart">
              <HorizontalBar data={data} height={220} options={options} />
            </div>
            <div className="dtx-c-plot-legend-row">
              <Row className="dtx-c-plot-legend">
                <div className="Ellipse-4"></div>
                <p className="span4">Decreasing Search Volume</p>
              </Row>
              <Row className="dtx-c-plot-legend">
                <div className="Ellipse-5"></div>
                <p className="span4">Increasing Search Volume</p>
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <div className="dtx-c-plot-content">
          <div className="dtx-c-plot-header">
            <h6>{title}</h6>
            <FeatherIcon icon="help-circle" color="#5b5a7c" size="24" className="ml-5px" />
          </div>
          <div className="dtx-c-plot-body">
            <Skeleton active />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartCards;
