import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SolutionTable from '../../tables/solutions/SolutionTable';
import { searchSolutions } from '../../../redux/solutions/actionCreator';
import FeatherIcon from 'feather-icons-react';

const queryString = require('query-string');

const { TabPane } = Tabs;

const SolutionsTabs = ({ id, brandName }) => {
  const dispatch = useDispatch();

  let parsed = queryString.parse(window.location.search);

  const [currentTab, setCurrentTab] = useState(3);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nSolution, setNSolution] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [listRowID, setListRowID] = useState([]);

  const [filters, setFilters] = useState({
    filter_service_name: '',
    order_service_name: '',
    order_number_users: '',
    order_service_created_at: '',
  });

  const { updating } = useSelector(state => {
    return {
      updating: state.ChangeLayoutMode,
    };
  });

  function callback(key) {
    setCurrentTab(key);
    setLoading(true);
  }

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchSolutions(parsed['id'], currentTab, currentPage, filters, (response, total) => {
        setData(response);
        setNSolution(total);
        setLoading(false);

        let listRowID = [];

        response.map(solution => {
          listRowID.push(solution.id);
        });
        setListRowID(listRowID);
      }),
    );
  }, [dispatch, currentTab, updating, currentPage, filters]);

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    setFilters({
      ...filters,
      filter_service_name: changedValues[key],
    });
  };

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form
          name="basic"
          layout="inline"
          onValuesChange={onValuesChange}
          onFinish={() => {
            let isMounted = true;
            setLoading(true);
            dispatch(
              searchSolutions(parsed['id'], currentTab, currentPage, filters, (response, total) => {
                if (isMounted) {
                  setData(response);
                  setNSolution(total);
                  setLoading(false);
                }
              }),
            );
            return () => {
              isMounted = false;
            };
          }}
          initialValues={{
            name: filters.name,
          }}
        >
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>
            <Form.Item className="dtx-c-field" name="name">
              <Input placeholder="Filter by Solution Name" className="dtx-c-field__input" />
            </Form.Item>
            <Form.Item className="">
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="dtx-c-table">
        <div className="dtx-c-table-header">
          <h6>Solutions</h6>
          <Tabs defaultActiveKey="3" onChange={callback}>
            <TabPane tab="Draft / Suspended" key="0" />
            <TabPane tab="Under Approval" key="1" />
            <TabPane tab="Under Activation" key="2" />
            <TabPane tab="Active" key="3" />
          </Tabs>
        </div>
        <div className="dtx-c-table-content">
          <SolutionTable
            currentTab={currentTab}
            data={data}
            nSolution={nSolution}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            brandID={id}
            brandName={brandName}
            listRowID={listRowID}
          />
        </div>
      </div>
    </div>
  );
};

export default SolutionsTabs;
