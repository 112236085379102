import { message } from 'antd';
import axios from 'axios';
import { DataService } from '../../config/dataService/dataService';
import { setUpdating } from '../themeLayout/actionCreator';
import actions from './actions';

const { changeFilters } = actions;

const searchSolutionsSingleCustomer = (id, currentTab, currentPage, filters, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/service/search_services/`, {
      ...filters,
      customer_id: id,
      page: currentPage,
      filter_status: [currentTab],
    });

    if (response.status === 200) {
      callback(response.data.services, response.data.number_of_rows);
    }
  };
};

const searchSolutions = (id, currentTab, currentPage, filters, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/service/search_services/`, {
      ...filters,
      brand_id: id,
      page: currentPage,
      filter_status: [currentTab],
    });

    if (response.status === 200) {
      callback(response.data.services, response.data.number_of_rows);
    }
  };
};

const editSearchSolutionFilters = value => {
  return async dispatch => {
    dispatch(
      changeFilters({
        name: value,
      }),
    );
  };
};

const createSolution = (form, callback) => {
  return async dispatch => {
    dispatch(setUpdating(true));

    const response = await DataService.post(`/service/create_service/`, form);

    if (response.status === 200) {
      callback(response.data.service);
      dispatch(setUpdating(false));
    } else {
      message.error('Solution with this Name already exists.', 4);
    }
  };
};

const searchListServiceTypes = callback => {
  return async dispatch => {
    const response = await DataService.get(`/service/list_service_types`);

    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const readService = (id, callback) => {
  return async dispatch => {
    // console.log(id);

    const response = await DataService.post(`/service/read_service/`, {
      service_ids: [id],
    });

    // console.log(response);

    if (response.status === 200 && response.data !== undefined && response.data.services.length > 0) {
      callback(response.data);
    }
  };
};

const readKeywordMapping = callback => {
  return async dispatch => {
    const response = await DataService.get(`/service/list_keyword_mappings`);
    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const readUpdateFrequency = callback => {
  return async dispatch => {
    const response = await DataService.get(`/service/list_select_update_frequency`);
    if (response.status === 200) {
      callback(response.data);
    }
  };
};



const postDeleteSolution = form => {
  return async dispatch => {
    // console.log(form);
    dispatch(setUpdating(true));

    const search_brands = await DataService.post(`/service/delete_service/`, form);

    if (search_brands.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
    }
  };
};

const postCreationConfiguration = (form, file, callback) => {
  return async dispatch => {
    dispatch(setUpdating(true));

    console.log(form);

    // const response = await DataService.post(`/service/create_trend_sonar_configuration`, form);

    const formData = new FormData();

    for (let field in form) {
      // console.log(field, typeof(form[field]))
      if (field === 'negative_keywords_file' && form[field] !== undefined) {
        console.log(form[field])
        formData.append(field, form[field].file.originFileObj);
      } else if (field !== 'negative_keywords_file') {
        formData.append(field, form[field]);
      }
    }

    // formData.append('negative_keywords_file', file, file.name);

    axios
      .post('/service/create_trend_sonar_configuration', formData, {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
          'Content-Type': `multipart/form-data`,
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('accessToken')),
        },
      })
      .then(response => {
        if (response.status === 200) {
          dispatch(setUpdating(false));
          callback(response.data);
        }
      })
      .catch(response => {
        console.log(response);
      });
  };
};

const updateService = form => {
  return async dispatch => {
    console.log(form);
    dispatch(setUpdating(true));

    const search_brands = await DataService.post(`/service/update_service_status_or_visibility/`, form);

    console.log(search_brands.status)

    if (search_brands.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
    }  else if (form.status === 2) {
      message.error("we have a problem")
      dispatch(setUpdating(false));
    }
  };
};

const updateServiceCallback = (form, callback) => {
  return async dispatch => {
    console.log(form);
    dispatch(setUpdating(true));

    const search_brands = await DataService.post(`/service/update_service_status_or_visibility/`, form);

    if (search_brands.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
      callback(search_brands.status);
    } else if (form.status === 2) {
      message.error("we have a problem")
    }
  };
};

const updateVisibility = form => {
  return async dispatch => {
    // console.log(form);

    const search_brands = await DataService.post(`/service/update_service_status_or_visibility/`, form);

    if (search_brands.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
    }
  };
};

const updateVisibilityCallback = (form, callback) => {
  return async dispatch => {
    // console.log(form);

    const response = await DataService.post(`/service/update_service_status_or_visibility/`, form);

    if (response.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
      callback(response.data);
    }
  };
};

const searchSolutionsTest = callback => {
  return async dispatch => {
    const response = await DataService.post(`/service/search_services/`);

    if (response.status === 200) {
      callback(response.data.services, response.data.number_of_rows);
    }
  };
};

const readConfiguration = (id, callback) => {
  return async dispatch => {
    const response = await DataService.get(`/service/get_trend_sonar_configuration/${id}`);

    // console.log(response)

    if (response.status === 200) {
      callback(response.data);
    }
  };
};

// To do:
const readServiceLanguage = callback => {
  return async dispatch => {
    const response = await DataService.get(`/service/list_select_language`);
    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const readServiceLocation = callback => {
  return async dispatch => {
    const response = await DataService.get(`/service/list_select_location`);
    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const readServiceExpantionType = callback => {
  return async dispatch => {
    const response = await DataService.get(`/service/list_select_expansion_type`);
    if (response.status === 200) {
      callback(response.data);
    }
  };
};

export {
  createSolution,
  searchSolutions,
  editSearchSolutionFilters,
  searchListServiceTypes,
  readService,
  postDeleteSolution,
  readKeywordMapping,
  readUpdateFrequency,
  postCreationConfiguration,
  updateService,
  updateServiceCallback,
  updateVisibility,
  searchSolutionsTest,
  readConfiguration,
  updateVisibilityCallback,
  searchSolutionsSingleCustomer,

  readServiceLanguage,
  readServiceLocation,
  readServiceExpantionType
};
