import React, { useEffect, useState } from 'react';
import { Form, Input, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AllRequestsSolutions from '../../tables/solutions/AllRequestsSolutions';
import { editSearchSolutionFilters, searchSolutions } from '../../../redux/solutions/actionCreator';
import FeatherIcon from 'feather-icons-react';

const { TabPane } = Tabs;

const AllRequestsSolutionsTabs = ({ id }) => {


  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(2);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nSolution, setNSolution] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [listRowID, setListRowID] = useState([]);

  const [filters, setFilters] = useState();

  const { updating } = useSelector(state => {
    return {
      updating: state.ChangeLayoutMode,
    };
  });

  function callback(key) {
    setCurrentTab(key);
    setLoading(true);
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      setLoading(true);

    dispatch(
      searchSolutions("", currentTab, currentPage, filters, (response, total) => {
        if (isMounted) {

          let listRowID = [];

          response.map(brand => {
            listRowID.push(brand.id);
          });

          setListRowID(listRowID);
          setData(response);
          setNSolution(total);
          setLoading(false);
        }

      }),
    );
    return () => { isMounted = false };
  }, [dispatch, currentTab, updating, currentPage, filters]);

  return (
    <div className="dtx-c-table">
      <div className="dtx-c-table-header">
        <h6>All Requests</h6>
        <Tabs defaultActiveKey="2" onChange={callback}>
          <TabPane tab="Activation" key="2" />
        </Tabs>
      </div>
      <div className="dtx-c-table-content">
        {/* <div className="dtx-c-table__composed"> */}
        <div>
          <AllRequestsSolutions
            currentTab={currentTab}
            data={data}
            nSolution={nSolution}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            listRowID={listRowID}

          />
        </div>
      </div>
    </div>
  );
};

export default AllRequestsSolutionsTabs;
