import { DataService } from '../../config/dataService/dataService';
import actions from './actions';

const { setProfile, setOverviewCardsLoading, setOverviewCardsValues } = actions;

const getProfile = profile => {
  return async dispatch => {
    dispatch(setProfile(profile));
  };
};

const getProfileCall = () => {
  return async dispatch => {
    const profile = await DataService.get(`/user/check_user/`);

    if (profile.status === 200) {
      dispatch(getProfile(profile.data));
    }
  };
};

const getOverviewCardsValues = data => {
  return async dispatch => {
    dispatch(setOverviewCardsValues(data));
  };
};

const getOverviewCardsLoading = data => {
  return async dispatch => {
    dispatch(setOverviewCardsLoading(data));
  };
};

export { getProfile, getProfileCall, getOverviewCardsValues, getOverviewCardsLoading };
