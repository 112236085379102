import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import brandsReducer from './brands/reducers';
import customerDetailReducer from './customerDetails/reducers';
import customersReducer from './customers/reducers';
import solutionReducer from './solutions/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import userReducer from './user/reducers';
import usersReducer from './users/reducers';

const rootReducers = combineReducers({
  auth: authReducer,  
  user: userReducer,
  customersReducer: customersReducer,
  brandsReducer: brandsReducer,
  customerDetailReducer: customerDetailReducer,
  usersReducer: usersReducer,
  ChangeLayoutMode,
  solutionReducer: solutionReducer
});

export default rootReducers;
