import { DataService } from '../../config/dataService/dataService';
import actions from './actions';
import { setUpdating } from '../themeLayout/actionCreator';
import { message } from 'antd';

const { searchCustomers, loading, setFilters, loadingCustomerCard, customerCard } = actions;

const postSearchCustomers = (currentPage, filters, callback) => {
  return async (dispatch, getState) => {
    dispatch(loading(true));

    // console.log(currentPage)

    const search_customers = await DataService.post(`/customer/search_customers/`, {
      ...filters,
      page: currentPage,
    });

    if (search_customers.status === 200) {
      dispatch(searchCustomers(search_customers.data.customers));
      callback(search_customers.data);
      dispatch(loading(false));
    }
  };
};

const getCustomerCard = currentPage => {
  return async (dispatch, getState) => {
    dispatch(loadingCustomerCard(true));

    const response = await DataService.post(`/customer/search_customers/`);

    if (response.status === 200) {
      dispatch(customerCard(response.data.number_of_customers));
      dispatch(loadingCustomerCard(false));
    }
  };
};

const getCustomerList = (value, callback) => {
  return async dispatch => {
    const response = await DataService.get(`/customer/read_customer/`, { customer_ids: [value] });

    // console.log(response);

    if (response.status === 200) {
      callback(response.data.customers);
    }
  };
};

const getCustomerList2 = (value, callback) => {
  return async dispatch => {
    const response = await DataService.get(`/customer/read_customer/`, { customer_ids: [value] });

    // console.log(response);

    if (response.status === 200) {
      callback(response.data.customers);
    }
  };
};

const getCustomerList3 = callback => {
  return async dispatch => {
    const response = await DataService.get(`/customer/read_customer/`);

    // console.log(response);

    if (response.status === 200) {
      callback(response.data.customers);
    }
  };
};

const editSearchCustomersFilters = data => {
  return async dispatch => {
    dispatch(setFilters(data));
  };
};

const postCreateCustomer = (form, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/customer/create_customer/`, form);

    dispatch(setUpdating(true));

    if (response.status === 200) {
      dispatch(setUpdating(false));
      message.success('Create Customer', [3]);
      callback(response.status);
    }
  };
};

const postUpdateCustomer = (form, callback) => {
  return async dispatch => {
    dispatch(setUpdating(true));
    const response = await DataService.post(`/customer/update_customer/`, form);
    if (response.status === 200) {
      dispatch(setUpdating(false));
      message.success('Update Customer', [2]);
      callback(response.status);
    }
  };
};

const postDeleteCustomer = form => {
  return async dispatch => {
    // console.log(form);
    dispatch(setUpdating(true));
    const response = await DataService.post(`/customer/delete_customer/`, form);
    if (response.status === 200) {
      dispatch(setUpdating(false));
      message.success('Delete Customer', [2]);
    }
  };
};

const readCustomers = callback => {
  return async dispatch => {
    const response = await DataService.post(`/customer/search_customers/`);

    if (response.status === 200) {
      callback(response.data.customers);
    }
  };
};

/**************** NEW ENDPOINT */

const getCustomerList_new = (value, callback) => {
  return async dispatch => {
    const response = await DataService.get(`/customer/read_customer/`);

    // console.log(response);

    if (response.status === 200) {
      callback(response.data.customers);
    }
  };
};

export {
  postSearchCustomers,
  getCustomerList,
  editSearchCustomersFilters,
  getCustomerCard,
  postCreateCustomer,
  postUpdateCustomer,
  postDeleteCustomer,
  getCustomerList2,
  getCustomerList3,
  readCustomers,
  getCustomerList_new
};
