import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerList, getCustomerList2 } from '../../../redux/customers/actionCreator';
import { getBrandList } from '../../../redux/brands/actionCreator';
import { postCreateUser } from '../../../redux/users/actionCreator';
import { createSolution, searchListServiceTypes } from '../../../redux/solutions/actionCreator';
import moment from 'moment';

const { Option } = Select;

const queryString = require('query-string');

const NewSolutionSpecialist = ({ visible, onClose, id, setSuccessShow, setIdSuccess, setSuccessItem }) => {
  const dispatch = useDispatch();

  let parsed = queryString.parse(window.location.search);
  const [listCustomer, setListCustomer] = useState([]);
  const [listService, setListService] = useState([]);
  const [listBrand, setListBrand] = useState([]);

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  useEffect(() => {
    if (visible) {
      dispatch(
        getCustomerList(level.id, customers => {
          // console.log(customers);

          let newListCustomer = [];
          customers.map(customer => {
            newListCustomer.push(<Option value={customer.id}>{customer.name}</Option>);
          });

          // console.log(newListCustomer);

          setListCustomer(newListCustomer);
        }),
      );
    }
    dispatch(
      searchListServiceTypes(services => {
        // console.log(services);

        let newListService = [];
        newListService.push(services.map(service => <Option value={service.id}>{service.name}</Option>));
        setListService(newListService);
      }),
    );
  }, [dispatch]);

  const onFinish = values => {
    let newForm = {};

    newForm = {
      ...values,
      start_date: moment().format('YYYY-MM-DD'),
      status: 0,
      end_date: moment()
        .add(5, 'days')
        .format('YYYY-MM-DD'),
    };

    dispatch(
      createSolution(newForm, response => {
        console.log(response.id);
        setSuccessItem('newSolution');
        setIdSuccess(response.id);
        setSuccessShow(true);
        onClose();
      }),
    );
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (

    <Drawer
      className="dtx-c-drawer"
      title={'New Solution'}
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <Form name="basic" layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} {...layout}>
        <div className="dtx-c-field">
          <p className="label2 m-grey">Solution Name</p>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="  e.g. L’Oréal" className="dtx-c-field__input" />
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <span>Customer Name:</span>
          <Form.Item
            name="customer"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              className="dtx-c-field__input"
              placeholder="Select Customer"
              disabled={listCustomer.length > 0 ? false : true}
              onChange={value => {
                dispatch(
                  getBrandList(value, brands => {
                    let newListBrand = [];
                    newListBrand.push(brands.map(brand => <Option value={brand.id}>{brand.name}</Option>));
                    setListBrand(newListBrand);
                  }),
                );
              }}
            >
              {listCustomer}
            </Select>
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <span>Brand Name:</span>
          <Form.Item
            name="brand"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select Brand" disabled={listBrand.length > 0 ? false : true}>
              {listBrand}
            </Select>
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <span>Category:</span>
          <Form.Item
            name="category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select Category">
              <Option value={0}>TOFU</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <span>Solution typology:</span>
          <Form.Item
            name="service_type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select Typology">
            {listService}
            </Select>
          </Form.Item>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default NewSolutionSpecialist;
