import { DataService } from '../../config/dataService/dataService';
import { getOverviewCardsLoading, getOverviewCardsValues } from '../user/actionCreator';
import actions from './actions';
import { setUpdating } from '../themeLayout/actionCreator';
import { message } from 'antd';

const { searchBrands, loading, setBrandsDetail, setFilters } = actions;

// const postSearchBrands = (currentPage, id) => {
//   return async (dispatch, getState) => {
//     dispatch(loading(true));
//     dispatch(getOverviewCardsLoading(true));

//     const search_brands = await DataService.post(`/brand/search_brands/`, {
//       ...getState().customersReducer.filters,
//       customer_id: id,
//       page: currentPage,
//     });

//     if (search_brands.status === 200) {
//       dispatch(searchBrands(search_brands.data.brands));

//       dispatch(
//         getOverviewCardsValues({
//           myCustomers: search_brands.data.number_of_customers,
//           myBrands: search_brands.data.number_of_brands,
//           myUsers: search_brands.data.number_of_users,
//         }),
//       );
//       dispatch(loading(false));
//       dispatch(getOverviewCardsLoading(false));
//     }
//   };
// };

const postSearchBrands = (currentPage, id, filters, callback) => {
  return async (dispatch, getState) => {
    const response = await DataService.post(`/brand/search_brands/`, {
      ...filters,
      customer_id: id,
      page: currentPage,
    });

    if (response.status === 200) {
      callback(response.data.brands);
    }
  };
};

const postCustomerDetailCard = (currentPage, id, callback) => {
  return async (dispatch, getState) => {
    const response = await DataService.post(`/brand/search_brands/`, {
      ...getState().customersReducer.filters,
      customer_id: id,
      page: currentPage,
    });

    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const postOverviewCards = callback => {
  return async dispatch => {
    const response = await DataService.post(`/brand/search_brands/`);
    if (response.status === 200) {
      callback(response.data);
    }
  };
};

const postAllUsersSearchBrands = (id, callback) => {
  return async (dispatch, getState) => {
    const response = await DataService.post(`/brand/search_brands/`, { customer_id: id });

    if (response.status === 200) {
      callback(response.data.brands);
    }
  };
};

// const postSearchLastBrands = (currentPage, callback) => {
//   return async dispatch => {
//     dispatch(loading(true));
//     dispatch(getOverviewCardsLoading(true));

//     const search_brands = await DataService.post(`/brand/search_brands/`);

//     if (search_brands.status === 200) {

//       let listRowID = []
//       search_brands.data.brands.map((brand) =>{
//         listRowID.push(brand.id)
//       })

//       dispatch(searchBrands(search_brands.data.brands));

//       let newBrandsDetail = {}

//       search_brands.data.brands.map((brand) => {
//         newBrandsDetail[brand.id] = brand
//       })

//       dispatch(setBrandsDetail(newBrandsDetail))

//       dispatch(
//         getOverviewCardsValues({
//           myCustomers: search_brands.data.number_of_customers,
//           myBrands: search_brands.data.number_of_brands,
//           myUsers: search_brands.data.number_of_users,
//         }),
//       );
//       dispatch(loading(false));
//       dispatch(getOverviewCardsLoading(false));
//       callback(listRowID)
//     }
//   };
// };

const postSearchLastBrands = callback => {
  return async dispatch => {
    const response = await DataService.post(`/brand/search_brands/`, {order_brand_created_at:"2"});
    if (response.status === 200) {
      callback(response.data.brands);
    }
  };
};

const editSearchBrandsFilters = data => {
  return async dispatch => {
    // console.log(data);

    dispatch(setFilters(data));
  };
};

const getBrandList = (value, callback) => {
  return async dispatch => {
    const brandList = await DataService.post(`/brand/read_brand/`, { customer_ids: [value] });

    // console.log('Brands endpoint: ', brandList)

    if (brandList.status === 200) {
      callback(brandList.data.brands);
    }
  };
};

const getBrandListArray = (value, callback) => {
  return async dispatch => {
    const brandList = await DataService.post(`/brand/read_brand/`, { customer_ids: [value] });

    if (brandList.status === 200) {
      callback(brandList.data.brands);
    }
  };
};

const postCreateBrands = (form, callback) => {
  return async dispatch => {
    dispatch(setUpdating(true));
    const response = await DataService.post(`/brand/create_brand/`, form);
    if (response.status === 200) {
      dispatch(setUpdating(false));
      message.success('Create Brand', [3]);
      callback(response);
    }
  };
};

const postUpdateBrand = (form, callback) => {
  return async dispatch => {
    dispatch(setUpdating(true));
    const response = await DataService.post(`/brand/update_brand/`, form);
    if (response.status === 200) {
      dispatch(setUpdating(false));
      message.success('Brand Updating', [2]);
      callback(response);
    }
  };
};

const postDeleteBrand = form => {
  return async dispatch => {
    // console.log(form);
    dispatch(setUpdating(true));

    const search_brands = await DataService.post(`/brand/delete_brand/`, form);

    if (search_brands.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
      message.success('Brand Delete', [2]);
    }
  };
};

const postReadBrands = (id, callback) => {
  return async (dispatch, getState) => {
    const response = await DataService.get(`/brand/read_brand/`, {
      brand_ids: id,
    });

    if (response.status === 200) {
      callback(response);
    }
  };
};

const postSearchUsersBrand = (id, callback) => {
  return async (dispatch, getState) => {
    dispatch(loading(true));
    dispatch(getOverviewCardsLoading(true));

    const response = await DataService.post(`/brand/search_brands/`, {
      customer_id: id,
    });

    if (response.status === 200) {
      callback(response.data.brands.users);
    }
  };
};

const getBrandListSidebar = (value, callback) => {
  return async dispatch => {
    const brandList = await DataService.get(`/brand/read_brand/`);

    // console.log('Brands endpoint: ', brandList);

    if (brandList.status === 200) {
      callback(brandList.data.brands);
    }
  };
};

/******************** NEW ENDPOINTS */

const postAllUsersSearchBrands_new = (id, callback) => {
  return async (dispatch, getState) => {
    const response = await DataService.post(`/brand/read_brand/`, { brand_ids: [id] });

    if (response.status === 200) {
      callback(response.data.brands[0]);
    }
  };
};

const getBrandList_new = callback => {
  return async dispatch => {
    const response = await DataService.get(`/brand/read_brand/`);

    // console.log('Brands endpoint: ', brandList)

    if (response.status === 200) {
      callback(response.data.brands);
    }
  };
};

export {
  postSearchBrands,
  postCreateBrands,
  postUpdateBrand,
  postDeleteBrand,
  getBrandList,
  postSearchLastBrands,
  editSearchBrandsFilters,
  postReadBrands,
  postSearchUsersBrand,
  postAllUsersSearchBrands,
  postOverviewCards,
  postCustomerDetailCard,
  getBrandListArray,
  getBrandListSidebar,
  postAllUsersSearchBrands_new,
  getBrandList_new,
};
