import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Skeleton, Switch } from 'antd';
import { Bar } from 'react-chartjs-2';
import ChartCards from '../../../components/charts/chartCards/ChartCards';
import HorizontalBarChart from '../../../components/charts/chartCards/HorizontalBarChart';
import LineChart from '../../../components/charts/chartCards/LineChart';
import SearchVolumeSwitch from '../../../components/tabs/solutions/SearchVolumeSwitch';
import { useDispatch, batch, useSelector } from 'react-redux';
import {
  dynamicTrendByCluster,
  getUpdateDate,
  searchVolumeKeywords,
  top10GrowingKeywords,
} from '../../../redux/keywords/actionCreator';
import {
  readConfiguration,
  readService,
  updateService,
  updateVisibility,
  updateVisibilityCallback,
} from '../../../redux/solutions/actionCreator';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import SuspendedSolutionConfirmModal from '../../../components/modals/SuspendedSolutionConfirmModal';
import TrendSonarConfigurationEdit from '../../../components/modals/TrendSonarConfigurationEdit';
import { showConfirm } from '../../../utility/utility';
const queryString = require('query-string');

const getTag = status => {
  let response = [];

  switch (status) {
    case 0:
      response.push(
        <div key="0" className="dtx-c-status dtx-c-status__info">
          <p className="span2">Draft</p>
        </div>,
      );
      break;
    case 1:
      response.push(
        <div key="1" className="dtx-c-status dtx-c-status__info">
          <p className="span2">Need Approval</p>
        </div>,
      );
      break;
    case 2:
      response.push(
        <div key="2" className="dtx-c-status dtx-c-status__warning">
          <p className="span2">Under Activation</p>
        </div>,
      );
      break;
    case 3:
      response.push(
        <div key="3" className="dtx-c-status dtx-c-status__success">
          <p className="span2">Active</p>
        </div>,
      );
      break;
    default:
      <div></div>;
  }

  return response;
};

const forDays = {
  Daily: 'day',
  Monthly: 'month',
  Hourly: 'hour',
  Weekly: 'week',
};
const SolutionDetailPage = ({ location }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [stateCheck, setStateCheck] = useState(false);

  /**
   * Modal Suspended
   */
  const [isSupendedModalVisible, setSupendedModalVisible] = useState(false);

  const [isVisibleChange, setIsVisibleChange] = useState(false);
  const [formSuspended, setFormSuspended] = useState({});

  const [solutionData, setSolutionData] = useState(undefined);
  const [solutionConfigData, setSolutionConfigData] = useState(undefined);

  const [top10Data, setTop10Data] = useState(undefined);
  const [searchVolume, setSearchVolume] = useState(undefined);
  const [dynamicTrend, setDynamicTrend] = useState(undefined);

  const [isSolutionDataLoading, setIsSolutionDataLoading] = useState(true);
  const [isTop10DataLoading, setIsTop10DataLoading] = useState(true);
  const [isSearchVolumeLoading, setIsSearchVolumeLoading] = useState(true);
  const [isDynamicTrendLoading, setIsDynamicTrendLoading] = useState(true);

  const [dataUpdate, setDataUpdate] = useState(undefined);

  const [configurationEditModal, setConfigurationEditModal] = useState(false);

  const showConfigurationEditModal = () => {
    setConfigurationEditModal(true);
  };

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  let parsed = queryString.parse(window.location.search);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSolutionData(undefined);
      setSolutionConfigData(undefined);

      setTop10Data(undefined);
      setSearchVolume(undefined);
      setDynamicTrend(undefined);

      setIsSolutionDataLoading(true);
      setIsTop10DataLoading(true);
      setIsSearchVolumeLoading(true);
      setIsDynamicTrendLoading(true);

      dispatch(
        readService(parsed['id'], response => {
          // console.log(response)

          let service = response.services.find(x => x.id === parseInt(parsed['id']));
          setIsVisibleChange(service.is_visible);
          setStateCheck(service.is_visible);

          dispatch(
            readConfiguration(service.id, configResponse => {
              // console.log(configResponse.configuration);

              setSolutionConfigData(configResponse.configuration);

              // console.log(
              //   configResponse.configuration.find(
              //     x => x.service_config_item.name === 'select_time_interval_visualization',
              //   ).value,
              // );

              setSolutionData(service);
              setIsSolutionDataLoading(false);
            }),
          );
        }),
      );

      dispatch(
        getUpdateDate({ service_id: parsed['id'] }, response => {
          setDataUpdate(response.data_updated_at);
        }),
      );
    }
    return () => {
      isMounted = false;
    };
  }, [parsed['id'], isVisibleChange]);

  /**
   * { service_name: 'demo' }: is a Value for testing
   */
  useEffect(() => {
    let isMounted = true;
    // console.log('ENTRO');
    if (isMounted) {
      if (solutionData !== undefined) {
        batch(() => {
          dispatch(
            top10GrowingKeywords({ service_id: solutionData.id }, response => {
              setTop10Data(response);
              setIsTop10DataLoading(false);
            }),
          );

          dispatch(
            searchVolumeKeywords({ service_id: solutionData.id, limit_number_categories: 15 }, response => {
              setSearchVolume(response);
              setIsSearchVolumeLoading(false);
            }),
          );

          dispatch(
            dynamicTrendByCluster(
              {
                service_id: solutionData.id,
                time_interval_visualization:
                  solutionConfigData.find(x => x.service_config_item.name === 'select_time_interval_visualization') !==
                    undefined &&
                  solutionConfigData.find(x => x.service_config_item.name === 'select_time_interval_visualization')
                    .value,
              },
              response => {
                let newDynamicTrend = {
                  clusters: [],
                  dates: response['dates'],
                };

                for (let key in response.clusters) {
                  newDynamicTrend['clusters'].push({
                    data: response['clusters'][key],
                    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                    borderWidth: 1,
                    fill: false,
                    label: key,
                  });
                }

                setDynamicTrend(newDynamicTrend);
                setIsDynamicTrendLoading(false);
              },
            ),
          );
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [solutionData]);

  return (
    <div id="solution" className="dtx-l-main">
      <div className="dtx-l-header">
        <div className="dtx-c-header-info">
          <Row>
            <div
              className="dtx-c-icon mb-1-5"
              onClick={() => {
                history.goBack();
              }}
            >
              <FeatherIcon icon="arrow-left" className="m-secondary" />
              <p className="label1 m-secondary">Back</p>
            </div>
          </Row>
          <Row>
            <h4>
              {solutionData !== undefined && !isSolutionDataLoading ? (
                solutionData.name
              ) : (
                <Skeleton active paragraph={{ rows: 0 }} />
              )}
            </h4>
            {solutionData !== undefined && !isSolutionDataLoading ? (
              getTag(solutionData.status)
            ) : (
              <Skeleton className="span-skeleton" active paragraph={{ rows: 0 }} />
            )}
          </Row>
          <Row className="mt-5px">
            <p className="span1 m-lightgrey">Data updated to:&nbsp;</p>
            {dataUpdate !== undefined ? (
              <p className="span1 m-lightgrey d-flex">
                <span className="bold">{dataUpdate}</span>
              </p>
            ) : (
              <Skeleton className="span-skeleton" active paragraph={{ rows: 0 }} />
            )}
          </Row>
        </div>
        <div className="dtx-c-header-action">
          <div className="dtx-c-switch">
            {!isSolutionDataLoading &&
              solutionData !== undefined &&
              (level.user.is_superuser || level.user_groups[0] !== 'client') && (
                <p className="label2 m-grey">Visibility:</p>
              )}
            {!isSolutionDataLoading &&
            solutionData !== undefined &&
            (level.user.is_superuser || level.user_groups[0] !== 'client') ? (
              <div>
                <Switch
                  checked={stateCheck}
                  checkedChildren="Visible"
                  unCheckedChildren="Invisible"
                  onChange={value => {
                    if (value) {
                      showConfirm(
                        'Solution Visualization',
                        `You are trying to set up the solution to "visibile".
                      The Customer User will receive an email and could access to the platform and see the solution. Do you want to proceed?`,
                        condition => {
                          if (condition) {
                            setStateCheck(value);
                          }
                        },
                      );
                    } else {
                      setStateCheck(value);

                      dispatch(
                        updateVisibility(
                          {
                            service_id: solutionData.id,
                            is_visible: value,
                          },
                          response => {
                            setIsVisibleChange(response.modified_service.is_visible);
                          },
                        ),
                      );
                    }
                  }}
                />
              </div>
            ) : (
              <Skeleton />
            )}
            {/* {
              <Switch
                defaultChecked={solutionData.is_visible}
                onChange={value => {
                  dispatch(
                    updateVisibility(
                      {
                        service_id: solutionData.id,
                        is_visible: value,
                      },
                      response => {
                        console.log(response);
                      },
                    ),
                  );
                }}
              />
            } */}
          </div>
          {level.user.is_superuser || level.user_groups[0] === 'seomaster' ? (
            <Button
              className={`${isVisibleChange ? 'dtx-d-none' : 'dtx-c-btn btn__small dtx-c-btn__solid'}`}
              disabled={isVisibleChange}
              onClick={() => {
                setFormSuspended({
                  service_id: solutionData.id,
                  status: 0,
                });

                setSupendedModalVisible(true);
              }}
            >
              <FeatherIcon icon={'pause-circle'} size={14} className="m-white" />
              Suspend Solution
            </Button>
          ) : (
            <div></div>
          )}
          {level.user.is_superuser || level.user_groups[0] === 'seomaster' ? (
            <Button
              onClick={() => showConfigurationEditModal(true)}
              className="dtx-c-btn btn__small dtx-c-btn__secondary"
            >
              <FeatherIcon icon={'info'} size={14} className="m-primary" />
              View Solution Setup
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="dtx-c-plot__section">
        <h5>Top 10 Keywords by Growth Rate and Type</h5>
        <Row className="dtx-c-plot-row">
          <div className="dtx-c-plot small">
            <ChartCards
              response={top10Data !== undefined && !isTop10DataLoading && top10Data['High Volume'].data !== null ? top10Data['High Volume'].data : []}
              labels={top10Data !== undefined && !isTop10DataLoading && top10Data['High Volume'].labels !== null ? top10Data['High Volume'].labels : []}
              title={'High Volume Constant Signal'}
              tootlipText={'Keywords with consistent high search volume compared to the rest of the dataset.'}
            />
          </div>
          <div className="dtx-c-plot small">
            <ChartCards
              response={top10Data !== undefined && !isTop10DataLoading && top10Data['Low Volume'].data !== null ? top10Data['Low Volume'].data : []}
              labels={top10Data !== undefined && !isTop10DataLoading && top10Data['Low Volume'].labels !== null ? top10Data['Low Volume'].labels : []}
              title={'Low Volume Constant Signal'}
              tootlipText={'Keywords with consistent but low search volume compared to the rest of the dataset.'}
            />
          </div>
          <div className="dtx-c-plot small">
            <ChartCards
              response={top10Data !== undefined && !isTop10DataLoading && top10Data['Weak Volume'].data !== null ? top10Data['Weak Volume'].data : []}
              labels={top10Data !== undefined && !isTop10DataLoading && top10Data['Weak Volume'].labels !== null ? top10Data['Weak Volume'].labels : []}
              title={'Weak Volume'}
              tootlipText={
                'Keywords with an inconsistent search volume; New keywords or keywords that exhibit seasonal peaks due to local/global events.'
              }
            />
          </div>
        </Row>
      </div>
      <div className="dtx-c-plot__section">
        <h5>Clusters Search Volume</h5>
        <Row className="dtx-c-plot-row">
          <div className="dtx-c-plot middle">
            <HorizontalBarChart
              response={searchVolume !== undefined && !isSearchVolumeLoading && searchVolume['average_cluster_volume']}
              labels={searchVolume !== undefined && !isSearchVolumeLoading && searchVolume['category_1']}
              title={`Average ${
                solutionConfigData !== undefined
                  ? solutionConfigData.find(
                      x => x.service_config_item.name === 'select_time_interval_visualization',
                    ) !== undefined &&
                    solutionConfigData.find(x => x.service_config_item.name === 'select_time_interval_visualization')
                      .value
                  : ''
              } Volume`}
            />
          </div>
          <div className="dtx-c-plot middle">
            <LineChart
              response={dynamicTrend !== undefined ? dynamicTrend['clusters'] : []}
              labels={dynamicTrend !== undefined ? dynamicTrend['dates'] : []}
              title={`Search Volume Trend per ${
                solutionConfigData !== undefined
                  ? solutionConfigData.find(
                      x => x.service_config_item.name === 'select_time_interval_visualization',
                    ) !== undefined &&
                    forDays[
                      solutionConfigData.find(x => x.service_config_item.name === 'select_time_interval_visualization')
                        .value
                    ]
                  : ''
              }`}
            />
          </div>
        </Row>
      </div>
      <div className="dtx-c-plot__section">
        <h5>Keywords Insights</h5>
        <div>
          {!isSolutionDataLoading && solutionData !== undefined ? (
            <div>
              <SearchVolumeSwitch
                service_name={solutionData.name}
                service_id={parsed['id']}
                time_interval_visualization={
                  solutionConfigData !== undefined
                    ? solutionConfigData.find(
                        x => x.service_config_item.name === 'select_time_interval_visualization',
                      ) !== undefined &&
                      solutionConfigData.find(x => x.service_config_item.name === 'select_time_interval_visualization')
                        .value
                    : ''
                }
              />
            </div>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      <TrendSonarConfigurationEdit
        configurationEditModal={configurationEditModal}
        setConfigurationEditModal={setConfigurationEditModal}
        record={solutionData}
        disabledModal={true}
      />
      <SuspendedSolutionConfirmModal
        isModalVisible={isSupendedModalVisible}
        setIsModalVisible={setSupendedModalVisible}
        func={updateService}
        form={formSuspended}
      />
    </div>
  );
};

export default SolutionDetailPage;
