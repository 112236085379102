import { message } from 'antd';
import Cookies from 'js-cookie';
import axios from './api2';

function refreshToken() {
  // console.log(JSON.parse(localStorage.getItem('refreshToken')));
  // console.log(localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== 'undefined');

  if (localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== 'undefined') {
    axios
      .post(`/auth/jwt/refresh/`, { refresh: JSON.parse(localStorage.getItem('refreshToken')) })
      .then(response => {
        // console.log(response);

        if (response.data.access && response.data.refresh ) {
          localStorage.setItem('accessToken', JSON.stringify(response.data.access));
          localStorage.setItem('refreshToken', JSON.stringify(response.data.refresh));

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;

          Cookies.set('logedIn', true);

          window.location.reload(true);
        } else {
          message.error('this session expired', [3]);

          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          Cookies.remove('logedIn');
          Cookies.remove('profile');
  
          window.location.reload(true);
        }
      })
      .catch(function(error) {
        message.error('this session expired', [3]);

        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        Cookies.remove('logedIn');
        Cookies.remove('profile');

        window.location.reload(true);
      });
  } else {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    Cookies.remove('logedIn');
    Cookies.remove('profile');

    // window.location.reload(true);
  }
}

class DataService2 {
  
  static get(path = '') {
    return axios({
      method: 'GET',
      url: path,
      // headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return axios({
      method: 'POST',
      url: path,
      data,
      // headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return axios({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      // headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return axios({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      // headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return axios({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      // headers: { ...authHeader() },
    });
  }
}

axios.interceptors.request.use(config => {
  const requestConfig = config;
  const { headers } = config;

  // console.log(config);

  if (config.url === '/user/check_user/') {
    requestConfig.headers = {
      ...headers,
    };
  } else if (localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== 'undefined') {
    requestConfig.headers = {
      ...headers,
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('accessToken')),
    };
  } else {
    requestConfig.headers = {
      ...headers,
    };
  }

  return requestConfig;
});

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      if (response.status === 500) {
        // console.log('Error 500: ', response);
        return originalRequest;
      } else if (response.status === 401) {
        // console.log('Error 401: ', response.data.code);
        // if (
        //   response.data.code === 'token_not_valid' ||
        //   response.data.detail === 'Authentication credentials were not provided.'
        // ) {
        refreshToken();
        // }
        return originalRequest;
      } else {
        return originalRequest;
      }
    }

    return Promise.reject(error);
  },
);
export { DataService2 };