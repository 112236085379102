import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postCreateBrands } from '../../../redux/brands/actionCreator';
import { getCustomerList } from '../../../redux/customers/actionCreator';
import { getClientUsers } from '../../../redux/users/actionCreator';
import { getSearchCustomer, postSearchCustomer } from '../../../redux/customerDetails/actionCreator';
import { Redirect, useHistory } from 'react-router-dom';

const { Option } = Select;

const NewBrand = ({ visible, onClose, idCustomer, setSuccessShow, setIdSuccess, setSuccessItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  const [listCustomer, setListCustomer] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(
        getSearchCustomer(idCustomer, values => {
          let newListCustomer = [];
          newListCustomer.push(values.customers.map(customer => <Option value={customer.id}>{customer.name}</Option>));
          setListCustomer(newListCustomer);
        }),
      );
    }
  }, [visible, dispatch]);

  const onFinish = values => {
    setLoading(true);

    let payload = {
      ...values,
      users: [],
    };

    dispatch(
      postCreateBrands(payload, response => {
        // console.log(response)

        if (response.status === 200) {
          setLoading(false);
          onClose();
          // setSuccessShow(true);
          // setIdSuccess(response.data.brand.id);
          // setSuccessItem('newBrand')
          // history.push(`/brands/detail?id=${response.data.brand.id}`)
          // window.history.pushState({},"",`/brands/detail?id=${response.data.brand.id}`)
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Drawer
      title={'New Brand'}
      className="dtx-c-drawer"
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ customer: idCustomer !== undefined ? parseInt(idCustomer) : undefined }}
          onFinishFailed={onFinishFailed}
          {...layout}
        >
          <div className="dtx-c-field">
            <p className="label2 m-grey">Brand Name</p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="  e.g. L’Oréal" className="dtx-c-field__input" />
            </Form.Item>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey">Brand Website (optional):</p>
            <Form.Item
              name="website"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="  e.g. www.loreal.com/en" className="dtx-c-field__input" />
            </Form.Item>
          </div>
          <div className="dtx-c-field">
            <p className="label2 m-grey"> Select the Customer to whom to associate the Brand:</p>
            <Form.Item
              name="customer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Customer"
                className="dtx-c-field__select"
                disabled={listCustomer.length > 0 ? false : true}
              >
                {listCustomer}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
              {loading ? <span className="Label">Loading...</span> : <span>Create Brand</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default NewBrand;
