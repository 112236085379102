import actions from './actions';

const { SEARCH_USERS, LOADING_USERS, SET_FILTERS, GET_USER_CARD, GET_LOADING_USER_CARD, GET_USERS_TAGS, SET_NUSERS } = actions;

const initState = {
  users: [],
  nUsers: null,
  filters: {
    "filter_user_username": "",
    "filter_user_first_name": "",
    "filter_user_last_name": "",
    "filter_user_email": "",
    "order_user_username": "",
    "order_user_first_name": "",
    "order_user_last_name": "",
    "order_user_email": "",
    "order_user_created_at": ""
  },
  loading: false,
  overviewCardsLoading: false,
  usersTags: {},
  userCard: null,
  loadingUserCard: null
};

/**
 *
 * @todo impure state mutation/explaination
 */
const usersReducer = (state = initState, action) => {
  const { type, data, /* err */ } = action;

  switch (type) {
    case SEARCH_USERS:
      return {
        ...state,
        users: data,
      };
    case LOADING_USERS:
      return {
        ...state,
        loading: data,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: data,
      };
    case GET_USER_CARD:
      return {
        ...state,
        userCard: data,
      };
    case GET_LOADING_USER_CARD:
      return {
        ...state,
        loadingUserCard: data,
      };

      case GET_USERS_TAGS:
        return {
          ...state,
          usersTags: data
      }; 

      case SET_NUSERS:
        return {
          ...state,
          nUsers: data
        }
    default:
      return state;
  }
};
export default usersReducer;
