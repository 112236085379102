import React, { useEffect, useState } from 'react';
import { Table, Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { editSearchBrandsFilters, postDeleteBrand, postSearchBrands } from '../../../redux/brands/actionCreator';
import { Link } from 'react-router-dom';
import Drawers from '../../drawer';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import FeatherIcon from 'feather-icons-react';
import { searchUsersTag } from '../../../redux/users/actionCreator';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AllBrandsTable = ({ id }) => {
  const dispatch = useDispatch();

  /***** VARIABLES *****/

  const [recordUpdateBrand, setRecordUpdateBrand] = useState();
  const [visible, setVisible] = useState(false);

  const [filters, setFilters] = useState({
    filter_brand_name: '',
    order_brand_name: '',
    order_number_users: '',
    order_number_projects: '',
    order_brand_created_at: '',
  });

  /**
   * Variable for the Table:
   *  - dataTable: the records show in the table
   *  - loadingTable: use for show the loading function for all calls
   *  - columns: list of the table columns
   *  - currentPage: the current page selection for the endpoint
   */
  const [dataTable, setDataTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listRowID, setListRowID] = useState([]);

  const columns = [
    {
      title: 'Brand Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <Link to={`/brands/detail?id=${record.id}`} className="m-secondary">
          {value}
        </Link>
      ),
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    { title: 'Active Solutions', dataIndex: 'number_active_solutions', key: 'number_active_solutions' },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
      render: value => moment(value).format('DD/MM/YYYY'),
    },

    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (value, record) => (
        <div className="dtx-c-table__action">
          <div
            className="dtx-c-btn__icon"
            onClick={() => {
              setRecordUpdateBrand(record);
              showDrawer();
            }}
          >
            <FeatherIcon icon={'edit'} className="m-extralightgrey" />
          </div>
          {record.number_active_solutions === 0 && (
            <div
              className="dtx-c-btn__icon s-error"
              onClick={() => {
                showModal({ brand_id: record.id });
              }}
            >
              <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
            </div>
          )}
        </div>
      ),
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Variable for the endpoint from Redux
   */
  const { usersTags, updating } = useSelector(state => {
    return {
      usersTags: state.usersReducer.usersTags,
      updating: state.ChangeLayoutMode,
    };
  });

  /***** OTHER COMPONENT *****/

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };

  /**
   * Setup Function Drawer
   */
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  /***** FUNCTIONS *****/

  /**
   * The first dispatch is for to fill the table
   * Other call is after changing the current page or the filters
   */
  useEffect(() => {
    setLoadingTable(true);
    dispatch(
      postSearchBrands(currentPage, id, filters, brands => {
        let listRowID = [];

        brands.map(brand => {
          listRowID.push(brand.id);
        });

        setListRowID(listRowID);

        setDataTable(brands);
        setLoadingTable(false);
      }),
    );
  }, [currentPage, dispatch, id, updating]);

  useEffect(() => {
    dispatch(searchUsersTag());
  }, []);

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    // console.log(sorter);

    if (sorter !== undefined) {
      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        if (field === 'name' || field === 'created_at') {
          if (order !== undefined) {
            setFilters({
              ...filters,
              [`order_brand_${field}`]: order === 'ascend' ? 1 : 2,
            });
          } else {
            setFilters({
              ...filters,
              [`order_brand_${field}`]: '',
            });
          }
        } else if (field === 'number_brands') {
          if (order !== undefined) {
            setFilters({
              ...filters,
              [`order_${field}`]: order === 'ascend' ? 1 : 2,
            });
          } else {
            setFilters({
              ...filters,
              [`order_${field}`]: '',
            });
          }
        }
      }
    }

    if (pagination.current !== currentPage) {
      // setStatusTable(true);
      setCurrentPage(pagination.current);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    setFilters({
      ...filters,
      filter_brand_name: changedValues[key],
    });
  };

  /**
   *
   * @param {*} users Variable contains the list of tag for single brand (row)
   * @returns generate the HTML structure for show the tags under the brand row
   */
  const getUser = users => {
    let tags = [];

    // console.log(users)
    // console.log(usersTags)

    tags.push(
      users.map(user => (
        <div className="Tag" key={user.id}>
          <span className="client-name">{usersTags[user.id]}</span>
        </div>
      )),
    );

    return tags;
  };

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form name="basic" layout="inline" onValuesChange={onValuesChange} onFinish={() => {
          setLoadingTable(true);
          dispatch(
            postSearchBrands(currentPage, id, filters, brands => {
              let listRowID = [];
      
              brands.map(brand => {
                listRowID.push(brand.id);
              });
      
              setListRowID(listRowID);
      
              setDataTable(brands);
              setLoadingTable(false);
            }),
          );
        }} {...layout}>
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>
            <Form.Item className="dtx-c-field" name="name">
              <Input placeholder="Filter by Brand Name" className="dtx-c-field__input" />
            </Form.Item>
            <Form.Item className="">
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="dtx-c-table">
        <div className="dtx-c-table-header">
          <h6>All Brands</h6>
        </div>
        <div className="dtx-c-table-content">
          <div className="dtx-c-table-content">
            <Table
              columns={columns}
              onChange={handleTableChange}
              dataSource={dataTable}
              pagination={false}
              loading={loadingTable}
              rowKey={record => record.id}
            />

            <Drawers
              drawerName={'updateBrand'}
              visible={visible}
              onClose={onClose}
              id={id}
              record={recordUpdateBrand}
            />

            <DeleteConfirmModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              func={postDeleteBrand}
              form={formDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBrandsTable;
