import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="dtx-l-main">
    <div className="dtx-l-header">
      <h4>{`404 - Not Found!`}</h4>
      
    </div>
    <Link to="/">Go Homepage</Link>
  </div>
);

export default NotFound;
