import React, { useEffect, useState } from 'react';
import { Table, Form, Input, Tooltip, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  editSearchCustomersFilters,
  postDeleteCustomer,
  postSearchCustomers,
} from '../../redux/customers/actionCreator';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Drawers from '../drawer';
import DeleteConfirmModal from '../modals/DeleteConfirmModal';
import { useDebouncedCallback } from 'use-debounce';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ClientsTable = ({ id }) => {
  const dispatch = useDispatch();

  const [customerData, setCustomerData] = useState([]);
  const [totals, setTotals] = useState([]);

  const [recordUpdateBrand, setRecordUpdateBrand] = useState();
  const [visible, setVisible] = useState(false);

  const [listRowID, setListRowID] = useState([]);

  const [filters, setFilters] = useState({
    filter_customer_name: '',
    filter_seouser_first_name: '',
    order_customer_name: '',
    order_number_brands: '',
    order_number_projects: '',
    order_customer_created_at: '',
  });

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };

  const { level, customers, loading, updating } = useSelector(state => {
    return {
      level: state.user.level,
      customers: state.customersReducer.customers,
      loading: state.customersReducer.loading,
      updating: state.ChangeLayoutMode,
    };
  });

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      postSearchCustomers(currentPage, filters, response => {
        setTotals(response.number_of_customers);

        let listRowID = [];

        response.customers.map(customer => {
          listRowID.push(customer.id);
        });

        // console.log(listRowID);

        setListRowID(listRowID);

        setCustomerData(response.customers);
      }),
    );
  }, [currentPage, filters]);

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
      render: (value, record) => (
        <Link to={`/clients/detail?id=${record.id}`} className="m-secondary">
          {value}
        </Link>
      ),
    },
    {
      title: 'Brands',
      dataIndex: 'number_brands',
      key: 'number_brands',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    { title: 'Active Solutions', dataIndex: 'number_active_solutions', key: 'number_active_solutions' },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    // Commented by Ryan. If remove this comment, Customers page will be crashed.
    // { title: 'Customer Users', dataIndex: 'client_users', key: 'client_users' },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (value, record) => (
        <div className="dtx-c-table__action">
          {level.user.is_superuser || (level.user_groups[0] !== 'seouser' && level.user_groups[0] === 'seomaster') ? (
            <div
              className="dtx-c-btn__icon"
              onClick={() => {
                // console.log(record);
                setRecordUpdateBrand(record);
                showDrawer();
              }}
            >
              <FeatherIcon icon={'edit'} className="m-extralightgrey" />
            </div>
          ) : (
            <div />
          )}
          {level.user.is_superuser || (level.user_groups[0] !== 'seouser' && level.user_groups[0] === 'seomaster') ? (
            record.number_active_solutions === 0 && (
              <div
                className="dtx-c-btn__icon s-error"
                onClick={() => {
                  showModal({ customer_id: record.id });
                }}
              >
                <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
              </div>
            )
          ) : (
            <div />
          )}
        </div>
      ),
    },
  ];

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];
    setFilters({
      ...filters,
      [`filter_${key}`]: changedValues[key],
    });
  };

  /**
   * Setup Function Drawer
   */
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    // console.log(sorter);
    // console.log(pagination);

    if (sorter !== undefined) {
      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        if (field === 'name' || field === 'created_at') {
          if (order !== undefined) {
            setFilters({
              ...filters,
              [`order_customer_${field}`]: order === 'ascend' ? 1 : 2,
            });
          } else {
            setFilters({
              ...filters,
              [`order_customer_${field}`]: '',
            });
          }
        } else if (field === 'number_brands') {
          if (order !== undefined) {
            setFilters({
              ...filters,
              [`order_${field}`]: order === 'ascend' ? 1 : 2,
            });
          } else {
            setFilters({
              ...filters,
              [`order_${field}`]: '',
            });
          }
        }

        // dispatch(
        //   postSearchCustomers(currentPage, filters, response => {
        //     setTotals(response.number_of_customers);

        //     let listRowID = [];

        //     response.customers.map(customer => {
        //       listRowID.push(customer.id);
        //     });

        //     setListRowID(listRowID);

        //     setCustomerData(response.customers);
        //   }),
        // );
      }
    }
    if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
    }
  };

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form
          name="basic"
          layout="inline"
          // onFinish={onValuesChange}
          onValuesChange={onValuesChange}
          onFinish={() => {
            let isMounted = true;
            dispatch(
              postSearchCustomers(currentPage, filters, response => {
                if (isMounted) {
                  setTotals(response.number_of_customers);

                  let listRowID = [];

                  response.customers.map(customer => {
                    listRowID.push(customer.id);
                  });

                  setListRowID(listRowID);

                  setCustomerData(response.customers);
                }
              }),
            );
            return () => {
              isMounted = false;
            };
          }}
          initialValues={{
            name: filters.filter_customer_name,
          }}
          {...layout}
        >
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>
            <Form.Item className="dtx-c-field" name="customer_name">
              <Input placeholder="Filter by Customer Name" className="dtx-c-field__input" />
            </Form.Item>
            <Form.Item className="dtx-c-field" name="seouser_first_name">
              <Input placeholder="Filter by Specialist Name" className="dtx-c-field__input" />
            </Form.Item>
            <Form.Item className="">
              <Button className="dtx-c-btn btn__small dtx-c-btn__solid" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="dtx-c-table">
        <div className="dtx-c-table-header">
          <h6>All Customers</h6>
        </div>
        <div className="dtx-c-table-content">
          <div className="dtx-c-table__composed">
            {/* {console.log(customers)} */}

            <Table
              columns={columns}
              onChange={handleTableChange}
              pagination={{ position: ['bottomCenter'], pageSize: 10, total: totals }}
              dataSource={customers}
              loading={loading}
              rowKey={record => record.id}
              expandable={{
                expandedRowRender: record => (
                  <div className="dtx-c-table-subrow">
                    <p className="span3">Specialist: </p>
                    {record.seo_users !== null ? (
                      record.seo_users.map(seo => (
                        <div className="dtx-c-tag back-b-grey3">
                          <p className="span3">{seo.first_name + ' ' + seo.last_name}</p>
                        </div>
                      ))
                    ) : (
                      <Spin />
                    )}
                  </div>
                ),
                expandedRowKeys: listRowID,
              }}
              defaultExpandAllRows={true}
              showExpandColumn={true}
              expandIconAsCell={true}
              expandIconColumnIndex={-1}
            />
          </div>
        </div>
      </div>
      <Drawers drawerName={'updateCustomer'} visible={visible} onClose={onClose} id={id} record={recordUpdateBrand} />
      <DeleteConfirmModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        func={postDeleteCustomer}
        form={formDelete}
      />
    </div>
  );
};

export default ClientsTable;
