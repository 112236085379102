import Cookies from 'js-cookie';
import { DataService } from '../../config/dataService/dataService';
import { getProfile } from '../user/actionCreator';
import actions from './actions';
import axios from '../../config/dataService/api';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  resetPasswordBegin,
  resetPasswordSuccess,
  resetPasswordErr,
  getExpired
} = actions;

const login = (callback, form) => {
  return async dispatch => {
    dispatch(loginBegin());
    const response = await DataService.post(`/auth/jwt/create/`, form);

    // console.log(response)

    if (response.status === 200) {
      Cookies.set('logedIn', true);
      localStorage.setItem('accessToken', JSON.stringify(response.data.access));
      localStorage.setItem('refreshToken', JSON.stringify(response.data.refresh));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
      const profile = await DataService.get(`/user/check_user/`, form);

      if (profile.status === 200) {
        Cookies.set('profile', profile.data);

        dispatch(getProfile(profile.data));
        dispatch(loginSuccess(response.data));
      }
    } else {
      dispatch(loginErr(response));
      callback(response);
    }
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('profile');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const resetPassword = (form, showModal,callback) => {
  return async dispatch => {

    // console.log(form)

    // dispatch(resetPasswordBegin());

    const response = await DataService.post(`/auth/users/reset_password/`, form);

    if (response.status === 204) {
      showModal(true);
    } else {
      callback(response);
    }
  };
};

const newPassword = (callback, form, showModal) => {
  return async dispatch => {
    // console.log(form);
    dispatch(resetPasswordBegin());
    const response = await DataService.post(`/auth/users/reset_password_confirm/`, form);

    // console.log(response)

    if (response.status === 204) {
      dispatch(resetPasswordSuccess(response.data));    
      showModal(true);
    } else {
      dispatch(resetPasswordErr(response));
      callback(response);
    }
  };
};

const changePassword = (form, callback) => {
  return async dispatch => {

    // console.log(form);

    const response = await DataService.post(`/auth/users/set_password/`, form);
    if (response.status === 204) {
      callback(response.status)
    } else {
      callback(response.status)
    }
  };
};


const changeExpired = (data) => {
  return async dispatch => {

    getExpired(data)

  };
};


export { login, logOut, resetPassword, newPassword, changePassword, changeExpired };
