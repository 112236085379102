import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { getCustomerList2 } from '../../../redux/customers/actionCreator';
import { getBrandList, postAllUsersSearchBrands_new, postReadBrands, postUpdateBrand } from '../../../redux/brands/actionCreator';
import { getClientUsers, postCreateUser } from '../../../redux/users/actionCreator';

const { Option } = Select;

const NewExisting = ({ visible, onClose, id, idBrand }) => {

  // console.log(idBrand)

  const dispatch = useDispatch();

  const [listCustomer, setListCustomer] = useState(undefined);
  const [listCustomerActvie, setListCustomerActvie] = useState(undefined);
  const [thisBrand, setThisBrand] = useState(undefined);

  useEffect(() => {
    if (visible) {
      dispatch(
        postAllUsersSearchBrands_new(idBrand, values => {

          let exactlyBrand = values

          setThisBrand(exactlyBrand);
          let users = [];

          exactlyBrand.users.map(user => {
            users.push(user);
          });

          setListCustomerActvie(users);

        }),
      );

      dispatch(
        getClientUsers({ get_seousers: 'false', get_clientusers: 'true' }, values => {
          let newListClientUser = [];

          values.map(userClient => {
            newListClientUser.push(
              <Option value={userClient.id}>{userClient.first_name + ' ' + userClient.last_name}</Option>,
            );
          });

          // console.log(newListClientUser);

          setListCustomer(newListClientUser);
        }),
      );
    }
  }, [dispatch]);

  const onFinish = values => {

    let payload = {
      ...thisBrand,
      ...values,
      brand_id: thisBrand.id,
      customer: thisBrand.customer.id
    };

    dispatch(
      postUpdateBrand(payload, response => {
        // console.log(response)

        if (response.status === 200) {
          onClose();
        }
      }),
    );
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Drawer
      title={'Add Existing User'}
      className="dtx-c-drawer"
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <div>
        {listCustomer !== undefined && visible && listCustomerActvie !== undefined ? (
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ users: listCustomerActvie }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...layout}
          >
           
            <div className="dtx-c-field">
              <p className="label2 m-grey">Select existing Customer Users to associate with the Brand:</p>
              <Form.Item
                name="users"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Select Customer User" mode="multiple" className="dtx-c-field__select">
                  {listCustomer}
                </Select>
              </Form.Item>
            </div>
            <Form.Item>
              <Button className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
                Add Existing User
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <>
            <div className="dtx-c-field">
              <p className="label2 m-grey">Select existing Customer Users to associate with the Brand:</p>
              <Form.Item
                name="client_ids"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={true}
                  placeholder="Select Customer User"
                  mode="multiple"
                  className="dtx-c-field__select"
                >
                  {listCustomer}
                </Select>
              </Form.Item>
            </div>
            <Form.Item>
              <Button disabled={true} className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
                Add Existing User
              </Button>
            </Form.Item>
          </>
        )}
      </div>
    </Drawer>
  );
};
export default NewExisting;
