import { Button, Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IndexModals from '../../../components/modals/indexModals';
import IndexModalsMaster from '../../../components/modals/indexModalsMaster';
import Drawers from '../../../components/drawer';
import CardItems from '../../../components/homepage/CardItems';
import AllUsersBrands from '../../../components/tables/users/AllUsersBrands';
import AllBrandsTable from '../../../components/tables/brands/AllBrandsTable';
import { postCustomerDetailCard } from '../../../redux/brands/actionCreator';
import { postSearchCustomer } from '../../../redux/customerDetails/actionCreator';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import SuccessModals from '../../../components/modals/SuccessModals';
import CardCustomerItems from '../../../components/homepage/CardCustomerItems';

const queryString = require('query-string');

const checkLoading = (container, key) => {
  if (container !== undefined && container !== null) {
    return container[key];
  } else {
    return <Spin />;
  }
};

const CustomerDetails = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [overviewCardsLoading, setOverviewCards] = useState(null);

  const [parsed] = useState(queryString.parse(window.location.search));

  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(
      postSearchCustomer(parsed['id'], response => {
        setData(response.customers[0]);
        setRecordData(response.customers[0])
      }),
    );

    dispatch(
      postCustomerDetailCard(1, parsed['id'], response => {
        setOverviewCards({
          myBrands: response.number_of_brands,
        });
      }),
    );
  }, [dispatch, parsed]);

  const { customer } = useSelector(state => {
    return {
      customer: state.customerDetailReducer.customer,
    };
  });

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  const [showModalsCard] = useState(
    level.user.is_superuser || level.user_groups[0] === 'seomaster'
      ? ['newClient', 'newBrand', 'newUser']
      : ['newBrand', 'newUser'],
  );

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
   * Modal:
   *  - Success Modals
   */
  const [successShow, setSuccessShow] = useState(false);
  const [successItem, setSuccessItem] = useState();

  const [recordData, setRecordData] = useState(null)

  const showSuccessModal = () => {
    setSuccessShow(true);
  };

  const [idSuccess, setIdSuccess] = useState(null);

  /**
   * Modal
   */
  const [isModalVisibleSpecialist, setIsModalVisibleSpecialist] = useState(false);

  const showModalSpecialist = () => {
    setIsModalVisibleSpecialist(true);
  };

  const [visible, setVisible] = useState(false);
  const [drawerName, setDrawerName] = useState();

  const showDrawer = name => {
    if ((level.user.is_superuser || level.user_groups[0] === 'seomaster') && name === 'newUser') {
      showModalSpecialist();
    } else if (name === 'newUser') {
      setDrawerName('newClientUser');
      setVisible(true);
    } else {
      setDrawerName(name);
      setVisible(true);
    }
  };

  const onClose = () => {
    // console.log('Close');
    setVisible(false);
  };

  return (
    <div className="dtx-l-main">
      <div
        className="dtx-c-icon mb-1-5"
        onClick={() => {
          history.goBack();
        }}
      >
        <FeatherIcon icon="arrow-left" className="m-secondary" />
        <p className="label1 m-secondary">Back</p>
      </div>
      <div className="dtx-l-header">
        <div className="dtx-c-header-info">
          <h4>{checkLoading(customer, 'name')}</h4>
          <p className="span1 m-lightgrey">
            Created by {moment(checkLoading(customer, 'created_at')).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="dtx-c-header-action">
          <Button
            className="dtx-c-btn btn__small dtx-c-btn__secondary"
            onClick={() => {
              // console.log(test);
              setDrawerName('updateCustomer');
              showDrawer('updateCustomer');
            }}
          >
            <FeatherIcon icon="settings" className="m-primary" size={14} />
            Edit Customer
          </Button>
          <Button className="dtx-c-btn btn__small dtx-c-btn__solid" onClick={showModal}>
            Add New
          </Button>
        </div>
      </div>
      <Row className="mb-3-5">
        <div className="dtx-c-card-box">
          <h6 className="m-white">Overview</h6>
          <div className="dtx-c-card__user">
            <p className="label2">Assigned Specialist:</p>
            <div className="dtx-c-tag-row">
              {customer !== undefined &&
                customer.users !== undefined &&
                customer.users.map(SeoUser => {
                  return (
                    <div className="dtx-c-tag back-b-grey3">
                      <p className="span3">{SeoUser.first_name + ' ' + SeoUser.last_name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="dtx-c-card-column">
            <CardItems
              type={'brands'}
              values={overviewCardsLoading !== null && overviewCardsLoading.myBrands}
              loading={overviewCardsLoading !== null ? false : true}
            />
            <CardCustomerItems type={'solutions'} values={0} loading={overviewCardsLoading} />
          </div>
        </div>
        <AllUsersBrands id={parsed['id']} />
      </Row>
      <Row>
        <AllBrandsTable id={parsed['id']} />
      </Row>

      <IndexModals
        title="Add New Element"
        containTitle="Select which element you want to add"
        listShowModals={showModalsCard}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <IndexModalsMaster
        listShowModals={['newClientUser', 'newSpecialistUser', 'newMasterUser']}
        isModalVisibleSpecialist={isModalVisibleSpecialist}
        setIsModalVisibleSpecialist={setIsModalVisibleSpecialist}
        showModalSpecialist={showModalSpecialist}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={showModal}
        showDrawer={showDrawer}
      />

      <SuccessModals
        successShow={successShow}
        setSuccessShow={setSuccessShow}
        idSuccess={idSuccess}
        successItem={successItem}
      />

      <Drawers
        drawerName={drawerName}
        visible={visible}
        onClose={onClose}
        setSuccessShow={setSuccessShow}
        setIdSuccess={setIdSuccess}
        setSuccessItem={setSuccessItem}
        idCustomer={parsed['id']}
        record={recordData}
      />
    </div>
  );
};

export default CustomerDetails;
