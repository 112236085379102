import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data'
  },
});

axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error);

    return Promise.reject(error);
  },
);
