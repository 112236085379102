import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Switch, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { } from '../../../redux/users/actionCreator';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import TrendSonarConfigurationEdit from '../../modals/TrendSonarConfigurationEdit';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import FeatherIcon from 'feather-icons-react';
import { postDeleteSolution, updateService, updateVisibility } from '../../../redux/solutions/actionCreator';
import { Link } from 'react-router-dom';
import TrendSonarConfiguration from '../../modals/TrendSonarConfiguration';
import SuspendedSolutionConfirmModal from '../../modals/SuspendedSolutionConfirmModal';
import { showConfirm } from '../../../utility/utility';
import { postUpdateService } from '../../../server/Endpoints';
import { setUpdating } from '../../../redux/themeLayout/actionCreator';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

{
  /* <div className="dtx-c-table-subrow">
<p className="span3">Customer Name: </p>
{getUser(record.users)}
</div> */
}

const getTag = status => {
  // console.log(status);

  let response = [];

  switch (status) {
    case 0:
      response.push(
        <div key='0' className="dtx-c-tag back-b-grey3" >
          <p className="span3">Draft</p>
        </div >,
      );
      break;
    case 1:
      response.push(
        <div key='1' className="dtx-c-tag back-b-grey3">
          <p className="span3">Need Approval</p>
        </div>,
      );
      break;
    case 2:
      response.push(
        <div key='2' className="dtx-c-tag back-b-grey3">
          <p className="span3">Under Activation</p>
        </div>,
      );
      break;
    case 3:
      response.push(
        <div key='3' className="dtx-c-tag back-b-grey3">
          <p className="span3">Active</p>
        </div>,
      );
      break;
    default:
      <div></div>;
  }

  // console.log(response);

  return response;
};

const SolutionTable = ({
  brandID,
  listRowID,
  brandName,
  currentTab,
  data,
  nSolution,
  currentPage,
  setCurrentPage,
  filters,
  setFilters,
  loading,
}) => {
  const dispatch = useDispatch();

 

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  const [stateCheck, setStateCheck] = useState({});

  /**
   * Modal Deleting
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  /**
 * Modal Suspended
 */
  const [isSupendedModalVisible, setSupendedModalVisible] = useState(false);
  const [formSuspended, setFormSuspended] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };

  const [record, setRecord] = useState();

  /**
   * Modal
   */

  const [configurationEditModal, setConfigurationEditModal] = useState(false);

  const showConfigurationEditModal = () => {
    setConfigurationEditModal(true);
  };

  // console.log(data);

  useEffect(() => {
    // console.log('currentTab: ', currentTab);

    let newStateCheck = {};

    if (currentTab === '3' || currentTab === 3) {
      console.log('Entro', data);

      data.map(item => {
        // console.log('Item: ', item);
        newStateCheck[item.id] = item.is_visible;
      });
    } else {
      newStateCheck = {};
    }

    // console.log(newStateCheck)
    setStateCheck(newStateCheck);
  }, [data]);


  const columns = {
    0: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Creation Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => moment(value).format('DD/MM/YYYY'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (values, record) => {
          return (
            <div className="dtx-c-table__action">
              <div
                className="dtx-c-btn__icon"
                onClick={() => {
                  setRecord(record);
                  // setIdSuccess(record.id)
                  showConfigurationEditModal(true);
                }}
              >
                <FeatherIcon icon={'settings'} className="m-extralightgrey" />
              </div>
              <div className="dtx-c-btn__icon s-error" onClick={() => showModal({ service_id: record.id })}>
                <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
              </div>
            </div>
          );
        },
      },
    ],
    1: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Creation Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => moment(value).format('DD/MM/YYYY'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (value, record) => {
          return (
            <div className="dtx-c-table__action">
              {level.user.is_superuser || level.user_groups[0] === 'seomaster' ? (
                <div className="approve-section">
                  <Button
                    className="dtx-c-btn btn__small dtx-c-btn__solid"
                    onClick={() => {

                      postUpdateService({
                        service_id: record.id,
                        status: 2,
                      }, `/service/update_service_status_or_visibility/`, (response) => {
                        message.success(`Solution successfully approved`, 5);

                        dispatch(setUpdating(false));

                      }, (response) => {
                        message.error(response.data.error, 30)
                        dispatch(setUpdating(false));

                      })

                      // dispatch(
                      //   // updateService({
                      //   //   service_id: record.id,
                      //   //   name: record.name,
                      //   //   status: 2,
                      //   //   service_type: record.service_type,
                      //   //   brand: record.brand.id,
                      //   //   start_date: record.start_date,
                      //   //   end_date: record.end_date,
                      //   // }),
                      //   updateService({
                      //     service_id: record.id,
                      //     status: 2,
                      //   }),
                      // );

                      // console.log(record);
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    className="dtx-c-btn btn__small dtx-c-btn__outline"
                    onClick={() => {
                      dispatch(
                        // updateService({
                        //   service_id: record.id,
                        //   name: record.name,
                        //   status: 0,
                        //   service_type: record.service_type,
                        //   brand: record.brand.id,
                        //   start_date: record.start_date,
                        //   end_date: record.end_date,
                        // }),
                        updateService({
                          service_id: record.id,
                          status: 0,
                        }),
                      );

                      // console.log(record);
                    }}
                  >
                    Decline
                  </Button>
                </div>
              ) : (
                <div></div>
              )}

              <div
                className="dtx-c-btn__icon m-grey"
                onClick={() => {
                  setRecord(record);
                  // setIdSuccess(record.id)
                  showConfigurationEditModal(true);
                }}
              >
                <FeatherIcon icon={'info'} className="m-extralightgrey" />
              </div>
            </div>
          );
        },
      },
    ],
    2: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Creation Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => moment(value).format('DD/MM/YYYY'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (value, record) => {
          return (
            <div className="dtx-c-table__action">
              <div
                className="dtx-c-btn__icon m-grey"
                onClick={() => {
                  setRecord(record);
                  // setIdSuccess(record.id)
                  showConfigurationEditModal(true);
                }}
              >
                <FeatherIcon icon={'info'} className="m-extralightgrey" />
              </div>
            </div>
          );
        },
      },
    ],
    3: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
        render: (value, record) => (
          <Link
            to={{
              pathname: '/solutions/detail',
              search: `?id=${record.id}`,
              state: {
                location: {
                  link: `/brands/detail?id=${brandID}`,
                  title: brandName,
                },
              },
            }}
            className="m-secondary"
          >
            {value}
          </Link>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,

      },
      {
        title: 'Creation Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => moment(value).format('DD/MM/YYYY'),
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: value => getTag(value),
      },
      {
        title: 'Client Visibility',
        dataIndex: 'is_visible',
        key: 'is_visible',
        render: (values, record) => (
          <Switch
            // defaultChecked={values}
            checked={stateCheck[record.id]}
            checkedChildren="Visible"
            unCheckedChildren="Invisible"
            onChange={value => {
              if (value) {
                showConfirm('Solution Visualization', `You are trying to set up the solution to "visibile".
                The Customer User will receive an email and could access to the platform and see the solution. Do you want to proceed?`, condition => {
                  if (condition) {
                    setStateCheck({
                      ...stateCheck,
                      [record.id]: value
                    });

                    dispatch(
                      updateVisibility(
                        {
                          service_id: record.id,
                          is_visible: value,
                        },
                        response => {
                          // console.log(response);
                        },
                      ),
                    );
                  } else {

                  }
                });
              } else {

                setStateCheck(value)

                dispatch(
                  updateVisibility(
                    {
                      service_id: record.id,
                      is_visible: value,
                    },
                    response => {
                      // console.log(response);
                    },
                  ),
                );
              }
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (value, record) => {
          return (
            <div className="dtx-c-table__action">
              {record.is_visible ? (
                <div className="dtx-c-btn__icon m-grey">
                  <FeatherIcon icon={'info'} className="m-extralightgrey" onClick={() => {
                    setRecord(record);
                    // setIdSuccess(record.id)
                    showConfigurationEditModal(true);
                  }} />
                </div>
              ) : (
                <div className="dtx-c-btn__icon m-grey">
                  <FeatherIcon icon={'pause-circle'} className="m-extralightgrey" onClick={() => {
                    // console.log(record)
                    setFormSuspended({
                      "service_id": record.id,
                      "status": 0,
                    })

                    setSupendedModalVisible(true)

                  }} />
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  /**
   * Funtion in progress
   * @param {*} checked
   */
  function onChange(checked) {
    // console.log(`switch to ${checked}`);
  }

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    if (pagination.current !== currentPage) {
      // setStatusTable(true);
      setCurrentPage(pagination.current);
    }

    if (sorter !== undefined) {
      setFilters({
        ...filters,
        order_service_name: '',
        order_number_users: '',
        order_service_created_at: '',
      });

      if (sorter.length === undefined && sorter.field !== 'date_joined') {
        let field = sorter.field;
        let order = sorter.order;

        if (order !== undefined) {
          setFilters({
            ...filters,
            [`order_service_${field}`]: order === 'ascend' ? 1 : 2,
          });
        } else {
          setFilters({
            ...filters,
            [`order_service_${field}`]: '',
          });
        }
      } else if (sorter.field === 'date_joined') {
        let field = sorter.field;
        let order = sorter.order;

        if (order !== undefined) {
          setFilters({
            ...filters,
            [`order_service_created_at`]: order === 'ascend' ? 1 : 2,
          });
        } else {
          setFilters({
            ...filters,
            [`order_service_created_at`]: '',
          });
        }
      }
    }
  };

  // console.log('currentTab: ===>', data)

  return (
    <div className="dtx-c-table__composed">
      <Table
        columns={columns[currentTab]}
        dataSource={data}
        pagination={{ position: ['bottomCenter'], pageSize: 10, total: nSolution }}
        loading={loading}
        rowKey={record => record.id}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: record => {
            return record.service_config.length > 0 && record.service_config[0].keyword_mapping_name !== undefined ? (
              <div key={record.id} className="dtx-c-table-subrow">
                <span className="span3">Data:</span> <div className="dtx-c-tag back-b-grey3">
                  <span className="span3">{record.service_config[0].keyword_mapping_name}</span></div>
              </div>) : <div />
          }
          ,
          expandedRowKeys: listRowID,
        }}
        defaultExpandAllRows={true}
        showExpandColumn={true}
        expandIconAsCell={true}
        expandIconColumnIndex={-1}
      />

      <TrendSonarConfigurationEdit
        configurationEditModal={configurationEditModal}
        setConfigurationEditModal={setConfigurationEditModal}
        record={record}
        disabledModal={record !== undefined && record.status !== 0 && record.status !== -1 ? true : false}
      />

      <DeleteConfirmModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        func={postDeleteSolution}
        form={formDelete}
      />

      <SuspendedSolutionConfirmModal
        isModalVisible={isSupendedModalVisible}
        setIsModalVisible={setSupendedModalVisible}
        func={updateService}
        form={formSuspended}
      />
    </div>
  );
};

export default SolutionTable;
