import React, { useEffect, useState } from 'react';
import { Menu, Skeleton } from 'antd';
import { NavLink, useRouteMatch, useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandList, getBrandListSidebar } from '../redux/brands/actionCreator';
import { searchSolutionsTest } from '../redux/solutions/actionCreator';
import { MenuStyle } from './style.js';
const queryString = require('query-string');

const { SubMenu } = Menu;

const SolutionItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  const [selectName, setSelectName] = useState(window.location.pathname.split('/')[1]);

  const [openKeys, setOpenKeys] = React.useState(!topMenu);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  // const { userId } = useSelector(state => {
  //   return {
  //     userId: state.user.level.user.id,
  //   };
  // });

  const [brandCollection, setBrandCollection] = useState([]);
  const [solutionCollection, setSolutionCollection] = useState([]);
  const [isBrandLoading, setIsBrandLoading] = useState(true);
  const [isSolutionLoading, setIsSolutionLoading] = useState(true);

  let parsed = queryString.parse(window.location.search);

  useEffect(() => {
    setSelectName(window.location.pathname.split('/')[1]);
  }, []);

  useEffect(() => {
    // console.log(level);
    if (!level.user.is_superuser && level.user_groups[0] === 'client') {
      dispatch(
        getBrandListSidebar(level.user.id, value => {
          // console.log(value);

          setIsBrandLoading(false);
          setBrandCollection(value);
        }),
      );
      // dispatch(
      //   searchSolutionsTest(value => {
      //     console.log(value);

      //     setSolutionCollection(value);
      //     setIsSolutionLoading(false);
      //   }),
      // );
    }
  }, []);

  const getSolutions = item => {
    // console.log(item);

    let solution_list = [];

    item.active_solutions.map(solution => {
      // console.log(solution);

      solution_list.push(
        <Menu.Item
          className={
            window.location.pathname === '/solutions/detail' ? 'dtx-c-sidebar-item' : 'dtx-c-sidebar-item false'
          }
          key={'sub1-' + item.id + '-' + solution.id}
          title={solution.name}
        >
          <NavLink
            onClick={toggleCollapsed}
            className="dtx-c-nav-link"
            to={{
              pathname: '/solutions/detail',
              search: `?id=${solution.id}`,
              state: {
                location: {
                  link: `/solutions/detail?id=${solution.id}`,
                  // title: solution.service_type + ' - ' + solution.name,
                },
              },
            }}
          >
            <p className="span1">{solution.service_type__name + ' - ' + solution.name}</p>
          </NavLink>
        </Menu.Item>,
      );
    });

    // console.log(solution_list)

    // {!isSolutionLoading ? (
    //   solutionCollection.map(solution => {
    //     if (solution.brand.customer.id === userId && solution.status === 3 && solution.is_visible)
    //     //0 => draft, 3 => active
    //     {
    //       return (
    //         <Menu.Item
    //           className={window.location.pathname === "/solutions/detail" ? "dtx-c-sidebar-item" : "dtx-c-sidebar-item false"}
    //           key={'sub1-' + item.id + '-' + solution.id}
    //           title={solution.name}
    //         >
    //           <NavLink
    //             onClick={toggleCollapsed}
    //             className="menu__nav-link"
    //             to={{
    //               pathname: '/solutions/detail',
    //               search: `?id=${solution.id}`,
    //               state: {
    //                 location: {
    //                   link: `/solutions/detail?id=${solution.id}`,
    //                   // title: solution.service_type + ' - ' + solution.name,
    //                 },
    //               },
    //             }}                                >
    //             <p className="span1">{solution.service_type.name + ' - ' + solution.name}</p>
    //           </NavLink>
    //         </Menu.Item>
    //       );
    //     }
    //   })
    // ) : (
    //   <Skeleton className="sub-menu-2" active paragraph={{ rows: 0 }} />
    // )}

    return solution_list;
  };

  return (
    <>
      {!level.user.is_superuser && level.user_groups[0] === 'client' && (
        <MenuStyle>
          {/* <p className="dtx-c-sidebar-title">SOLUTIONS</p> */}
          <Menu
            onOpenChange={onOpenChange}
            onClick={onClick}
            mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
            theme={darkMode && 'dark'}
            overflowedIndicator={<FeatherIcon icon="more-vertical" />}
            className="dtx-c-sidebar-list"
          >
            <SubMenu
              key="sub2"
              // title="TOFU"
              title={<p className="span1">Solutions</p>}
              className="dtx-c-sidebar-item"
              icon={!topMenu && <FeatherIcon icon="box" />}
            >
              {!isBrandLoading ? (
                brandCollection.map(item => {
                  // console.log(item);

                  if (item !== undefined && item.active_solutions.length > 0) {
                    return (
                      <SubMenu
                        key={'sub1-' + item.id}
                        className="dtx-c-sidebar-item-sub"
                        title={<p className="span1">{item.name}</p>}
                      >
                        {getSolutions(item)}
                      </SubMenu>
                    );
                  }
                })
              ) : (
                <>
                  <Skeleton className="sub-menu-1" active paragraph={{ rows: 0 }} />
                  <Skeleton className="sub-menu-2" active paragraph={{ rows: 0 }} />
                  <Skeleton className="sub-menu-1" active paragraph={{ rows: 0 }} />
                  <Skeleton className="sub-menu-2" active paragraph={{ rows: 0 }} />
                  <Skeleton className="sub-menu-2" active paragraph={{ rows: 0 }} />
                </>
              )}
            </SubMenu>
          </Menu>
        </MenuStyle>
      )}
    </>
  );
};

SolutionItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default SolutionItems;
