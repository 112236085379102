import { Skeleton } from 'antd';
import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const HorizontalBarChart = ({ response, title, labels }) => {
  // console.log(response);

  let { datasets, options } = {
    options: {
      responsive: true,

      legend: {
        display: true,
        position: 'bottom',
      },
      labels: {
        display: false,
      },
      title: {
        display: false,
        text: 'High Volume Constant Signal',
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: '#e5e9f2',
            },
            ticks: {
              beginAtZero: true,
              fontSize: 10,
              fontColor: '#182b49',
            },
          },
        ],
      },
    },

    datasets: [
      {
        data: response,
        label: 'Average Search Volume',
        borderColor: '#61BAFF',
        backgroundColor: '#53a2fc',
        barPercentage: 0.6,
      },
    ],
  };
  const data = {
    datasets,
    labels,
  };

  return (
    <div className="dtx-c-plot-content">
      <div className="dtx-c-plot-header">
        <h6>{title}</h6>
      </div>
      {response !== false ? (
        <div className="dtx-c-plot-body">
          <HorizontalBar data={data} options={options} height={200} />
        </div>
      ) : (
        <div className="dtx-c-plot-body">
          <Skeleton active/>
        </div>
      )}
    </div>
  );
};

export default HorizontalBarChart;
