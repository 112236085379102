const actions = {
  SEARCH_CUSTOMERS : 'SEARCH_CUSTOMERS',
  LOADING_CUSTOMERS : 'LOADING_CUSTOMERS',
  SET_FILTERS : 'SET_FILTERS',
  GET_CUSTOMER_CARD: 'GET_CUSTOMER_CARD',
  GET_LOADING_CUSTOMER_CARD: 'GET_LOADING_CUSTOMER_CARD',

  searchCustomers: (data) => {
    return {
      type: actions.SEARCH_CUSTOMERS,
      data
    };
  },

  loading: (data) => {
    return {
      type: actions.LOADING_CUSTOMERS,
      data
    };
  },

  setFilters: (data) => {
    return {
      type: actions.SET_FILTERS,
      data
    };
  },

  customerCard: (data) => {
    return {
      type: actions.GET_CUSTOMER_CARD,
      data
    };
  },

  loadingCustomerCard: (data) => {
    return {
      type: actions.GET_LOADING_CUSTOMER_CARD,
      data
    };
  },
};

export default actions;
