import React, { useEffect, useState } from 'react';
import { Spin, Table, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { postDeleteBrand, postSearchLastBrands } from '../../../redux/brands/actionCreator';
import moment from 'moment';
import Drawers from '../../drawer';
import { searchUsersTag } from '../../../redux/users/actionCreator';
import { Link, NavLink } from 'react-router-dom';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import { getCustomerList3 } from '../../../redux/customers/actionCreator';

const LastBrands = ({ id }) => {
  const dispatch = useDispatch();

  const [recordUpdateBrand, setRecordUpdateBrand] = useState();
  const [visible, setVisible] = useState(false);

  /**
   * Variable for the Table:
   *  - dataTable: the records show in the table
   *  - loadingTable: use for show the loading function for all calls
   *  - listRowID: use for default open expanded row
   *  - columns: list of the table columns
   */
  const [dataTable, setDataTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listCustomersName, setListCustomersName] = useState(null);
  const [listRowID, setListRowID] = useState([]);
  const columns = [
    {
      title: 'Brand Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (value, record) => (
        <Link to={`/brands/detail?id=${record.id}`} className="m-secondary">
          {value}
        </Link>
      ),
    },
    { title: 'Active Solutions', dataIndex: 'number_active_solutions', key: 'number_active_solutions', width: '20%' },
    { title: 'Users', dataIndex: 'number_users', key: 'number_users', width: '10%' },
    {
      title: 'Creation Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',

      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Last Edit',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '20%',
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '10%',

      render: (value, record) => (
        <div className="dtx-c-table__action">
          <div
            className="dtx-c-btn__icon"
            onClick={() => {
              setRecordUpdateBrand(record);
              showDrawer();
            }}
          >
            <FeatherIcon icon={'edit'} className="m-extralightgrey" />
          </div>
          {record.number_active_solutions === 0 && (
            <div
              className="dtx-c-btn__icon s-error"
              onClick={() => {
                showModal({ brand_id: record.id });
              }}
            >
              <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
            </div>
          )}
        </div>
      ),
    },
  ];

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };

  const { usersTags, updating } = useSelector(state => {
    return {
      usersTags: state.usersReducer.usersTags,
      updating: state.ChangeLayoutMode,
    };
  });

  /**
   * Setup Function Drawer
   */
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  /**
   * UseEffect
   */
  useEffect(() => {
    setLoadingTable(true);

    dispatch(
      postSearchLastBrands(brands => {
        let listRowID = [];

        brands.map(brand => {
          listRowID.push(brand.id);
        });

        setListRowID(listRowID);
        setDataTable(brands);

        setLoadingTable(false);
      }),
    );

    dispatch(
      getCustomerList3(response => {
        // console.log(response);

        let newCustomerList = {};

        response.map(customer => {
          newCustomerList[customer.id] = customer.name;
        });

        setListCustomersName(newCustomerList);
      }),
    );
  }, [dispatch, updating]);

  /**
   *
   * @param {*} users Variable contains the list of tag for single brand (row)
   * @returns generate the HTML structure for show the tags under the brand row
   */
  const getUser = users => {
    let tags = [];

    tags.push(
      users.map(user => (
        <div key={user.id} className="dtx-c-tag back-b-grey3">
          <p className="span3">{user.first_name + ' ' + user.last_name}</p>
        </div>
      )),
    );

    return tags;
  };

  /**
   * Return of the component (function component)
   */
  return (
    <div className="dtx-c-table__composed">
      <Table
        columns={columns}
        dataSource={dataTable}
        // pagination={{ position: ['bottomCenter'], pageSize: 5, total: 5 }}
        pagination={false}
        loading={loadingTable}
        rowKey={record => record.id}
        expandable={{
          expandedRowRender: record => (
            <div className="dtx-c-table-subrow">
              <p className="span3">Customer Name: </p>
              {/* {getUser(record.users)} */}
              {listCustomersName !== null ? (<NavLink to={`/clients/detail?id=${record.customer}`} className="m-grey">
                                <div className="dtx-c-tag back-b-grey3">
                  <p className="span3">{listCustomersName[record.customer]}</p>
                </div>
              </NavLink>

              ) : (
                <Spin />
              )}
            </div>
          ),
          expandedRowKeys: listRowID,
        }}
        defaultExpandAllRows={true}
        showExpandColumn={true}
        expandIconAsCell={true}
        expandIconColumnIndex={-1}
      />
      <Drawers drawerName={'updateBrand'} visible={visible} onClose={onClose} id={id} record={recordUpdateBrand} />
      <DeleteConfirmModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        func={postDeleteBrand}
        form={formDelete}
      />
    </div>
  );
};

export default LastBrands;
