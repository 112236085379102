import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, Redirect, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();

  const [selectName, setSelectName] = useState(window.location.pathname.split('/')[1]);

  const [openKeys, setOpenKeys] = React.useState(!topMenu);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const { level } = useSelector(state => {
    return {
      level: state.user.level,
    };
  });

  useEffect(() => {
    setSelectName(window.location.pathname.split('/')[1]);
  }, []);

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={selectName === '' ? 'home' : selectName}
      defaultOpenKeys={!topMenu}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      className="dtx-c-sidebar-list"
    >
      <Menu.Item
        className={window.location.pathname === '/' ? 'dtx-c-sidebar-item' : 'dtx-c-sidebar-item false'}
        key="home"
        title="Homepage"
      >
        <NavLink className="dtx-c-nav-link" onClick={toggleCollapsed} to={`${path}`}>
          <FeatherIcon icon="home" />
          <p className="span1">Homepage</p>
        </NavLink>
      </Menu.Item>

      {
        level !== undefined && level !== null && level.user.is_superuser || level.user_groups[0] === 'seouser' || level.user_groups[0] === 'seomaster' ? (
          <Menu.Item
            className="dtx-c-sidebar-item"
            key="clients"
            title="Clients"
          >
            <NavLink className="dtx-c-nav-link" onClick={toggleCollapsed} to={`/clients`}>
              <FeatherIcon icon="briefcase" />
              <p className="span1">Customers</p>
            </NavLink>
          </Menu.Item>
        ) : (
          <></>
        )}

      {
        level !== undefined && level !== null && level.user.is_superuser || level.user_groups[0] === 'seouser' || level.user_groups[0] === 'seomaster' ? (
          <Menu.Item
            className="dtx-c-sidebar-item"
            key="users"
            title="Users"
          >
            <NavLink className="dtx-c-nav-link" onClick={toggleCollapsed} to={`/users`}>
              <FeatherIcon icon="users" />
              <p className="span1">Users</p>
            </NavLink>
          </Menu.Item>
        ) : (
          <> </>
        )}

      {
        level !== undefined && level !== null && level.user.is_superuser ? (
          <Menu.Item
            className="dtx-c-sidebar-item"
            key="solutions"
            title="Solutions"
          >
            <NavLink className="dtx-c-nav-link" onClick={toggleCollapsed} to={`/solutions`}>
              <FeatherIcon icon="bar-chart-2" />
              <p className="span1">Solutions</p>
            </NavLink>
          </Menu.Item>
        ) : (
          <> </>
        )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
