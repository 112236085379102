import actions from './actions';

const { SEARCH_CUSTOMERS, LOADING_CUSTOMERS, SET_FILTERS, GET_CUSTOMER_CARD, GET_LOADING_CUSTOMER_CARD } = actions;

const initState = {
  customers: [],
  nCustomers: null,
  filters: {
    filter_customer_name: '',
    order_customer_name: '',
    order_number_brands: '',
    order_number_projects: '',
    order_customer_created_at: '',
  },
  loading: false,
  customerCard: null,
  loadingCustomerCard: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const customersReducer = (state = initState, action) => {
  const { type, data, /** err */ } = action;

  switch (type) {
    case SEARCH_CUSTOMERS:
      return {
        ...state,
        customers: data,
      };
    case LOADING_CUSTOMERS:
      return {
        ...state,
        loading: data,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: data,
      };
    case GET_CUSTOMER_CARD:
      return {
        ...state,
        customerCard: data,
      };
    case GET_LOADING_CUSTOMER_CARD:
      return {
        ...state,
        loadingCustomerCard: data,
      };
    default:
      return state;
  }
};
export default customersReducer;
