import React, { useEffect, useState } from 'react';
import { Table, Form, Input, Select, Switch, Button, Tooltip, Modal } from 'antd';
import FontAwesome from 'react-fontawesome';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActiveAction,
  editSearchUsersFilters,
  initSearchUsersFilters,
  postDeleteUser,
  postSearchUsers,
} from '../../../redux/users/actionCreator';
import moment from 'moment';
import Drawers from '../../drawer';
import DeleteConfirmModal from '../../modals/DeleteConfirmModal';
import { getCustomerList, getCustomerList3 } from '../../../redux/customers/actionCreator';
import { nameChange } from '../../../constants/nameType';
import { NavLink } from 'react-router-dom';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const getUserIcon = type => {
  // console.log(type)

  switch (type) {
    case 'seouser':
      return (
        <div className="dtx-c-icon">
          <FeatherIcon icon="user" />
          <p className="span2 m-grey">SEO User</p>
        </div>
      );
      break;
    case 'seomaster':
      return (
        <div className="dtx-c-icon">
          <FeatherIcon icon="user-check" />
          <p className="span2 m-grey">SEO Master</p>
        </div>
      );
      break;
    case 'client':
      return (
        <div className="dtx-c-icon">
          <FeatherIcon icon="briefcase" />
          <p className="span2 m-grey">Customer User</p>
        </div>
      );
      break;
    case undefined:
      return (
        <div className="dtx-c-icon">
          <FeatherIcon icon="shield" />
          <p className="span2 m-grey">Admin</p>
        </div>
      );
      break;
    default:
      return '';
  }
};

const UsersTable = ({ id }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  /**
   * Variable for the Table:
   *  - dataTable: the records show in the table
   *  - loadingTable: use for show the loading function for all calls
   *  - listRowID: use for default open expanded row
   *  - columns: list of the table columns
   */
  const [dataTable, setDataTable] = useState([]);
  const [dataTableNRow, setDataTableNRow] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listRowID, setListRowID] = useState([]);

  const [listCustomers, setListCustomers] = useState([]);

  /**
   * SET Use state variables
   */
  const [recordUpdateUser, setRecordUpdateUser] = useState();
  const [drawerName, setDrawerName] = useState();
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [newFilters, setNewFilter] = useState({
    filter_user_username: [],
    filter_user_first_name: [],
    filter_user_last_name: [],
    filter_user_email: [],
    filter_user_is_active: '',
    filter_user_typology: '',
    order_user_username: '',
    order_user_first_name: '',
    order_user_last_name: '',
    order_user_email: '',
    order_user_created_at: '',
  });

  const [newFiltersView, setNewFiltersView] = useState([]);

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const showModal = value => {
    setFormDelete(value);
    setIsModalVisible(true);
  };

  /**
   * Wrap the variables
   */
  const nameWrap = {
    filter_user_first_name: 'Name',
    filter_user_last_name: 'Surname',
    filter_user_email: 'Email',
  };

  /**
   * FROM REDUX
   */
  const { users, nUsers, loading, filters, usersTags, updating, level } = useSelector(state => {
    return {
      users: state.usersReducer.users,
      nUsers: state.usersReducer.nUsers,
      loading: state.usersReducer.loading,
      filters: state.usersReducer.filters,
      usersTags: state.usersReducer.usersTags,
      updating: state.ChangeLayoutMode,
      level: state.user.level,
    };
  });

  /**
   * Columns for SEO USER
   */
  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Surname',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Date',
      dataIndex: 'date_joined',
      key: 'date_joined',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
      render: value => moment(value).format('GG/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value, record) => (
        <Switch
          defaultChecked={value}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={value => onChange({ user_id: record.id, active: value })}
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (value, record) => (
        <div className="dtx-c-table__action">
          <div
            className="dtx-c-btn__icon"
            onClick={() => {
              setRecordUpdateUser(record);

              // console.log(record.user_groups[0]);

              switch (record.user_groups[0]) {
                case 'seouser':
                  showDrawer('updateSeoUser');
                  break;
                case 'seomaster':
                  showDrawer('updateMasterUser');
                  break;
                case 'client':
                  showDrawer('updateClientUser');
                  break;
                default:
              }
            }}
          >
            <FeatherIcon icon={'edit'} className="m-extralightgrey" />
          </div>
          {/* <div className="dtx-c-btn__icon s-error" onClick={() => dispatch(postDeleteUser({ user_id: record.id }))}>
            <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
          </div> */}
        </div>
      ),
    },
  ];

  /**
   * Columns for SEO MASTER
   */
  const columns_masters = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Surname',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
    },
    {
      title: 'Typology',
      dataIndex: 'type',
      key: 'type',
      render: (value, record) => getUserIcon(record.user_groups[0]),
    },
    {
      title: 'Date',
      dataIndex: 'date_joined',
      key: 'date_joined',
      sorter: {
        compare: (a, b) => a.name - b.name,
      },
      render: value => moment(value).format('GG/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value, record) => (
        <Switch
          defaultChecked={value}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={value => onChange({ user_id: record.id, active: value })}
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (value, record) => (
        <div className="dtx-c-table__action">
          <div
            className="dtx-c-btn__icon"
            onClick={() => {
              setRecordUpdateUser(record);

              // console.log(record);
              // console.log(record.user_groups[0]);

              switch (record.user_groups[0]) {
                case 'seouser':
                  showDrawer('updateSeoUser');
                  break;
                case 'seomaster':
                  showDrawer('updateMasterUser');
                  break;
                case 'client':
                  showDrawer('updateClientUser');
                  break;
                default:
              }
            }}
          >
            <FeatherIcon icon={'edit'} className="m-extralightgrey" />
          </div>
          <div className="dtx-c-btn__icon s-error" onClick={() => showModal({ user_id: record.id })}>
            <FeatherIcon icon={'trash-2'} className="m-extralightgrey" />
          </div>
        </div>
      ),
    },
  ];

  const getFiltersView = () => {
    let newView = [];

    for (let key in newFilters) {
      // console.log(key, ': ', newFilters[key]);

      if (newFilters[key] !== undefined && newFilters[key].length > 0 && newFilters[key] !== '') {
        let items = [];

        if (key !== 'filter_user_is_active' && key !== 'filter_user_typology') {
          newFilters[key].map(filter => {
            // console.log(filter)

            items.push(
              <div className="dtx-c-filter-value" key={key}>
                <p className="label2 m-dark mr-0-25">{filter}</p>
                <FeatherIcon
                  icon="x"
                  className="s-error"
                  size={15}
                  onClick={() => {
                    // console.log(key);

                    let newFilterArray = {
                      ...newFilters,
                      [key]: newFilters[key].filter(el => el !== filter),
                    };
                    setDataTable([]);
                    // console.log(newFilterArray);
                    setNewFilter(newFilterArray);
                  }}
                />
              </div>,
            );
          });

          newView.push([
            <div key={key} className="dtx-c-filter-item">
              <p className="span3 m-lightgrey mr-0-25">{nameChange[key] + ': '}</p>
              {items}
            </div>,
          ]);
        } else if (key === 'filter_user_is_active'){
          newView.push([
            <div key={key} className="dtx-c-filter-item">
              <p className="span3 m-lightgrey mr-0-25">{nameChange[key] + ': '}</p>
              {newFilters[key] === '1' ? 'Active' : 'inactive'}
              <FeatherIcon
                icon="x"
                className="s-error"
                size={15}
                onClick={() => {
                  // console.log(key);

                  let newFilterArray = {
                    ...newFilters,
                    filter_user_is_active: '',
                  };

                  setDataTable([]);
                  // console.log(newFilterArray);
                  setNewFilter(newFilterArray);
                }}
              />
            </div>,
          ]);
        } else if (key === 'filter_user_typology'){
          newView.push([
            <div key={key} className="dtx-c-filter-item">
              <p className="span3 m-lightgrey mr-0-25">{nameChange[key] + ': '}</p>
              {newFilters[key]} 
              <FeatherIcon
                icon="x"
                className="s-error"
                size={15}
                onClick={() => {
                  // console.log(key);

                  let newFilterArray = {
                    ...newFilters,
                    filter_user_typology: '',
                  };

                  setDataTable([]);
                  // console.log(newFilterArray);
                  setNewFilter(newFilterArray);
                }}
              />
            </div>,
          ]);
        }
      }
    }

    if (newView.length > 0) {
      setNewFiltersView(
        <div className="dtx-c-filter-subrow">
          <p className="label2 m-lightgrey">Filtered By:</p>
          {newView}
          <NavLink
            to="#"
            className="label2 s-error"
            onClick={() => {
              setDataTable([]);
              setNewFilter({
                ...newFilters,
                filter_user_username: [],
                filter_user_first_name: [],
                filter_user_last_name: [],
                filter_user_email: [],
                filter_user_is_active: '',
                filter_user_typology: '',
              });
            }}
          >
            Clear
          </NavLink>
        </div>,
      );
    } else {
      setNewFiltersView(<div className="Subfilter">{newView}</div>);
    }
  };

  /**
   * Call for UserData
   */
  useEffect(() => {
    setLoadingTable(true);
    getFiltersView();
    dispatch(
      postSearchUsers({ ...newFilters, page: currentPage }, response => {
        let listRowID = [];
        response.users.map(user => {
          listRowID.push(user.id);
        });

        dispatch(
          getCustomerList3(responseCustomers => {
            // console.log(responseCustomers);
            setListCustomers(responseCustomers);

            setListRowID(listRowID);
            setDataTable(response.users);
            setDataTableNRow(response.number_of_users);
            setLoadingTable(false);
          }),
        );
      }),
    );
  }, [newFilters, currentPage, updating]);

  /**
   *
   * @param {*} changedValues
   * @param {*} allValues
   */
  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];

    // setNewFilters({
    //   ...newFilters,
    //   [`filter_user_${key}`]: changedValues[key],
    // })
  };

  /**
   * Setup Function Drawer
   */
  const showDrawer = value => {
    // console.log(value);
    setDrawerName(value);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    if (sorter !== undefined) {
      dispatch(initSearchUsersFilters());

      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        let newFilterArray = {
          ...newFilters,
          order_user_username: '',
          order_user_first_name: '',
          order_user_last_name: '',
          order_user_email: '',
          order_user_created_at: '',
        };

        if (order !== undefined) {
          if (field === 'date_joined') {
            field = 'created_at';
          }

          setNewFilter({
            ...newFilterArray,
            [`order_user_${field}`]: order === 'ascend' ? 1 : 2,
          });
        } else {
          if (field === 'date_joined') {
            field = 'created_at';
          }

          setNewFilter({
            ...newFilterArray,
            [`order_user_${field}`]: '',
          });
        }
      }
    }

    if (pagination.current !== currentPage) {
      // setStatusTable(true);
      setCurrentPage(pagination.current);
    }
  };

  /**
   * This Function is util for make an array of tag element
   * @param {*} users
   * @returns array of tags
   */
  const getUserTag = users => {
    let tags = [];

    // console.log(users.customer_ids);

    tags.push(
      users.customer_ids.map(user => (
        <div className="Tag">
          <span className="client-name">{listCustomers.find(x => x.id === user).name}</span>
        </div>
      )),
    );

    return tags;
  };

  /**
   * Funtion in progress
   * @param {*} checked
   */
  function onChange(checked) {
    // console.log(`switch to ${checked}`);

    dispatch(
      ActiveAction(checked, value => {
        // console.log(value);
      }),
    );
  }

  return (
    <div className="dtx-l-content">
      <div className="dtx-c-filter">
        <Form name="basic" form={form} layout="inline" onValuesChange={onValuesChange} {...layout}>
          <div className="dtx-c-filter-field">
            <div className="dtx-c-filter-icon">
              <FeatherIcon icon={'filter'} className="m-grey" />
            </div>
            <Form.Item className="dtx-c-field" name="first_name">
              <Input placeholder="Filter by Name" className="dtx-c-field__input" />
            </Form.Item>
            <Form.Item className="dtx-c-field" name="last_name">
              <Input placeholder="Filter by Surname" className="dtx-c-field__input" />
            </Form.Item>

            <Form.Item className="dtx-c-field" name="email">
              <Input placeholder="Filter by Email" className="dtx-c-field__input" />
            </Form.Item>
            {/* <Form.Item className="dtx-c-field" name="client_name">
              <Input placeholder="Filter by Customer Name" className="dtx-c-field__input"/>
            </Form.Item> */}
            
            <Form.Item className="dtx-c-field" name="typology">
              <Select placeholder="Filter by Typology" className="dtx-c-field__select">
                <Option value="client">Customer</Option>
                <Option value="seouser">Seo User</Option>
                <Option value="seomaster">Seo Master</Option>
              </Select>
            </Form.Item>

            <Form.Item className="dtx-c-field" name="is_active">
              <Select placeholder="Filter by Status" className="dtx-c-field__select">
                <Option value="1">active</Option>
                <Option value="0">inactive</Option>
              </Select>
            </Form.Item>

            <Form.Item className="" name="SUBMIT">
              <Button
                className="dtx-c-btn btn__small dtx-c-btn__solid"
                onClick={() => {
                  setDataTable([]);
                  let formFields = form.getFieldsValue();
                  let createForm = newFilters;

                  // console.log('FORMFIELD: ', formFields);
                  // console.log('newFILTERS: ', newFilters);
                  // console.log('CreateForm PRIMA: ', createForm);

                  for (let key in formFields) {
                    // console.log('KEY: ', key, ' ESISTE?: ', Array.isArray(createForm[`filter_user_${key}`]));
                    if (formFields[key] !== undefined && key !== 'is_active' && key !== 'typology') {
                      if (Array.isArray(createForm[`filter_user_${key}`]))
                        createForm[`filter_user_${key}`].push(formFields[key]);
                    } else if (key === 'is_active' || key === 'typology') {
                      createForm[`filter_user_${key}`] = formFields[key];
                    }
                  }

                  // console.log('CreateForm DOPO: ', createForm);

                  setNewFilter({ ...createForm });

                  form.resetFields();

                  // setLoadingTable(true)
                  // dispatch(
                  //   postSearchUsers({
                  //     ...newFilters,
                  //     page: currentPage
                  //   }, response => {
                  //     let listRowID = [];

                  //     response.users.map(user => {
                  //       listRowID.push(user.id);
                  //     });

                  //     setListRowID(listRowID);
                  //     setDataTable(response.users);
                  //     setDataTableNRow(response.number_of_users);
                  //     setLoadingTable(false)
                  //   }),
                  // )
                }}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      {newFiltersView}
      <div className="dtx-c-table">
        <div className="dtx-c-table-header">
          <h6>All Users</h6>
        </div>
        <div className="dtx-c-table-content">
          {/* <div className="dtx-c-table__composed"> */}
          <div>
            <Table
              columns={level.user.is_superuser || level.user_groups[0] === 'seomaster' ? columns_masters : columns}
              onChange={handleTableChange}
              dataSource={dataTable}
              loading={loadingTable}
              pagination={{ position: ['bottomCenter'], pageSize: 10, total: dataTableNRow }}
              // defaultExpandAllRows={true}
              // expandable={{
              //   expandedRowRender: record => (
              //     <div key={record.id} className="dtx-c-table-subrow">
              //       <p className="span3">Customer Name: </p>
              //       {record.users !== undefined ? getUserTag(record.users) : <div></div>}
              //     </div>
              //   ),
              //   expandedRowKeys: listRowID,
              // }}
              // expandIconAsCell={false}
              // expandIconColumnIndex={-1}
              // rowKey={record => record.id}
              // preserve={false}
            />
            <Drawers drawerName={drawerName} visible={visible} onClose={onClose} id={id} record={recordUpdateUser} />
            <DeleteConfirmModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              func={postDeleteUser}
              form={formDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
