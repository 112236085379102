import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { postUpdateBrand } from '../../../redux/brands/actionCreator';
import { getCustomerList } from '../../../redux/customers/actionCreator';
import { getSearchCustomer } from '../../../redux/customerDetails/actionCreator';
import { getClientUsers, getUsers } from '../../../redux/users/actionCreator';

const { Option } = Select;

const ModifyBrand = ({ visible, onClose, id, record, idCustomer }) => {
  // console.log('DrawerVisible: ', idCustomer);
  // console.log('DrawerVisible: ', record);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [listCustomer, setListCustomer] = useState([]);
  const [listClientUser, setListClientUser] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(
        getCustomerList(record.id, values => {
          // console.log(values);
          let newListCustomer = [];

          newListCustomer.push(values.map(customer => <Option value={customer.id}>{customer.name}</Option>));

          setListCustomer(newListCustomer);
        }),
      );

      dispatch(
        getClientUsers({ get_seousers: 'false', get_clientusers: 'true' }, values => {
          // console.log(values);
          let newListClientUser = [];

          newListClientUser.push(
            values.map(userClient => (
              <Option value={userClient.id}>{userClient.first_name + ' ' + userClient.last_name}</Option>
            )),
          );

          setListClientUser(newListClientUser);
        }),
      );
    }
  }, [visible, dispatch]);

  const onFinish = values => {
    setLoading(true);

    let payload = {
      ...values,
      brand_id: record.id,
    };

    dispatch(
      postUpdateBrand(payload, response => {
        // console.log(response)

        if (response.status === 200) {
          setLoading(false);
          onClose();
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getUsers = value => {
    let newUsers = [];

    value.map(user => {
      newUsers.push(user.id);
    });

    return newUsers;
  };

  const onUpdate = value => {
    const fields = form.getFieldsValue();

    let newForm = {};

    newForm = {
      ...fields,
      users: [],
    };

    form.setFieldsValue(newForm);
  };

  const onDrawerClose = values => {
    const fields = form.getFieldsValue();

    let newForm = {
      ...fields,
    };

    form.setFieldsValue(newForm);

    onClose();
  };

  // console.log(typeof record.users[0]);
  // console.log(typeof record.customer);

  return (
    <Drawer
      destroyOnClose={true}
      title={'Edit Brand'}
      width={500}
      placement="right"
      preserve={false}
      onClose={onDrawerClose}
      visible={visible}
      className="dtx-c-drawer"
    >
      <Form
        form={form}
        destroyOnClose={true}
        preserve={false}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          name: record !== undefined && record.name,
          website: record !== undefined && record.website,
          customer:
            record !== undefined && typeof record.customer !== 'number'
              ? record.customer.id
              : record !== undefined && typeof record.customer === 'number'
              ? record.customer
              : idCustomer !== undefined && idCustomer,
          users:
            record !== undefined && record.users.length > 0 && typeof record.users[0] !== 'number'
              ? getUsers(record.users)
              : record.users,
        }}
        setFieldsValue
        {...layout}
      >
        <div className="dtx-c-field">
          <p className="label2 m-grey">Brand Name</p>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="  e.g. L’Oréal" className="dtx-c-field__input" />
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <p className="label2 m-grey">Brand Website (optional):</p>
          <Form.Item
            name="website"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Input placeholder="  e.g. www.loreal.com/en" className="dtx-c-field__input" />
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <p className="label2 m-grey"> Select the Customer to whom to associate the Brand:</p>
          <Form.Item
            name="customer"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={onUpdate}
              placeholder="Select a Customer"
              className="dtx-c-field__select"
              disabled={listCustomer.length > 0 ? false : true}
            >
              {listCustomer}
            </Select>
          </Form.Item>
        </div>
        <div className="dtx-c-field">
          <p className="label2 m-grey"> Select the Customer User to associate the Brand with:</p>
          <Form.Item
            name="users"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select a Customer User"
              mode="multiple"
              disabled={listClientUser.length > 0 ? false : true}
              className="dtx-c-field__select"
            >
              {listClientUser}
            </Select>
          </Form.Item>
        </div>
        <Form.Item>
          <Button className="dtx-c-btn btn__large dtx-c-btn__solid" htmlType="submit">
            {loading ? <span className="Label">Loading...</span> : <span>Save Changes</span>}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ModifyBrand;
