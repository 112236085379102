import { Modal } from "antd";

const { confirm } = Modal;


/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

var format_number = function (num, to_fixed) {
  if (num !== null && num !== undefined) {
    let options = {};
    if (to_fixed !== undefined) options["maximumFractionDigits"] = to_fixed;
    return num.toLocaleString("en-GB", options);
  } else {
    return 0;
  }
};

function showWarning(title, content) {
  Modal.warning({
    title: title,
    content: content,
  });
}

function showConfirm(title, content, callback) {
  confirm({
    title: title,
    // icon: <ExclamationCircleOutlined />,
    content: content,
    onOk() {
      callback(true)
    },
    onCancel() {
      callback(false)
    },
  });
}


export { ellipsis, format_number, showWarning, showConfirm };
