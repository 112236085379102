import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/users/actionCreator';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { updateService, updateServiceCallback } from '../../../redux/solutions/actionCreator';
import SuccessModals from '../../modals/SuccessModals';
import TrendSonarConfigurationEdit from '../../modals/TrendSonarConfigurationEdit';
import { showWarning } from '../../../utility/utility'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AllRequestsSolutions = ({
  listRowID,
  currentTab,
  data,
  nSolution,
  currentPage,
  setCurrentPage,
  filters,
  setFilters,
  loading,
}) => {
  const dispatch = useDispatch();

  /**
   * Modal
   */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDelete, setFormDelete] = useState({});

  const columns = {
    2: [
      {
        title: 'Solution Name',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Category',
        dataIndex: 'service_type',
        key: 'service_type',
        render: (value, record) => <div>{value.name}</div>,
      },
      {
        title: 'Client',
        dataIndex: 'customer_name',
        key: 'customer_name',
        render: (value, record) => <div>{record.brand.customer !== undefined && record.brand.customer.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand_name',
        render: (value, record) => <div>{record.brand.name}</div>,
        sorter: {
          compare: (a, b) => a.customer - b.customer,
        },
      },
      {
        title: 'Request Date',
        dataIndex: 'date_joined',
        key: 'date_joined',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
        render: value => moment(value).format('GG/MM/YYYY'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value, record) => (
          <Switch
            defaultChecked={false}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={value => {
              dispatch(
                // updateServiceCallback({
                //   service_id: record.id,
                //   name: record.name,
                //   status: 3,
                //   service_type: record.service_type,
                //   brand: record.brand.id,
                //   start_date: record.start_date,
                //   end_date: record.end_date,
                // }, (response) =>{
                //   showSuccessModal(true)
                // }),
                updateServiceCallback(
                  {
                    service_id: record.id,
                    status: 3,
                  },
                  response => {
                    showSuccessModal(true);
                  },
                ),
              );

              // console.log(record);
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (value, record) => (
            <div className="dtx-c-table__action">
              <div className="dtx-c-btn__icon m-grey">
                <FeatherIcon
                  icon={'info'}
                  className="m-extralightgrey"
                  onClick={() => {
                    setRecord(record);
                    // setIdSuccess(record.id)
                    showConfigurationEditModal(true);
                  }}
                />
              </div>
            </div>
        )
      },
    ],
  };

  /**
   * Modal
   */
  const [successShow, setSuccessShow] = useState(false);
  const [successItem, setSuccessItem] = useState('updateSolution');

  const showSuccessModal = () => {
    setSuccessShow(true);
  };

  /**
   * Funtion in progress
   * @param {*} checked
   */
  function onChange(checked) {
    // console.log(`switch to ${checked}`);
  }

  const [record, setRecord] = useState();

  /**
   * Modal
   */
  const [configurationEditModal, setConfigurationEditModal] = useState(false);

  const showConfigurationEditModal = () => {
    setConfigurationEditModal(true);
  };

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    if (pagination.current !== currentPage) {
      // setStatusTable(true);
      setCurrentPage(pagination.current);
    }

    if (sorter !== undefined) {
      setFilters({
        ...filters,
        order_service_name: '',
        order_number_users: '',
        order_service_created_at: '',
      });

      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        if (order !== undefined) {
          setFilters({
            ...filters,
            [`order_service_${field}`]: order === 'ascend' ? 1 : 2,
          });
        } else {
          setFilters({
            ...filters,
            [`order_service_${field}`]: '',
          });
        }
      }
    }
  };
  return (
    <div className="dtx-c-table__composed">
      <Table
        columns={columns[currentTab]}
        dataSource={data}
        pagination={{ position: ['bottomCenter'], pageSize: 10, total: nSolution }}
        loading={loading}
        rowKey={record => record.id}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: record => {
            return record.service_config.length > 0 && record.service_config[0].keyword_mapping_name !== undefined? (
               <div key={record.id} className="dtx-c-table-subrow">
                 <span className="span3">Data:</span> <div className="dtx-c-tag back-b-grey3">
                 <span className="span3">{record.service_config[0].keyword_mapping_name}</span></div>
               </div>) : <div/>
           }
         ,
         expandedRowKeys: listRowID,
        }}
        defaultExpandAllRows={true}
        showExpandColumn={true}
        expandIconAsCell={true}
        expandIconColumnIndex={-1}
      />

      <TrendSonarConfigurationEdit
        configurationEditModal={configurationEditModal}
        setConfigurationEditModal={setConfigurationEditModal}
        record={record}
        disabledModal={currentTab !== 0 ? true : false}
      />

      <SuccessModals successShow={successShow} setSuccessShow={setSuccessShow} successItem={successItem} />
    </div>
  );
};

export default AllRequestsSolutions;
