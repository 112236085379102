const actions = {
  SET_PROFILE: 'SET_PROFILE',
  SET_OVERVIEW_CARDS_VALUES: 'SET_OVERVIEW_CARDS_VALUES',
  SET_OVERVIEW_CARDS_LOADING: 'SET_OVERVIEW_CARDS_LOADING',

  setProfile: (data) => {
    return {
      type: actions.SET_PROFILE,
      data
    };
  },

  setOverviewCardsValues: (data) => {
    return {
      type: actions.SET_OVERVIEW_CARDS_VALUES,
      data
    };
  },

  setOverviewCardsLoading: (data) => {
    return {
      type: actions.SET_OVERVIEW_CARDS_LOADING,
      data
    };
  },

};

export default actions;
