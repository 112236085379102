import { Select, Skeleton, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { HorizontalBar, Line } from 'react-chartjs-2';

const { Option } = Select;

const LineChart = ({ response, title, labels }) => {
  // console.log(response);
  // console.log(labels);

  const [datasets, setDataset] = useState();
  const [options, setOpsions] = useState();

  const [clustersView, setClustersView] = useState();

  const [children, setCildren] = useState([]);

  const [clusters, setClusters] = useState({});

  const [data, setData] = useState({});

  useEffect(() => {
    if (response && labels !== undefined && labels.length > 0) {
      let newChildren = [];
      let newClusters = {};

      response.map(cluster => {
        // console.log(cluster);
        newChildren.push(<Option key={cluster.label}>{cluster.label}</Option>);
        newClusters[cluster.label] = cluster;
      });

      setCildren(newChildren);
      setClusters(newClusters);

      setDataset([response[0]]);
      // console.log([response[0].label])
      setClustersView([response[0].label]);

      setOpsions({
        responsive: true,

        legend: {
          display: false,
          position: 'bottom',
        },
        labels: {
          display: false,
        },
        title: {
          display: false,
          text: 'High Volume Constant Signal',
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#e5e9f2',
              },
              ticks: {
                beginAtZero: false,
                fontSize: 10,
                fontColor: '#182b49',
              },
            },
          ],
        },
      });
    }
  }, [response]);

  useEffect(() => {
    let newData = {
      datasets,
      labels,
    };

    setData(newData);
  }, [datasets]);

  // let { datasets, options } = {
  //   options: {
  //     responsive: true,

  //     legend: {
  //       display: false,
  //       position: 'bottom',
  //     },
  //     labels: {
  //       display: false,
  //     },
  //     title: {
  //       display: false,
  //       text: 'High Volume Constant Signal',
  //     },
  //     scales: {
  //       xAxes: [
  //         {
  //           gridLines: {
  //             color: '#e5e9f2',
  //           },
  //           ticks: {
  //             beginAtZero: false,
  //             fontSize: 10,
  //             fontColor: '#182b49',
  //           },
  //         },
  //       ],
  //     },
  //   },

  //   datasets: response,
  // };

  function tagRender(props) {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    // console.log(clusters[label]);

    if (clusters[label] !== undefined) {
      return (
        <Tag
          color={clusters[label].borderColor}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
    }
  }

  function handleChange(value) {
    // console.log(`NewDatasets selected: `, value);

    let newDatasets = [];

    value.map(cl => {
      newDatasets.push(clusters[cl]);
    });

    // console.log("NewDatasets Clusters: ", clusters)

    // console.log("NewDatasets: ", newDatasets)

    setDataset(newDatasets);
  }

  return (
    <div className="dtx-c-plot-content">
      <div className="dtx-c-plot-header">
        <h6>{title}</h6>
      </div>
      {response !== false && children.length > 0 ? (
        <>
          <div className="dtx-c-plot-body">
            <Line data={data} options={options} />
            <div className="mt-2">
              <div className="dtx-c-field">
                <p className="label2 m-grey mb-0-25">Select Cluster Name </p>
                <Select
                  mode="multiple"
                  tagRender={tagRender}
                  defaultValue={[response[0].label]}
                  style={{ width: '100%' }}
                  placeholder="Tags Mode"
                  onChange={handleChange}
                  className="dtx-c-field__tag"
                >
                  {children}
                </Select>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="dtx-c-plot-body">
          <Skeleton active />
        </div>
      )}
    </div>
  );
};

export default LineChart;
