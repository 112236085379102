import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { searchSolutions, searchSolutionsSingleCustomer } from '../../redux/solutions/actionCreator';


const CardCustomerItems = ({ type, values, loading }) => {

  const dispatch = useDispatch();

  const [loadingS, setLoading] = useState(false);
  const [nSolution, setNSolution] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    dispatch(
      searchSolutionsSingleCustomer(17, 3, 1, {}, (response, total) => {
        if (isMounted) {
          setNSolution(total);
          setLoading(false);
        }
      }),
    );
    return () => { isMounted = false };
  }, []);

  switch (type) {
    case 'clients':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <FeatherIcon icon={'briefcase'} />
          </div>
          <div className="dtx-c-card-content">
            <h2> {loading ? <Spin /> : values}</h2>
            <p className="m-lightgrey">Customers</p>
          </div>
        </div>
      );
    case 'brands':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <FeatherIcon icon={'award'} />
          </div>
          <div className="drx-c-card-content">
            <h2>{loading ? <Spin /> : values}</h2>
            <p className="m-lightgrey">Brands</p>
          </div>
        </div>
      );
    case 'projects':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <div className="Vector">
              <FeatherIcon icon={'layers'} />
            </div>
          </div>
          <div className="dtx-c-card-content">
            <h2>{loading ? <Spin /> : values}</h2>
            <p className="m-lightgrey">Projects</p>
          </div>
        </div>
      );
    case 'users':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <FeatherIcon icon={'users'} />
          </div>
          <div className="dtx-c-card-content">
            <h2>{loading ? <Spin /> : values}</h2>
            <p className="m-lightgrey">Users</p>
          </div>
        </div>
      );
    case 'activeSolution':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <FeatherIcon icon={'bar-chart-2'} />
          </div>
          <div className="dtx-c-card-content">
            <h2>{loading ? <Spin /> : values}</h2>
            <p className="m-lightgrey">Active Solutions</p>
          </div>
        </div>
      );
    case 'activeToApprove':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <FeatherIcon icon={'pause-circle'} />
          </div>
          <div className="dtx-c-card-content">
            <h2>{loading ? <Spin /> : values}</h2>
            <p className="m-lightgrey">Solutions to approve</p>
          </div>
        </div>
      );
    case 'solutions':
      return (
        <div className="dtx-c-card dtx-c-card__value">
          <div className="dtx-c-icon">
            <div className="Vector">
              <FeatherIcon icon={'layers'} />
            </div>
          </div>
          <div className="dtx-c-card-content">
            <h2>{loadingS ? <Spin /> : nSolution}</h2>
            <p className="m-lightgrey">Solutions</p>
          </div>
        </div>
      );
    default:
      console.log(`Sorry, we are out of .`);
  }
};

export default CardCustomerItems;
