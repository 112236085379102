import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { getCustomerList } from '../../../redux/customers/actionCreator';
import { getBrandList } from '../../../redux/brands/actionCreator';
import { postCreateUser, postUpdateUser } from '../../../redux/users/actionCreator';

const { Option } = Select;

const ModifySeoUser = ({ visible, onClose, id, record }) => {
  // console.log('EDIT SEO USER: ', record);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [listCustomer, setListCustomer] = useState([]);

  useEffect(() => {
    dispatch(
      getCustomerList(id, values => {
        let newListCustomer = [];

        newListCustomer.push(values.map(customer => <Option value={customer.id}>{customer.name}</Option>));

        setListCustomer(newListCustomer);
      }),
    );
  }, [dispatch]);

  const onFinish = values => {
    let newValues = {};

    newValues = {
      ...values,
      user_id: record.id,
      seomaster: 'False',
      seouser: 'True',
      clientuser: 'False',
      brand_ids: [],
    };

    dispatch(
      postUpdateUser(newValues, values => {
        onClose();
      }),
    );
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onUpdate = value => {
    const fields = form.getFieldsValue();

    let newForm = {};

    newForm = {
      ...fields,
      users: [],
    };

    form.setFieldsValue(newForm);
  };

  return (
    <Drawer
      title={'Edit SEO User'}
      destroyOnClose={true}
      width={500}
      preserve={false}
      placement="right"
      onClose={onClose}
      visible={visible}
      className='dtx-c-drawer'
    >
      <div>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            first_name: record !== undefined && record.first_name,
            last_name: record !== undefined && record.last_name,
            email: record !== undefined && record.email,
            client_ids: record !== undefined && record.customer_ids,
            brand_ids: record !== undefined && record.brand_ids,
          }}
          {...layout}
        >
          <div className="dtx-c-field-row">
            <div className="dtx-c-field">
              <p className='label2 m-grey'>Name:</p>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="e.g. Mario" className='dtx-c-field__input'/>
              </Form.Item>
            </div>

            <div className="dtx-c-field">
              <p className='label2 m-grey'>Surname:</p>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="e.g. Rossi" className='dtx-c-field__input'/>
              </Form.Item>
            </div>
          </div>
          <div className="dtx-c-field">
            <p className='label2 m-grey'>Email:</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="e.g. mario.rossi@gmail.com" className='dtx-c-field__input'/>
            </Form.Item>{' '}
          </div>
          <div className="dtx-c-field">
            <p className='label2 m-grey'>Select the Customer to whom to associate the User:</p>
            <Form.Item name="client_ids">
              <Select placeholder="Select Customer" className="dtx-c-field__select" mode="multiple" disabled={listCustomer.length > 0 ? false : true}>
                {listCustomer}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button className='dtx-c-btn btn__large dtx-c-btn__solid' htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default ModifySeoUser;
