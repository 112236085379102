import React, { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { exportCSVKeyword } from '../../../redux/keywords/actionCreator';
import TrendLine from '../../charts/TrendLine';
import { Link } from 'react-router-dom';
import { format_number } from '../../../utility/utility';
import moment from 'moment';

const getTag = volume => {
  // console.log(volume);

  let response = [];

  switch (volume) {
    case 3:
      response.push(
        <p key={volume} className="span4 m-blue">
          NEW
        </p>,
      );
      break;
    case 2:
      response.push(
        <p key={volume} className="span4 s-error">
          high volume
        </p>,
      );
      break;
    case 0:
      response.push(
        <p key={volume} className="span4 s-warning">
          weak volume
        </p>,
      );
      break;
    case 1:
      response.push(
        <p key={volume} className="span4 s-success">
          low volume
        </p>,
      );
      break;
    default:
      <div></div>;
  }

  // console.log(response);

  return response;
};

const AllKeywordTable = ({
  service_name,
  keywordData,
  keyWordFilters,
  setKeywordFilters,
  currentPage,
  setCurrentPage,
  totals,
  loading,
  defaultFilters,
  setDefaultValue,
  service_id,
  setOrderTable,
}) => {
  const dispatch = useDispatch();

  const [downloadLoader, setDownloaderLoader] = useState(false);

  /**
   * Columns for SEO USER
   */
  const [columns, setColumns] = useState([]);

  useState(() => {
    let newColumns = [
      {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword',
        render: (value, record) => {
          return (
            <div className="dtx-c-table__tag">
              <p>
                <Link
                  className="m-secondary span2"
                  to={{
                    pathname: '/keyword',
                    search: `?name=${record.keyword}`,
                    state: { KeywordData: record, service_id: service_id },
                  }}
                >
                  {record.keyword}
                </Link>
              </p>
              {getTag(record.type)}
            </div>
          );
        },
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
        ...(defaultFilters.field === 'order_keyword' && {
          defaultSortOrder: defaultFilters.value === 'Descending' ? 'descend' : 'ascend',
        }),
      },
      {
        title: 'Cluster',
        dataIndex: 'category_1',
        key: 'category_1',
        render: (value, record) => {
          let clusterPath = '';

          // console.log(record.category_1, record.category_2, record.category_3)

          if (record.category_1 !== 'ND') clusterPath = clusterPath + record.category_1;
          if (record.category_2 !== 'ND') clusterPath = clusterPath + ' / ' + record.category_2;
          if (record.category_2 !== 'ND' && record.category_3 !== 'ND')
            clusterPath = clusterPath + ' / ' + record.category_3;

          return clusterPath;
        },
        // render: (value, record) => (value !== 'ND' ? value : ''),
        // sorter: {
        //   compare: (a, b) => a.name - b.name,
        // },
        // ...(defaultFilters.field === 'order_category_1' && {
        //   defaultSortOrder: defaultFilters.value === 'Descending' ? 'descend' : 'ascend',
        // }),
      },
      // {
      //   title: 'Category',
      //   dataIndex: 'category_2',
      //   key: 'category_2',
      //   render: (value, record) => (value !== 'ND' ? value : ''),
      //   sorter: {
      //     compare: (a, b) => a.name - b.name,
      //   },
      // },
      // {
      //   title: 'Sub-Category',
      //   dataIndex: 'category_3',
      //   key: 'category_3',
      //   render: (value, record) => (value !== 'ND' ? value : ''),
      //   sorter: {
      //     compare: (a, b) => a.name - b.name,
      //   },
      // },
      {
        title: 'Volume',
        dataIndex: 'average_volume',
        key: 'average_volume',
        render: (value, record) => format_number(value, 0),
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Growth',
        dataIndex: 'growth_rate',
        key: 'growth_rate',
        render: (value, record) => {
          let check = parseInt(value) > 0 ? true : false;

          return record.type !== 3 ? (
            check ? (
              <div className="dtx-c-table__value">
                <p className="s-success">+{format_number(value, 2) + ' %'}</p>
                <FeatherIcon icon="arrow-up" className="s-success" />
              </div>
            ) : (
              <div className="dtx-c-table__value">
                <p className="s-error">{format_number(value, 2) + ' %'}</p>
                <FeatherIcon icon="arrow-down" className="s-error" />
              </div>
            )
          ) : (
            <div className="dtx-c-card-info-new">
              <div className="dtx-c-tag-new">
                <p className="span3">NEW</p>
              </div>
              <Tooltip title="This is a new keyword that had not been seen previously">
                <FeatherIcon icon="help-circle" color="#5b5a7c" size="18" className="ml-5px" />
              </Tooltip>
            </div>
          );
        },
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: 'Trend',
        dataIndex: 'trend',
        key: 'trend',
        render: (value, record) => {
          return (
            <div className="trendTable">
              <TrendLine
                id="kw-trend"
                response={record.historical_volume.volume}
                labels={record.historical_volume.date}
              />
            </div>
          );
        },
      },
    ];

    // console.log(newColumns)

    setColumns(newColumns);
  }, [keyWordFilters]);

  /**
   * This function is called on every change of the table.
   * @param {current} pagination is valued after change pagination.
   * @param {*} filters no use.
   * @param {field,order} sorter is valued after order (object after one order, array after multiple order)
   */
  const handleTableChange = (pagination, newfilters, sorter) => {
    if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
      setOrderTable(true);
    }

    if (sorter !== undefined) {
      // console.log(sorter);

      let newfilters = {
        ...keyWordFilters,
        order_keyword: '',
        order_typology: '',
        order_category_1: '',
        order_category_2: '',
        order_category_3: '',
        order_growth_rate: '',
        order_volume: '',
      };

      if (sorter.length === undefined) {
        let field = sorter.field;
        let order = sorter.order;

        if (order !== undefined) {
          newfilters = {
            ...newfilters,
            [`order_${field}`]: order === 'ascend' ? 1 : 2,
          };

          setDefaultValue({
            field: `order_${field}`,
            value: order === 'ascend' ? 'Ascending' : 'Descending',
          });
          setKeywordFilters(newfilters);
        } else {
          newfilters = {
            ...newfilters,
            [`order_${field}`]: '',
          };

          setDefaultValue({
            field: `order_${field}`,
            value: order === 'ascend' ? 'Ascending' : 'Descending',
          });
          setKeywordFilters(newfilters);
        }

        setOrderTable(true);
      }
    }
  };

  return (
    <div className="dtx-c-table">
      <div className="dtx-c-table-header">
        <h6>All Keywords</h6>
        <Button
          className="test_download-csv"
          target="_blank"
          download
          onClick={() => {
            setDownloaderLoader(true);
            dispatch(
              exportCSVKeyword({ service_id: service_id }, response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `trendsonar_${service_name + '_' + moment().format('YYYY-MM-DD')}.csv`); //or any other extension
                document.body.appendChild(link);
                link.click();
                setDownloaderLoader(false);
              }),
            );
          }}
        >
          {downloadLoader ? '...Loading' : 'Download CSV'}
        </Button>
      </div>
      <div className="dtx-c-table-content dtx-c-table__card-pagination">
        <Table
          columns={columns}
          onChange={handleTableChange}
          dataSource={keywordData}
          loading={loading}
          pagination={{ current: currentPage, position: ['bottomCenter'], pageSize: 10, total: totals }}
          // scroll={{ x: 1300 }}
        />
      </div>
    </div>
  );
};

export default AllKeywordTable;
