import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, TimePicker, DatePicker, Form, message, Skeleton, Empty, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import {
  postCreationConfiguration,
  readConfiguration,
  readKeywordMapping,
  readService,
  readServiceExpantionType,
  readServiceLanguage,
  readServiceLocation,
  readUpdateFrequency,
  updateServiceCallback,
} from '../../redux/solutions/actionCreator';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import UpdateFrequency from '../datePicker/UpdateFrequency';

const { Option } = Select;

const format = 'HH:mm';

const frequencyCombination = {
  '3 times a day': ['select_update_time_1', 'select_update_time_2', 'select_update_time_3'],
  '2 times a day': ['select_update_time_1', 'select_update_time_2'],
  '1 time a day': ['select_update_time_1'],
  '1 time a week': ['select_update_day_of_week', 'select_update_time_1'],
  '1 time a month': ['select_update_day_of_month', 'select_update_time_1'],
  'once every 2 weeks': ['select_update_day_of_week', 'select_update_time_1'],
};

export default function TrendSonarConfigurationEdit({
  record,
  configurationEditModal,
  setConfigurationEditModal,
  disabledModal,
}) {

  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const [form] = Form.useForm();

  const [myFormValues, setMyFormValues] = useState(undefined);

  const [solutionConfigData, setSolutionConfigData] = useState(undefined);
  const [responseKeywordMapping, setResponseKeywordMapping] = useState({});

  const handleCancel = (e) => {
    setMyFormValues({
      keyword_mapping: record !== undefined ? 0 : null,
      update_frequency: null,
      search_volume_view: null,
    });
    form.resetFields();
    setSolutionConfigData(undefined);
    setConfigurationEditModal(false);
  };

  const [listKeywordMapping, setListKeywordMapping] = useState([]);
  const [listKeywordMappingName, setListKeywordMappingName] = useState([]);

  const [loading, setLoading] = useState(false);

  const [listUpdateFrequency, setListUpdateFrequency] = useState([]);

  // to do
  const [listLanguage, setListLanguage] = useState([]);
  const [listLocation, setListLocation] = useState([]);
  const [listExpantionType, setListExpantionType] = useState([]);

  const [updateFrequencyData, setUpdateFrequencyData] = useState({});
  const [listSearchVolumeVisualization, setListSearchVolumeVisualization] = useState([]);

  const [frequencyComponents, setFrequencyComponents] = useState([]);

  const getContent = (frq, disabledModal) => {
    // console.log(myFormValues);
    if (myFormValues !== undefined) {
      switch (myFormValues.select_update_frequency) {
        case '3 times a day':
          return (
            <>
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Update</p>
                  <p className="span2">Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Update Time</p>
                    <Form.Item
                      name="select_update_time_1"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        defaultValue={myFormValues['select_update_time_1']}
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Second Update Time</p>
                    <Form.Item
                      name="select_update_time_2"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_2: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Third Update Time</p>
                    <Form.Item
                      name="select_update_time_3"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_3: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          );
          break;
        case '2 times a day':
          return (
            <>
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Update</p>
                  <p className="span2">Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Update Time</p>{' '}
                    <Form.Item
                      name="select_update_time_1"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Second Update Time</p>{' '}
                    <Form.Item
                      name="select_update_time_2"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_2: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          );
          break;
        case '1 time a day':
          return (
            <>
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Update</p>
                  <p className="span2">Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Update Time</p>
                    <Form.Item
                      name="select_update_time_1"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        minuteStep={15}
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field"></div>
                </div>
              </div>
            </>
          );
          break;
        case '1 time a week':
          return (
            <>
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Update</p>
                  <p className="span2">Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Day of the week</p>
                    <Form.Item
                      name="select_update_day_of_week"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select day"
                        className="dtx-c-field__select"
                        onChange={value => {
                          getValueForm(value, 'select_update_day_of_week');
                        }}
                        disabled={disabledModal}
                      >
                        <Option value={1}>Monday</Option>
                        <Option value={2}>Tuesday</Option>
                        <Option value={3}>Wednesday</Option>
                        <Option value={4}>Thursday</Option>
                        <Option value={5}>Friday</Option>
                        <Option value={6}>Saturday</Option>
                        <Option value={7}>Sunday</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Time</p>
                    <Form.Item
                      name="select_update_time_1"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          );
          break;
        case '1 time a month':
          return (
            <>
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Update</p>
                  <p className="span2">Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Day of the month</p>
                    <Form.Item
                      name="select_update_day_of_month"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select day of the month"
                        className="dtx-c-field__select"
                        onChange={value => {
                          getValueForm(value, 'select_update_day_of_month');
                        }}
                        disabled={disabledModal}
                      >
                        {getDays()}
                      </Select>
                    </Form.Item>
                  </div>
                  {/* { qua } */}
                  <div className="dtx-c-field">
                    <div className="dtx-c-field">
                      <p className="label2">Second Update Time</p>{' '}
                      <Form.Item
                        name="select_update_time_1"
                        getValueProps={value => {
                          form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('HH:mm') });
                        }}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TimePicker
                          placeholder="Select time"
                          className="dtx-c-field__picker"
                          format={format}
                          disabled={disabledModal}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
          break;
        case 'once every 2 weeks':
          return (
            <>
              {/* <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className='label1'>Data Update</p>
                  <p className='span2'>Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className='label2'>Day of the month</p>
                    <DatePicker picker="week" className="dtx-c-field__picker" />
                  </div>
                  <div className="dtx-c-field">
                    <p className='label2'>Update Time</p>
                    <TimePicker placeholder="Select time" className="dtx-c-field__picker" format={format} />
                  </div>
                </div>
              </div> */}
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Update</p>
                  <p className="span2">Select when to run the update</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Day of the week</p>
                    <Form.Item
                      name="select_update_day_of_week"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select day"
                        className="dtx-c-field__select"
                        onChange={value => {
                          getValueForm(value, 'select_update_day_of_week');
                        }}
                        disabled={disabledModal}
                      >
                        <Option value={1}>Monday</Option>
                        <Option value={2}>Tuesday</Option>
                        <Option value={3}>Wednesday</Option>
                        <Option value={4}>Thursday</Option>
                        <Option value={5}>Friday</Option>
                        <Option value={6}>Saturday</Option>
                        <Option value={7}>Sunday</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Time</p>
                    <Form.Item
                      name="select_update_time_1"
                      getValueProps={value => {
                        form.setFieldsValue({ select_update_time_1: moment(value, 'HH:mm').format('HH:mm') });
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select time"
                        className="dtx-c-field__picker"
                        format={format}
                        disabled={disabledModal}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          );
          break;

        default:
      }
    }
  };

  useEffect(() => {
    if (configurationEditModal) {
      // console.log('disabledModal: ===>', disabledModal, record);

      dispatch(
        readKeywordMapping((keywordsMapping) => {
          setListKeywordMappingName(keywordsMapping);

          let newListKeywordMapping = [];

          for (let key in keywordsMapping) {
            newListKeywordMapping.push(
              <Option key={key} value={key}>
                {keywordsMapping[key]}
              </Option>,
            );
          }

          setResponseKeywordMapping(keywordsMapping);
          setListKeywordMapping(newListKeywordMapping);

          dispatch(
            readConfiguration(record.id, (configResponse) => {
              // console.log('Configuration: ', configResponse.configuration);

              let newConfig = {};



              if (configResponse.configuration.length > 0) {

                if (configResponse.configuration.find(
                  (x) => x.service_config_item.name === 'select_expansion_type',
                ) !== undefined && configResponse.configuration.find(
                  (x) => x.service_config_item.name === 'select_language',
                ) !== undefined && configResponse.configuration.find(
                  (x) => x.service_config_item.name === 'select_location',
                ) !== undefined) {

                  newConfig = {
                    // keyword_mapping_id:
                    // keywordsMapping[
                    //   configResponse.configuration.find(x => x.service_config_item.name === 'keyword_mapping_id').value
                    // ],
                    keyword_mapping_id: configResponse.configuration
                      .find((x) => x.service_config_item.name === 'keyword_mapping_id')
                      .value.toString(),
                    // negative_keywords_file: solutionConfigData.find(
                    //   x => x.service_config_item.name === 'negative_keywords_filename',
                    // ).value,
                    select_update_frequency: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_update_frequency',
                    ).value,
                    select_time_interval_visualization: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_time_interval_visualization',
                    ).value,
                    select_expansion_type: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_expansion_type',
                    ).value,
                    select_language: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_language',
                    ).value,
                    select_location: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_location',
                    ).value,
                  };
                } else {
                  newConfig = {
                    // keyword_mapping_id:
                    // keywordsMapping[
                    //   configResponse.configuration.find(x => x.service_config_item.name === 'keyword_mapping_id').value
                    // ],
                    keyword_mapping_id: configResponse.configuration
                      .find((x) => x.service_config_item.name === 'keyword_mapping_id')
                      .value.toString(),
                    // negative_keywords_file: solutionConfigData.find(
                    //   x => x.service_config_item.name === 'negative_keywords_filename',
                    // ).value,
                    select_update_frequency: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_update_frequency',
                    ).value,
                    select_time_interval_visualization: configResponse.configuration.find(
                      (x) => x.service_config_item.name === 'select_time_interval_visualization',
                    ).value
                  };
                }

                let newConfigFrq =
                  frequencyCombination[
                  configResponse.configuration.find((x) => x.service_config_item.name === 'select_update_frequency')
                    .value
                  ];

                // console.log(
                //   configResponse.configuration.find(x => x.service_config_item.name === 'select_update_frequency')
                //     .value,
                // );

                if (newConfigFrq !== undefined) {
                  // console.log(newConfigFrq);

                  newConfigFrq.map((config) => {
                    if (
                      config === 'select_update_time_1' ||
                      config === 'select_update_time_2' ||
                      config === 'select_update_time_3'
                    ) {
                      // console.log(configResponse.configuration.find(x => x.service_config_item.name === config).value);

                      newConfig = {
                        ...newConfig,
                        [config]: moment(
                          configResponse.configuration.find((x) => x.service_config_item.name === config).value,
                          format,
                        ),
                      };
                    } else {
                      // console.log(config, configResponse.configuration);

                      newConfig = {
                        ...newConfig,
                        [config]: configResponse.configuration.find((x) => x.service_config_item.name === config).value,
                      };
                    }
                  });
                }

                console.log(newConfig)

                setMyFormValues(newConfig);
                form.setFieldsValue(newConfig);

                UpdateFrequency(
                  newConfig,
                  form,
                  setFrequencyComponents,
                  configResponse.configuration.find((x) => x.service_config_item.name === 'select_update_frequency')
                    .value,
                  disabledModal,
                );

                setSolutionConfigData(configResponse);
              }
            }),
          );
        }),
      );

      dispatch(
        readUpdateFrequency((updateFrequency) => {
          setUpdateFrequencyData(updateFrequency);

          let newListUpdateFrequency = [];

          for (let key in updateFrequency) {
            newListUpdateFrequency.push(
              <Option key={key} value={key}>
                {key}
              </Option>,
            );
          }

          setListUpdateFrequency(newListUpdateFrequency);
        }),
      );

      // const [listLanguage, setListLanguage] = useState([]);
      // const [listLocation, setListLocation] = useState([]);
      // const [listExpantionType, setListExpantionType] = useState([]);

      dispatch(
        readServiceLanguage((value) => {

          let newListLanguage = [];

          value.languages.map((lang) =>
            newListLanguage.push(
              <Option key={lang} value={lang}>
                {lang}
              </Option>
            )
          )

          setListLanguage(newListLanguage);

        }),
      );

      dispatch(
        readServiceLocation((value) => {
          let newListLocation = [];

          value.locations.map((loc) =>
            newListLocation.push(
              <Option key={loc} value={loc}>
                {loc}
              </Option>
            )
          )

          setListLocation(newListLocation);
        }),
      );

      dispatch(
        readServiceExpantionType((value) => {

          let newListExpantionType = [];

          for (let key in value) {
            newListExpantionType.push(
              <Option key={key} value={key}>
                {value[key]}
              </Option>,
            );
          }

          setListExpantionType(newListExpantionType);

        }),
      );
    }
  }, [configurationEditModal]);

  // useEffect(() => {
  //   if (configurationEditModal) {
  //     // dispatch(
  //     //   readConfiguration(record.id, configResponse => {
  //     //     console.log('Configuration: ',configResponse.configuration);
  //     //     let newConfig = {};
  //     //     console.log(responseKeywordMapping)
  //     //     if (configResponse.configuration.length > 0 && Object.keys(responseKeywordMapping).length !== 0) {
  //     //       newConfig = {
  //     //         keyword_mapping_id: responseKeywordMapping[configResponse.configuration.find(
  //     //             x => x.service_config_item.name === 'keyword_mapping_id',
  //     //           ).value],
  //     //         // keyword_mapping_id: configResponse.configuration.find(
  //     //         //   x => x.service_config_item.name === 'keyword_mapping_id',
  //     //         // ).value,
  //     //         // negative_keywords_file: solutionConfigData.find(
  //     //         //   x => x.service_config_item.name === 'negative_keywords_filename',
  //     //         // ).value,
  //     //         select_update_frequency: configResponse.configuration.find(
  //     //           x => x.service_config_item.name === 'select_update_frequency',
  //     //         ).value,
  //     //         select_time_interval_visualization: configResponse.configuration.find(
  //     //           x => x.service_config_item.name === 'select_time_interval_visualization',
  //     //         ).value,
  //     //       };
  //     //       let newConfigFrq =
  //     //         frequencyCombination[
  //     //           configResponse.configuration.find(x => x.service_config_item.name === 'select_update_frequency').value
  //     //         ];
  //     //       newConfigFrq.map(config => {
  //     //         if (
  //     //           config === 'select_update_time_1' ||
  //     //           config === 'select_update_time_2' ||
  //     //           config === 'select_update_time_3'
  //     //         ) {
  //     //           // console.log(configResponse.configuration.find(x => x.service_config_item.name === config).value);
  //     //           newConfig = {
  //     //             ...newConfig,
  //     //             [config]: moment(
  //     //               configResponse.configuration.find(x => x.service_config_item.name === config).value,
  //     //               format,
  //     //             ),
  //     //           };
  //     //         } else {
  //     //           newConfig = {
  //     //             ...newConfig,
  //     //             [config]: configResponse.configuration.find(x => x.service_config_item.name === config).value,
  //     //           };
  //     //         }
  //     //       });
  //     //       setMyFormValues(newConfig);
  //     //       form.setFieldsValue(newConfig);
  //     //       UpdateFrequency(
  //     //         newConfig,
  //     //         form,
  //     //         setFrequencyComponents,
  //     //         configResponse.configuration.find(x => x.service_config_item.name === 'select_update_frequency').value,
  //     //         disabledModal,
  //     //       );
  //     //       setSolutionConfigData(configResponse);
  //     //     }
  //     //   }),
  //     // );
  //   }
  // }, [configurationEditModal]);

  useEffect(() => {
    if (myFormValues !== undefined && myFormValues.select_update_frequency !== undefined) {
      UpdateFrequency(null, form, setFrequencyComponents, myFormValues.select_update_frequency, disabledModal);
    }
  }, [myFormValues]);

  const getDays = () => {
    let listDays = [];
    for (let i = 1; i <= 28; i++) {
      listDays.push(
        <Option key={i} value={i}>
          {i}
        </Option>,
      );
    }

    return listDays;
  };

  const getValueForm = (value, key) => {
    let newForms = {};
    newForms = {
      ...myFormValues,
      [key]: value,
    };
    setMyFormValues(newForms);
  };

  const onConfigurationClose = (values) => {
    const fields = form.getFieldsValue();

    let newForm = {
      ...fields,
    };

    form.setFieldsValue(newForm);

    setConfigurationEditModal(false);
  };

  const compareTime = (time_1, time_2) => {
    if (time_1[6] === 'p' && time_2[6] === 'a') return true;
    if (time_1[6] === 'a' && time_2[6] === 'p') return false;
    return time_1.localeCompare(time_2) === 1;
  };

  const convertTime = (time) => {
    //convert pm to am
    if (time === '12:00 am') {
      return '24:00';
    }
    if (time[6] === 'p' && time.substr(0, 2) !== '12') {
      return +time.substr(0, 2) + 12 + ':' + time.substr(3, 2);
    } else {
      // return time.substr(0, 5);
      return time.toString().substr(0, 5);
    }
  };

  function onSearch(val) {
    // console.log('search:', val);
  }

  // console.log("disabledModal: ", disabledModal)

  return (
    <Modal
      visible={configurationEditModal}
      // visible={true}
      title="Trend Sonar Configuration"
      cancelText="Go Back"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose={true}
      className="dtx-c-modal__composed"
      onClose={onConfigurationClose}
    >
      <div className="modal-Body-configuration">
        {solutionConfigData !== undefined && myFormValues !== undefined && frequencyComponents.length > 0 ? (
          <Form
            name="basic"
            form={form}
            // initialValues={myFormValues}
            onFinish={(values) => {
              setLoading(true)

              let newForms = {
                ...values,
                // ...myFormValues,
                keyword_mapping_name: listKeywordMappingName[values.keyword_mapping_id],
                service_id: record.id,
              };

              console.log(newForms);

              // for (let i in newForms) {
              //   if (i === 'select_update_time_1' || i === 'select_update_time_2' || i === 'select_update_time_3') {
              //     // console.log(newForms[i], moment(newForms[i]).format(format));
              //     newForms = {
              //       ...newForms,
              //       [i]: moment(newForms[i]).format(format),
              //       // [i]: convertTime(newForms[i]),
              //     };
              //   }
              // }

              console.log(newForms);

              dispatch(
                postCreationConfiguration(newForms, {}, (response) => {
                  // console.log(response);

                  dispatch(
                    updateServiceCallback({ service_id: record.id, status: 1 }, (responseUpdate) => {
                      // console.log(responseUpdate);
                      setConfigurationEditModal(false);
                      setLoading(false)
                      message.success('configuration created');
                    }),
                  );
                }),
              );
            }}
            onFinishFailed={(values) => {
              // console.log(values);
            }}
          >
            <div className="dtx-c-modal-content">
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Source</p>
                  <p className="span2">Select a keyword mapping in Intent Sonar</p>
                </div>

                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Keyword Mapping</p>
                    <Form.Item
                      name="keyword_mapping_id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Keyword Mapping"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'keyword_mapping_id');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listKeywordMapping}
                      </Select>
                    </Form.Item>
                    <p className="span3 m-secondary">Can't find your file? Open a ticket</p>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Negative Keyword</p>
                    <div className="Upload-Field">
                      <Form.Item name="negative_keywords_file">
                        {/* <input
                          type="file"
                          accept="text/csv"
                          onChange={e => {
                            / setFile(e.target.files[0])}} / getValueForm(
                              e.target.files[0],
                              'negative_keywords_file',
                            );
                            getValueForm(e.target.files[0], 'negative_keywords_file');
                          }}
                        /> */}

                        <Upload
                          listType="text/csv"
                          disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        >
                          <Button icon={<UploadOutlined />} disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}>Click to Upload</Button>
                        </Upload>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Expansion Type</p>
                  <p className="span2">Choose how you want to expand the keywords in the Keyword Mapping</p>
                </div>

                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Expansion Type</p>
                    <Form.Item
                      name="select_expansion_type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Keyword Mapping"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listExpantionType.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_expansion_type');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listExpantionType}
                      </Select>
                    </Form.Item>
                    {/* <p className="span3 m-secondary">Can't find your file? Open a ticket</p> */}
                  </div>
                </div>
              </div>

              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Language and Location</p>
                  <p className="span2">Specify the language and location for Keyword Expansion, Google Trends and Keyword Planner</p>
                </div>

                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Language</p>
                    <Form.Item
                      name="select_language"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select language"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listLanguage.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_language');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listLanguage}
                      </Select>
                    </Form.Item>
                    {/* <p className="span3 m-secondary">Can't find your file? Open a ticket</p> */}
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Location</p>
                    <Form.Item
                      name="select_location"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select location"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listLocation.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_location');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listLocation}
                      </Select>
                    </Form.Item>
                    {/* <p className="span3 m-secondary">Can't find your file? Open a ticket</p> */}
                  </div>
                </div>
              </div>

              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Visualization</p>
                  <p className="span2">Select the update frequency and the search volume visualization</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Update Frequency</p>
                    <Form.Item
                      name="select_update_frequency"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Update Frequency"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        onChange={(value) => {
                          console.log(value);

                          let newForm = {
                            keyword_mapping_id: form.getFieldValue()['keyword_mapping_id'],
                            select_update_frequency: value,
                            select_time_interval_visualization: '',
                          };

                          setMyFormValues({
                            keyword_mapping_id: form.getFieldValue()['keyword_mapping_id'],
                            select_update_frequency: value,
                          });

                          form.setFieldsValue(newForm);

                          getValueForm(value, 'select_update_frequency');

                          let newListSearchVolumeVisualization = [];

                          newListSearchVolumeVisualization.push(
                            updateFrequencyData[value].map((time) => <Option value={time}>{time}</Option>),
                          );

                          setListSearchVolumeVisualization(newListSearchVolumeVisualization);
                        }}
                      >
                        {listUpdateFrequency}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Search Volume Visualization</p>
                    <Form.Item
                      name="select_time_interval_visualization"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Update Frequency"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_time_interval_visualization');
                        }}
                      >
                        {listSearchVolumeVisualization}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {frequencyComponents}
            </div>
            {!disabledModal && (
              <div className="dtx-c-modal-footer">
                <Button htmlType="submit" className="dtx-c-btn btn__small dtx-c-btn__solid" loading={loading}>
                  Submit request
                </Button>
              </div>
            )}
          </Form>
        ) : (
          <Form
            name="basic"
            form={form}
            // initialValues={myFormValues}
            onFinish={(values) => {
              // console.log(values);
              setLoading(true);

              let newForms = {
                ...values,
                ...myFormValues,
                keyword_mapping_name: listKeywordMappingName[values.keyword_mapping_id],
                service_id: record.id,
              };

              console.log('====================', newForms);

              //TODO: Add a condition ... type
              if (
                newForms.select_update_frequency === '3 times a day' ||
                newForms.select_update_frequency === '2 times a day'
              ) {
                //handle when time fields are empty
                if (newForms.hasOwnProperty('select_update_time_3')) {
                  if (
                    newForms.select_update_time_1 === 'Invalid date' ||
                    newForms.select_update_time_2 === 'Invalid date' ||
                    newForms.select_update_time_3 === 'Invalid date'
                  ) {
                    message.error('Please fill all update time fields');
                    return;
                  }
                } else {
                  if (
                    newForms.select_update_time_1 === 'Invalid date' ||
                    newForms.select_update_time_2 === 'Invalid date'
                  ) {
                    message.error('Please fill all update time fields');
                    return;
                  }
                }

                //handle when time fields are not suitable
                if (compareTime(newForms.select_update_time_1, newForms.select_update_time_2)) {
                  message.error('The second update time must be after the first update time.');
                  return;
                }

                if (newForms.hasOwnProperty('select_update_time_3')) {
                  if (compareTime(newForms.select_update_time_2, newForms.select_update_time_3)) {
                    message.error('The third update time must be after the second update time.');

                    return;
                  }
                  if (compareTime(newForms.select_update_time_1, newForms.select_update_time_3)) {
                    message.error('The third update time must be after the first update time.');
                    return;
                  }
                }
              }

              for (let i in newForms) {
                if (i === 'select_update_time_1' || i === 'select_update_time_2' || i === 'select_update_time_3') {
                  // console.log(newForms[i], moment(newForms[i]).format(format));
                  newForms = {
                    ...newForms,
                    [i]: convertTime(newForms[i]),
                  };
                }
              }

              dispatch(
                postCreationConfiguration(newForms, {}, (response) => {
                  // console.log(response);
                  // console.log(newForms);

                  dispatch(
                    updateServiceCallback({ service_id: record.id, status: 1 }, (responseUpdate) => {
                      console.log(responseUpdate);
                      setConfigurationEditModal(false);
                      setLoading(false);
                      message.success('Configuration created');
                    }),
                  );
                }),
              );
            }}
            onFinishFailed={(values) => {
              // console.log(values);
            }}
          >
            <div className="dtx-c-modal-content">
              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Resource</p>
                  <p className="span2">Choose the keyword mapping from Intent Sonar</p>
                </div>

                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Keyword Mapping</p>
                    <Form.Item
                      name="keyword_mapping_id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select Keyword Mapping"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'keyword_mapping_id');
                        }}
                      >
                        {listKeywordMapping}
                      </Select>
                    </Form.Item>
                    <p className="span3 m-secondary">Can't find your file? Open a ticket</p>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Negative Keyword</p>
                    <div className="Upload-Field">
                      <Form.Item
                        name="negative_keywords_file"
                      // rules={[
                      //   {
                      //     required: true,
                      //   },
                      // ]}
                      >
                        <Upload
                          listType="text/csv"
                          disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        >
                          <Button icon={<UploadOutlined />} disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}>Click to Upload</Button>
                        </Upload>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>


              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Expansion Type</p>
                  <p className="span2">Choose how you want to expand the keywords in the Keyword Mapping</p>
                </div>

                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Expansion Type</p>
                    <Form.Item
                      name="select_expansion_type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Keyword Mapping"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listExpantionType.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_expansion_type');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listExpantionType}
                      </Select>
                    </Form.Item>
                    {/* <p className="span3 m-secondary">Can't find your file? Open a ticket</p> */}
                  </div>
                </div>
              </div>

              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Language and Location</p>
                  <p className="span2">Specify the language and location for Keyword Expansion, Google Trends and Keyword Planner</p>
                </div>

                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Language</p>
                    <Form.Item
                      name="select_language"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select language"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listLanguage.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_language');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listLanguage}
                      </Select>
                    </Form.Item>
                    {/* <p className="span3 m-secondary">Can't find your file? Open a ticket</p> */}
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Location</p>
                    <Form.Item
                      name="select_location"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select location"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listLocation.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_location');
                        }}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listLocation}
                      </Select>
                    </Form.Item>
                    {/* <p className="span3 m-secondary">Can't find your file? Open a ticket</p> */}
                  </div>
                </div>
              </div>

              <div className="dtx-c-section back-m-white">
                <div className="dtx-c-section-title">
                  <p className="label1">Data Visualization</p>
                  <p className="span2">Setup update frequency and search volume visualization</p>
                </div>
                <div className="dtx-c-field-row">
                  <div className="dtx-c-field">
                    <p className="label2">Update Frequency</p>
                    <Form.Item
                      name="select_update_frequency"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Update Frequency"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        onChange={(value) => {
                          let newForm = {
                            ...form,
                            select_time_interval_visualization: null,
                          };

                          form.setFieldsValue(newForm);

                          getValueForm(value, 'select_update_frequency');
                          let newListSearchVolumeVisualization = [];
                          newListSearchVolumeVisualization.push(
                            updateFrequencyData[value].map((time) => (
                              <Option key={time} value={time}>
                                {time}
                              </Option>
                            )),
                          );

                          console.log(newListSearchVolumeVisualization);

                          setListSearchVolumeVisualization(newListSearchVolumeVisualization);
                        }}
                      >
                        {listUpdateFrequency}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="dtx-c-field">
                    <p className="label2">Search Volume Visualization</p>
                    <Form.Item
                      name="select_time_interval_visualization"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Update Frequency"
                        className="dtx-c-field__select"
                        disabled={disabledModal || listKeywordMapping.length === 0 ? true : false}
                        onChange={(value) => {
                          getValueForm(value, 'select_time_interval_visualization');
                        }}
                      // disabled={disabledModal}
                      >
                        {listSearchVolumeVisualization}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {getContent('', disabledModal)}
            </div>
            {!disabledModal && (
              <div className="dtx-c-modal-footer">
                <Button htmlType="submit" className="dtx-c-btn btn__small dtx-c-btn__solid" loading={loading}>
                  Submit request
                </Button>
              </div>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
}
