import { message } from 'antd';
import { DataService } from '../../config/dataService/dataService';
import actions from './actions';
import { setUpdating } from '../themeLayout/actionCreator';

const { searchUsers, setNUsers, loading, setFilters, loadingUserCard, userCard, getUsersTags } = actions;

// const postSearchUsers = (page) => {
//   return async (dispatch, getState) => {
//     dispatch(loading(true));
//     const response = await DataService.post(`/user/search_users/`, {
//       ...getState().customersReducer.filters,
//       page: page
//     });

//     if (response.status === 200) {
//       dispatch(setNUsers(response.data.number_of_users));
//       dispatch(searchUsers(response.data.users));
//       dispatch(loading(false));
//     }
//   };
// };

const postSearchUsers = (form, callback) => {
  return async (dispatch, getState) => {
    const response = await DataService.post(`/user/search_users/`, form);

    if (response.status === 200) {
      callback(response.data);
    }
  };
};

// const postSearchUsers = (page, callback) => {
//   return async (dispatch, getState) => {
//     dispatch(loading(true));
//     const response = await DataService.post(`/user/search_users/`, {
//       ...getState().customersReducer.filters,
//       page: page
//     });

//     if (response.status === 200) {
//       // dispatch(setNUsers(response.data.number_of_users));
//       // dispatch(searchUsers(response.data.users));
//       // dispatch(loading(false));

//       callback(response.data)
//     }
//   };
// };

const getUserCard = () => {
  return async (dispatch, getState) => {
    const response = await DataService.post(`/user/search_users/`);

    if (response.status === 200) {
      dispatch(userCard(response.data.number_of_users));
      dispatch(loadingUserCard(false));
    }
  };
};

const getUsersList = callback => {
  return async dispatch => {
    const customerList = await DataService.get(`/customer/read_customer/`);

    if (customerList.status === 200) {
      callback(customerList.data.users);
    }
  };
};

const editSearchUsersFilters = data => {
  return async dispatch => {
    dispatch(setFilters(data));
  };
};

const initSearchUsersFilters = data => {
  return async dispatch => {
    dispatch(
      setFilters({
        filter_user_username: '',
        filter_user_first_name: '',
        filter_user_last_name: '',
        filter_user_email: '',
        order_user_username: '',
        order_user_first_name: '',
        order_user_last_name: '',
        order_user_email: '',
        order_user_created_at: '',
      }),
    );
  };
};

const searchUsersTag = () => {
  return async dispatch => {
    const profile = await DataService.post(`/user/search_users/`);

    let userDict = {};

    profile.data.users.map(user => {
      userDict[user.id] = `${user.first_name + user.last_name}`;
    });

    if (profile.status === 200) {
      dispatch(getUsersTags(userDict));
    }
  };
};

const postCreateUser = (form, callback) => {
  return async dispatch => {
    const create_user = await DataService.post(`/auth/users/`, form);
    dispatch(setUpdating(true));
    if (create_user.status === 201) {
      const email_send = await DataService.post(`/auth/users/custom_reset_password/`, form);
      message.success('User Created', [3]);
      if (email_send.status === 200 || email_send.status === 204) {
        message.success('Email send', [3]);
        dispatch(setUpdating(false));
        callback(email_send.status);
      }
    } else {
      message.error(create_user.username);
    }
  };
};

const postUpdateUser = (form, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/user/update_user/`, form);
    dispatch(setUpdating(true));

    if (response.status === 200) {
      // console.log('perfect');
      dispatch(setUpdating(false));
      message.success('User Updater', [2]);
      callback(response.status);
    }
  };
};

const postEditProfile = (form, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/user/update_user/`, form);

    if (response.status === 200) {
      message.success('User Updater', [2]);
      callback(response);
    } else {
      message.error('Problems', [2]);
    }
  };
};

const postDeleteUser = form => {
  return async dispatch => {
    // console.log(form);

    const search_brands = await DataService.post(`/user/delete_user/`, form);
    dispatch(setUpdating(true));

    if (search_brands.status === 200) {
      dispatch(setUpdating(false));
      message.success('User deleted', [5]);
    }
  };
};

const getUsers = (value, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/user/get_users/`, value);

    if (response.status === 200) {
      callback(response.data.seo_users);
    }
  };
};

const getClientUsers = (value, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/user/get_users/`, value);

    // console.log(response);

    if (response.status === 200) {
      callback(response.data.client_users);
    }
  };
};

const readUser = (value, callback) => {
  return async dispatch => {
    const response = await DataService.post(`/user/read_user/`, { user_id: value });

    if (response.status === 200) {
      callback(response.data.seo_users);
    }
  };
};

const ActiveAction = (form, callback) => {
  return async dispatch => {
    // console.log(form)
    const response = await DataService.post(`/user/activate_deactivate/`, form);

    if (response.status === 200) {
      message.success('User Active Updated', [2]);
      callback(response.data);
    }
  };
};

const getAllUser_customer_detail = (form, callback) => {
  return async dispatch => {
    
    const response = await DataService.post(`/user/get_users/`, form);

    if (response.status === 200) {
      callback(response.data.client_users);
    }
  };
};

const getAllUser_brand_detail = (form, callback) => {
  return async dispatch => {
    
    const response = await DataService.post(`/user/get_users/`, form);

    if (response.status === 200) {
      callback(response.data.client_users);
    }
  };
};

export {
  postSearchUsers,
  getUsersList,
  readUser,
  editSearchUsersFilters,
  getUserCard,
  searchUsersTag,
  postDeleteUser,
  postUpdateUser,
  postCreateUser,
  getUsers,
  getClientUsers,
  initSearchUsersFilters,
  ActiveAction,
  postEditProfile,
  getAllUser_customer_detail,
  getAllUser_brand_detail
};
